import { toAbsoluteUrl } from "../../utils/utils";

export default [
  {
    guid: "0046f281-bab4-11e9-8f5e-251ea8290111",
    username: "gosteam@edelvives.es",
    password: "GoSteam2020",
    accessToken:"0-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiMDA0NmYyODEtYmFiNC0xMWU5LThmNWUtMjUxZWE4MjkwZGYzIiwidXNlcm5hbWUiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwiZW1haWwiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlVzdWFyaW8iLCJsYXN0bmFtZSI6IkRlbW8iLCJhdmF0YXIiOiJodHRwczovL2kyYy12Mi5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS8wMDQ2ZjI4MS1iYWI0LTExZTktOGY1ZS0yNTFlYTgyOTBkZjMvYTkucG5nIiwiZXhwIjoxNTc5MjUxNTAyfQ.uDOc7XdXTcf0KcZzMXxaXRoEq0-3PsOn0RtxJycR9RE",
    name: "Usuario Teacher",
    lastname: "Demo",
    email: "gosteam@edelvives.es",
    avatar: "https://i2c-v2.s3.eu-central-1.amazonaws.com/0046f281-bab4-11e9-8f5e-251ea8290df3/a9.png",
    role_guid: "R02",
    role_name: "Teacher",    
    devices_limit: 24,
    device_contents_expire_days: 1
  },
  {
    guid: "0046f281-bab4-11e9-8f5e-251ea8290222",
    username: "alumno@edelvives.es",
    password: "123",
    accessToken:"1-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiMDA0NmYyODEtYmFiNC0xMWU5LThmNWUtMjUxZWE4MjkwZGYzIiwidXNlcm5hbWUiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwiZW1haWwiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlVzdWFyaW8iLCJsYXN0bmFtZSI6IkRlbW8iLCJhdmF0YXIiOiJodHRwczovL2kyYy12Mi5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS8wMDQ2ZjI4MS1iYWI0LTExZTktOGY1ZS0yNTFlYTgyOTBkZjMvYTkucG5nIiwiZXhwIjoxNTc5MjUxNTAyfQ.uDOc7XdXTcf0KcZzMXxaXRoEq0-3PsOn0RtxJycR9RE",
    name: "Usuario Teacher",
    lastname: "Demo",
    email: "alumno@edelvives.es",
    avatar: "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
    role_guid: "R01",
    role_name: "Student",   
    devices_limit: 24,
    device_contents_expire_days: 1
  },
  {
    guid: "0046f281-bab4-11e9-8f5e-251ea8290df3",
    username: "admin@oneclick.es",
    password: "123",
    accessToken:"2-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiMDA0NmYyODEtYmFiNC0xMWU5LThmNWUtMjUxZWE4MjkwZGYzIiwidXNlcm5hbWUiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwiZW1haWwiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlVzdWFyaW8iLCJsYXN0bmFtZSI6IkRlbW8iLCJhdmF0YXIiOiJodHRwczovL2kyYy12Mi5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS8wMDQ2ZjI4MS1iYWI0LTExZTktOGY1ZS0yNTFlYTgyOTBkZjMvYTkucG5nIiwiZXhwIjoxNTc5MjUxNTAyfQ.uDOc7XdXTcf0KcZzMXxaXRoEq0-3PsOn0RtxJycR9RE",
    name: "Usuario Teacher",
    lastname: "Demo",
    email: "admin@oneclick.es",
    avatar: "https://i2c-v2.s3.eu-central-1.amazonaws.com/0046f281-bab4-11e9-8f5e-251ea8290df3/a9.png",
    role_guid: "R02",
    role_name: "Teacher",    
    devices_limit: 24,
    device_contents_expire_days: 1
  },
  {
    guid: "0046f281-bab4-11e9-8f5e-251ea8290555",
    username: "teacher@oneclick.es",
    password: "123",
    accessToken:"3-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiMDA0NmYyODEtYmFiNC0xMWU5LThmNWUtMjUxZWE4MjkwZGYzIiwidXNlcm5hbWUiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwiZW1haWwiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlVzdWFyaW8iLCJsYXN0bmFtZSI6IkRlbW8iLCJhdmF0YXIiOiJodHRwczovL2kyYy12Mi5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS8wMDQ2ZjI4MS1iYWI0LTExZTktOGY1ZS0yNTFlYTgyOTBkZjMvYTkucG5nIiwiZXhwIjoxNTc5MjUxNTAyfQ.uDOc7XdXTcf0KcZzMXxaXRoEq0-3PsOn0RtxJycR9RE",
    name: "Usuario Teacher",
    lastname: "Demo",
    email: "teacher@oneclick.es",
    avatar: "https://i2c-v2.s3.eu-central-1.amazonaws.com/0046f281-bab4-11e9-8f5e-251ea8290df3/a9.png",
    role_guid: "R02",
    role_name: "Teacher",    
    devices_limit: 24,
    device_contents_expire_days: 1
  },
  {
    guid: "0046f281-bab4-11e9-8f5e-251ea829023443",
    username: "student@oneclick.es",
    password: "123",
    accessToken:"4-eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiMDA0NmYyODEtYmFiNC0xMWU5LThmNWUtMjUxZWE4MjkwZGYzIiwidXNlcm5hbWUiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwiZW1haWwiOiJ1c3VhcmlvLmRlbW8yQG9uZWNsaWNrLmVzIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlVzdWFyaW8iLCJsYXN0bmFtZSI6IkRlbW8iLCJhdmF0YXIiOiJodHRwczovL2kyYy12Mi5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS8wMDQ2ZjI4MS1iYWI0LTExZTktOGY1ZS0yNTFlYTgyOTBkZjMvYTkucG5nIiwiZXhwIjoxNTc5MjUxNTAyfQ.uDOc7XdXTcf0KcZzMXxaXRoEq0-3PsOn0RtxJycR9RE",
    name: "Usuario Student",
    lastname: "Demo",
    email: "student@oneclick.es",
    avatar: "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
    role_guid: "R01",
    role_name: "Student",    
    devices_limit: 24,
    device_contents_expire_days: 1
  },
  {
    id: 2,
    username: "user",
    password: "demo",
    email: "user@demo.com",
    accessToken: "access-token-6829bba69dd3421d8762-991e9e806dbf",
    refreshToken: "access-token-f8e4c61a318e4d618b6c199ef96b9e55",
    roles: [2], // Manager
    pic: toAbsoluteUrl("/media/users/100_2.jpg"),
    fullname: "Megan",
    occupation: "Deputy Head of Keenthemes in New York office",
    companyName: "Keenthemes",
    phone: "456669067891",
    address: {
      addressLine: "3487  Ingram Road",
      city: "Greensboro",
      state: "North Carolina",
      postCode: "27409"
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/user",
      facebook: "https://facebook.com/user",
      twitter: "https://twitter.com/user",
      instagram: "https://instagram.com/user"
    }
  },
  {
    id: 3,
    username: "guest",
    password: "demo",
    email: "guest@demo.com",
    accessToken: "access-token-d2dff7b82f784de584b60964abbe45b9",
    refreshToken: "access-token-c999ccfe74aa40d0aa1a64c5e620c1a5",
    roles: [3], // Guest
    pic: toAbsoluteUrl("/media/users/default.jpg"),
    fullname: "Ginobili Maccari",
    occupation: "CFO",
    companyName: "Keenthemes",
    phone: "456669067892",
    address: {
      addressLine: "1467  Griffin Street",
      city: "Phoenix",
      state: "Arizona",
      postCode: "85012"
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/guest",
      facebook: "https://facebook.com/guest",
      twitter: "https://twitter.com/guest",
      instagram: "https://instagram.com/guest"
    }
  }
];
