import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ size }) => {
  return <div className={`logo logo--${size}`} />;
};

Logo.defaultProps = {
  size: 'default',
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'big']),
};

export default Logo;
