import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const CardGeneric = ({ children, title, variant, onClick, className, backgroundColor }) => {
  return (
    <div
      className={clsx(`card-generic`, `card-generic--${variant}`, className, { 'card-generic--onclick': !!onClick })}
      onClick={onClick ? onClick : null}
      style={{ backgroundColor }}
    >
      {title && (
        <div className="card-generic__title">
          <span className="card-generic__title-text"> {title}</span>
        </div>
      )}
      <div className="card-generic__children">{children}</div>
    </div>
  );
};

CardGeneric.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'default-small', 'default-big', 'simple', 'secondary']),
};

CardGeneric.defaultProps = {
  variant: 'default',
};

export default CardGeneric;
