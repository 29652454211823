import { toAbsoluteUrl } from "../../utils/utils";

export default {
  status: "success",
  data: {
    collections: [
      {
        guid: "1001",
        collection: "Kubo",
        description:
          "Kubo es un robot educativo diseñado para que los niños aprendan a programar sin necesidad de utilizar pantallas incluso antes de que sepan leer y escribir.  A través de experiencias prácticas y cercanas a su realidad desarrollarán el pensamiento computacional y lo aplicarán a la programación.",
        thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-kubo.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-kubo-bt.png"),
        color: "#198fcf",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el1",
            level: "Infantil",
            educationYears: [
              {
                guid: "ey1",
                year: "3 años"
              },
              {
                guid: "ey2",
                year: "4 años"
              },
              {
                guid: "ey3",
                year: "5 años"
              }
            ],
            contents: [
              {
                guid: "1001-1",
                name: "Kubo llega al colegio",
                description: "Kubo va a descubrir cómo es el colegio y recorrer sus dependencias. ¡Cuánto por hacer!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo_1_3a.png"),
                visible: true,
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey1",
                    year: "3 años"
                  }
                ]                
              },
              {
                guid: "1001-2",
                name: "Kubo explora su casa",
                description: "Kubo se ha mudado de casa y quiere conocer todos sus rincones.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo_2_3a.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey1",
                    year: "3 años"
                  }
                ]
              },
              {
                guid: "1001-3",
                name: "Kubo visita una granja",
                description: "Kubo va por primera vez a una granja y tiene ganas de aprender cómo es todo en el campo.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo_3_3a.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey1",
                    year: "3 años"
                  }
                ]
              },
              {
                guid: "1001-4",
                name: "Kubo compra en el mercado",
                description: "Kubo va al mercado a hacer la compra. ¡Ñam, ñam, cuánta comida rica!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo_4_3a.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey1",
                    year: "3 años"
                  }
                ]
              },
              {
                guid: "1001-5",
                name: "Kubo descubre un castillo encantado",
                description:
                  "Kubo llega a un castillo y descubre algunas cosas sorprendentes. ¿Será que está encantado?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo_5_3a.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey1",
                    year: "3 años"
                  }
                ]
              },
              {
                guid: "1001-6",
                name: "Kubo camina por el pueblo",
                description: "Kubo llega al pueblo dispuesto a pasárselo genial.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo4_T1.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey2",
                    year: "4 años"
                  }
                ]
              },
              {
                guid: "1001-7",
                name: "Kubo se apunta al polideportivo ",
                description: "Kubo quiere hacer deporte y va al polideportivo para descubrir cuál le gusta más.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo4_T2.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey2",
                    year: "4 años"
                  }
                ]
              },
              {
                guid: "1001-8",
                name: "Kubo va al teatro ",
                description:
                  "Kubo va al teatro por primera vez. ¡Tiene los ojos bien abiertos para no perderse nada de la función!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo4_T3.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey2",
                    year: "4 años"
                  }
                ]
              },
              {
                guid: "1001-9",
                name: "Kubo explora el bosque",
                description:
                  "Un montón de animales se esconden en el bosque. ¿Los encontrará Kubo durante su excursión?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo4_T4.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey2",
                    year: "4 años"
                  }
                ]
              },
              {
                guid: "1001-10",
                name: "Kubo se divierte en el parque de atracciones",
                description:
                  "Kubo llega a un parque en el que hay norias, montañas rusas y coches que vuelan. ¡Qué emocionante!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo4_T5.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey2",
                    year: "4 años"
                  }
                ]
              },
              {
                guid: "1001-11",
                name: "Kubo conoce un hospital",
                description: "Kubo llega al hospital y descubre cuántas cosas pasan allí.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo5_Tapete1.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey3",
                    year: "5 años"
                  }
                ]
              },
              {
                guid: "1001-12",
                name: "Kubo pasea por el parque",
                description:
                  "En este parque Kubo puede ir por las copas de los árboles o perderse dentro de un laberinto. ¡Diversión asegurada!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo5_Tapete2.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey3",
                    year: "5 años"
                  }
                ]
              },
              {
                guid: "1001-13",
                name: "Kubo visita un museo ",
                description: "Kubo llega al museo dispuesto a descubir qué guardan sus salas. ¿Dinosaurios? ¿Momias?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo5_Tapete3.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey3",
                    year: "5 años"
                  }
                ]
              },
              {
                guid: "1001-14",
                name: "Kubo viaja a la sabana",
                description:
                  "En la sabana viven muchos animales que Kubo quiere conocer. ¿Dará mucho miedo ver un león de cerca?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo5_Tapete4.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey3",
                    year: "5 años"
                  }
                ]
              },
              {
                guid: "1001-15",
                name: "Kubo explora el sistema solar ",
                description:
                  "Kubo quiere descubrir todos los planetas, estrellas y naves espaciales que se esconden en el cielo.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Kubo5_Tapete5.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el1",
                    level: "Infantil"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey3",
                    year: "5 años"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        guid: "1002",
        collection: "WeDo 2.0",
        description:
          "Anima a tus alumnos a construir y programar robots con Lego Education y Scratch, a la vez que desarrollan las habilidades propias de la ciudadanía del siglo XXI.",
        //thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-lego-education.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-wedo-bt.png"),
        color: "#99b929",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el2",
            level: "Primaria",
            educationYears: [
              {
                guid: "ey4",
                year: "1.º"
              },
              {
                guid: "ey5",
                year: "2.º"
              },
              {
                guid: "ey6",
                year: "3.º"
              },
              {
                guid: "ey7",
                year: "4.º"
              },
              {
                guid: "ey8",
                year: "5.º"
              },
              {
                guid: "ey9",
                year: "6.º"
              }
            ],
            contents: [
              {
                guid: "1001-67",
                name: "El dragón de Komodo",
                description:
                  "¿A quién no le gustaría tener su propio dragón? Descubrid el increíble mundo de estos animales y construid uno. Haced que ruja cuando encuentre una presa y que muerda con su poderosa mandíbula.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_dragon-komodo_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                visible: true,
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-61",
                name: "El caracol brillante",
                description:
                  "¿Cuánto sabéis de animales? ¡Construid el modelo de un caracol muy especial y aprended más sobre ellos!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_caracol_1P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey4",
                    year: "1.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-62",
                name: "El explorador espacial",
                description:
                  "¿Sabéis que los científicos envían satélites al espacio para saber más del universo? ¡Fabricad tu propio satélite con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo-20-sat-lite-movil-1-p.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey4",
                    year: "1.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-63",
                name: "El detector de presencias",
                description:
                  "¿Os habéis parado a pensar en cómo percibimos lo que nos rodea? ¿Os gustaría saber cuándo se acerca alguien aunque no estéis mirando ni escuchando? ¡Realizad este proyecto y construid un robot que os avise!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_robot-espia_2P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey5",
                    year: "2.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-64",
                name: "El ventilador",
                description:
                  "Seguro que hay días en los que hace tanto calor que no sabéis qué hacer para refrescaros. ¡Con este proyecto prodrás construir tu propio ventilador!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_ventilador_2P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey5",
                    year: "2.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-65",
                name: "El coche Speedy",
                description:
                  "¿Queréis construir vuestro propio coche de competición, hacer que corra más rápido y que termine todas las carreras con éxito? ¡Completad este proyecto y disfrutad de la carrera!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_coche_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-66",
                name: "Misión Luna",
                description:
                  "Seguro que habéis soñado con viajar por el espacio y explorar otros planetas. ¡Ahora podéis hacerlo! Construid vuestro vehículo de exploración espacial y conseguid que no choque con ningún obstáculo.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_luna_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-68",
                name: "Prevención de inundaciones",
                description:
                  "¿Cómo podemos prevenir una inundación? ¡Diseñad una compuerta automática para controlar el agua según los patrones de las precipitaciones!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_inundaciones_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-69",
                name: "Mensajes cifrados",
                description:
                  "¿Te gustaría enviar mensajes mediante códigos? ¡Realizad este proyecto y construid un mando para transferir señales!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_joystick_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-70",
                name: "El medidor",
                description:
                  "¿Os habéis dado cuenta de que estamos rodeados de números? ¡Conoced mejor el espacio que os rodea con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_medidor_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-71",
                name: "El tiovivo",
                description:
                  "¿Queréis construir vuestra propia atracción de feria, hacer que dé vueltas en ambos sentidos y que produzca sonidos cuando queráis? ¡Completad este proyecto y lo conseguiréis!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_tiovivo_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-72",
                name: "La grúa",
                description:
                  "Seguro que habéis visto grúas enormes que mueven objetos muy pesados como si nada. ¡Ahora podéis tener la vuestra! Construidla y conseguid que levante y desplace los objetos que deséis.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_grua_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-73",
                name: "Mariposas sin alas",
                description:
                  "¿Sabéis cómo es una oruga? ¿Os gustaría averiguarlo y poder construir una que pueda programarse? ¡Trabajad en este proyecto y haced que vuestra oruga emita sonidos y cambie de color!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_oruga_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-74",
                name: "¡Peligro, alud!",
                description:
                  "Los aludes de nieve pueden producir grandes desastres. Construid vuestro robot para provocar aludes controlados a distancia y evitar situaciones peligrosas.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_alud_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-75",
                name: "El puente móvil",
                description:
                  "Seguro que habéis visto puentes que se elevan para permitir el paso de los barcos, y que descienden para que continúe el tráfico sobre ellos. ¡Ahora podéis construir uno! Programadlo para que se eleve cuando se aproxime un barco.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_puente_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-76",
                name: "El verderón",
                description:
                  "¿Sabéis lo que es un verderón? Este que vais a construir será muy especial ¿podréis conseguir que se aproxime volando a su nido? ¡Haced que alimente a sus polluelos cuando estos le pidan comida!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_verderon_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-77",
                name: "El perezoso",
                description:
                  "El perezoso es un animal peculiar que vive colgado de las ramas de los árboles en los bosques tropicales. ¿Os gustaría construir uno robótico? ¡Poneos manos a la obra y no os dejéis llevar por la pereza!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_perezoso_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-78",
                name: "¡Canasta!",
                description:
                  "¿Verdad que el baloncesto es un deporte divertido? ¡Pues ahora podéis tener en vuestro equipo un robot que enceste por vosotros cuando le deis la orden! ¡Y, además, llevará la cuenta de las canastas que consiga! ¿Queréis comprobarlo?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_canasta_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-79",
                name: "El sapo corredor",
                description:
                  "¿Sabéis qué tipo de animal es un sapo corredor?  ¿Os gustaría averiguarlo y tener uno que podáis programar para que croe cuando localice una mosca? ¡Haced que corra adelante y atrás mientras atrapa a sus presas!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_sapo_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-80",
                name: "Cada vez más rápido",
                description:
                  "¿Sabéis cómo eran los primeros medios de transporte? ¿Cómo se ha conseguido que sean cada vez más rápidos? ¡Construid vuestro propio coche y descubridlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_velocidad_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-81",
                name: "Plantas y polinizadores",
                description:
                  "Seguro que os habéis fijado en que las flores atraen a los insectos ¿Alguna vez habéis pensado que quizá a las plantas les interesa llamar su atención? ¿Ocurre con todas las flores? ¡Realizad este proyecto y lo descubriréis!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_plantas_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-82",
                name: "Animales adaptados al medio",
                description:
                  "¿Os habéis dado cuenta de que los animales desarrollan distintas características dependiendo de los lugares en los que viven?  ¡Con este proyecto realizaréis el modelo de una serpiente y aprenderéis qué tipos de adaptaciones existen!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_serpiente_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-83",
                name: "Estructuras robustas",
                description:
                  "Los movimientos terrestres originan terremotos. ¿Sabéis qué los edificios se pueden construir de forma que no se caigan si se produce uno? ¡Con este proyecto aprenderéis cómo!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_estructura_robusta_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-84",
                name: "De renacuajo a rana",
                description:
                  "¿Alguna vez habéis visto un renacuajo y os habéis preguntado cómo se transforma en una rana adulta? ¡Descubridlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_rana_pag-32_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-85",
                name: "El vehículo explorador",
                description:
                  "A vuestro alrededor hay todo un mundo por explorar.  ¡Construid vuestro vehículo para descubrirlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_dirigir_pag-42_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-86",
                name: "El camión de reciclaje",
                description:
                  "La basura supone un gran problema para el medioambiente y su gestión sostenible es muy importante. ¡Construid el prototipo de un camión de reciclaje para llevar la basura a la planta de tratamiento adecuada!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_reciclaje_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-87",
                name: "Gorilas en movimiento",
                description:
                  "¿Sabéis cómo se mueven los animales vertebrados? ¡Construid el modelo de un gorila para estudiarlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_gorila_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-88",
                name: "Rodeados de máquinas",
                description:
                  "¿Os imagináis cómo era la vida antes de que se inventasen las máquinas? ¿Sois conscientes de lo mucho que facilitan nuestra vida?  ¡Construid una mano robótica y aprendedlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_agarre_pag_82_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        guid: "1003",
        collection: "WeDo 2.0 y Scratch",
        description:
          "Integra en tus clases el lenguaje computacional a través de Scratch para que tus alumnos lo apliquen programando robots o creando sus propias historias.",
        thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-scratch.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-scratch-bt.png"),
        color: "#b57b49",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el2",
            level: "Primaria",
            educationYears: [
              {
                guid: "ey7",
                year: "4.º"
              },
              {
                guid: "ey8",
                year: "5.º"
              },
              {
                guid: "ey9",
                year: "6.º"
              }
            ],
            contents: [
              {
                guid: "1001-68",
                name: "Prevención de inundaciones",
                description:
                  "¿Cómo podemos prevenir una inundación? ¡Diseñad una compuerta automática para controlar el agua según los patrones de las precipitaciones!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_inundaciones_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-69",
                name: "Mensajes cifrados",
                description:
                  "¿Te gustaría enviar mensajes mediante códigos? ¡Realizad este proyecto y construid un mando para transferir señales!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_joystick_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-70",
                name: "El medidor",
                description:
                  "¿Os habéis dado cuenta de que estamos rodeados de números? ¡Conoced mejor el espacio que os rodea con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_medidor_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-80",
                name: "Cada vez más rápido",
                description:
                  "¿Sabéis cómo eran los primeros medios de transporte? ¿Cómo se ha conseguido que sean cada vez más rápidos? ¡Construid vuestro propio coche y descubridlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_velocidad_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-81",
                name: "Plantas y polinizadores",
                description:
                  "Seguro que os habéis fijado en que las flores atraen a los insectos ¿Alguna vez habéis pensado que quizá a las plantas les interesa llamar su atención? ¿Ocurre con todas las flores? ¡Realizad este proyecto y lo descubriréis!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_plantas_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-82",
                name: "Animales adaptados al medio",
                description:
                  "¿Os habéis dado cuenta de que los animales desarrollan distintas características dependiendo de los lugares en los que viven?  ¡Con este proyecto realizaréis el modelo de una serpiente y aprenderéis qué tipos de adaptaciones existen!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_serpiente_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-83",
                name: "Estructuras robustas",
                description:
                  "Los movimientos terrestres originan terremotos. ¿Sabéis qué los edificios se pueden construir de forma que no se caigan si se produce uno? ¡Con este proyecto aprenderéis cómo!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_estructura_robusta_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-84",
                name: "De renacuajo a rana",
                description:
                  "¿Alguna vez habéis visto un renacuajo y os habéis preguntado cómo se transforma en una rana adulta? ¡Descubridlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_rana_pag-32_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-85",
                name: "El vehículo explorador",
                description:
                  "A vuestro alrededor hay todo un mundo por explorar.  ¡Construid vuestro vehículo para descubrirlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_dirigir_pag-42_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-86",
                name: "El camión de reciclaje",
                description:
                  "La basura supone un gran problema para el medioambiente y su gestión sostenible es muy importante. ¡Construid el prototipo de un camión de reciclaje para llevar la basura a la planta de tratamiento adecuada!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_reciclaje_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-87",
                name: "Gorilas en movimiento",
                description:
                  "¿Sabéis cómo se mueven los animales vertebrados? ¡Construid el modelo de un gorila para estudiarlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_gorila_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-88",
                name: "Rodeados de máquinas",
                description:
                  "¿Os imagináis cómo era la vida antes de que se inventasen las máquinas? ¿Sois conscientes de lo mucho que facilitan nuestra vida?  ¡Construid una mano robótica y aprendedlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_agarre_pag_82_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        guid: "1004",
        collection: "Spike",
        description:
          "Reta a tus alumnos a mejorar el mundo en el que viven construyendo y programando robots con Lego Spike.",
        //thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-lego-education.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-spike-bt.png"),
        color: "#0fb0bc",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el2",
            level: "Primaria",
            educationYears: [
              {
                guid: "ey8",
                year: "5.º"
              },
              {
                guid: "ey9",
                year: "6.º"
              }
            ],
            contents: [
              {
                guid: "1001-89",
                name: "El bailarín de break dance",
                color: "#0fb0bc",                
                description: "¿Demasiado tiempo sentados? ¡Cread un bailarín que os recuerde que os tenéis que mover!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_breakdance_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                visible: false,
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-90",
                name: "Fuera de servicio",
                description:
                  "Vuestro carro de reparto se ha vuelto loco, se choca con todo y no entrega los paquetes… ¿Seréis capaces de arreglarlo?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_fuera-de-servicio_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-91",
                name: "El Dr. Piezas",
                description:
                  "Evitar el contagio de enfermedades es todo un reto de la medicina. ¡Programad un doctor para que la salud llegue a todos los rincones!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_Dr-Heartbeat_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-92",
                name: "Orden en la sala",
                description:
                  "¿Todo en orden? ¡Construid y programad  vuestro robot para que os ayude a ordenar la sala!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_base-de-conduccion_basica_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-93",
                name: "Un espacio seguro",
                description:
                  "¿Os imáginais un sitio seguro donde guardar vuestros objetos más apreciados? ¡Cread vuestro espacio seguro y programadlo para que solo podais abrirlo vosotros!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_caja-fuerte_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-94",
                name: "La silla mecánica",
                description:
                  "La ciudad está llena de obstáculos. ¡Programad vuestra silla mecánica para que llegue fácilmente a su destino!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_silla-ruedas_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              }
            ]
          },
          {
            guid: "el3",
            level: "ESO",
            educationYears: [
              {
                guid: "ey10",
                year: "1.º"
              },
              {
                guid: "ey11",
                year: "2.º"
              }
            ],
            contents: [
              {
                guid: "1001-95",
                name: "La mano robótica",
                description:
                  "¿Alguna vez habéis pensado en diseñar algo para mejorar la vida de otra persona? Diseñad una prótesis de mano y encontrad otra forma de abrir una puerta o comer una manzana.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_protesis_1E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey10",
                    year: "1.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-96",
                name: "Robot en acción",
                description:
                  "Hay lugares que son inaccesibles para las personas. ¡Diseñad y programad vuestro robot para que llegue a todos los sitios!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_Nanobot_1E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey10",
                    year: "1.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-97",
                name: "Automatízalo",
                description:
                  "Vuestro carro de reparto necesita mejoras para que podáis asumir más pedidos y cargar más paquetes. ¿Cómo podéis optimizarlo? ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_automatizalo_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey11",
                    year: "2.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-98",
                name: "Base de conducción ",
                description:
                  "¿Estáis preparados para superar retos? ¡Construid vuestra base de conducción y esforzaos para superarlos!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_base-de-conduccion-avanzada_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey11",
                    year: "2.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        guid: "1005",
        collection: "Impresión 3D",
        description:
          "Introduce a tus alumnos en el conocimiento de la impresión 3D de forma sencilla, potenciando su creatividad y diseñando objetos que mejoren su vida cotidiana y el entorno.",
        thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-impresion-3d.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-impresion-bt.png"),
        color: "#ffa800",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el2",
            level: "Primaria",
            educationYears: [
              {
                guid: "ey6",
                year: "3.º"
              },
              {
                guid: "ey7",
                year: "4.º"
              },
              {
                guid: "ey8",
                year: "5.º"
              },
              {
                guid: "ey9",
                year: "6.º"
              }
            ],
            contents: [
              {
                guid: "1001-99",
                name: "Un planeta más limpio",
                description:
                  "¿Qué podéis hacer para vivir en un lugar menos contaminado? ¡Realizad este proyecto y contribuid a un planeta más limpio!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Un_planeta_mas_limpio_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-100",
                name: "Hacia la sostenibilidad",
                description:
                  "¿Sabéis para cuántas cosas puede servir un portalápices? ¡Fabricad uno para divulgar los objetivos de desarrollo sostenible!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Hacia_la_sostenibilidad_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-101",
                name: "Arte natural",
                description:
                  "¿Alguna vez habéis visitado un museo de la naturaleza? ¡Organizadlo para que todos en el colegio puedan aprender y disfrutar de uno!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Arte_natural_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-102",
                name: "Continúa tu lectura",
                description:
                  "¿Os habéis parado a pensar en todos los beneficios que tiene la lectura? Ahora podéis crear vuestro propio marcapáginas personalizado y dinamizar vuestra propia campaña de animación a la lectura.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Continua_tu_lectura_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-103",
                name: "Juguetes para todos",
                description:
                  "¿Habéis jugado a la peonza alguna vez? ¿Os gustaría hacer la vuestra? ¡Fabricad una para una campaña de donación de juguetes!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Juguetes_para_todos_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-104",
                name: "Fiestas del mundo",
                description:
                  "¿Verdad que es súper divertido disfrazarse? ¡Completad este proyecto e investigad sobre los disfraces de diferentes celebraciones!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Fiestas_del_mundo_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-105",
                name: "La canica azul",
                description:
                  "¿Sabéis que podemos medir cuánta lluvia cae? Con este proyecto aprenderéis cómo se hace y cómo se interpretan los resultados.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/I3D_La_canica_azul_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-106",
                name: "Un pájaro viajero",
                description:
                  "¿Conocéis la importancia de las golondrinas en el ecosistema? Algunas personas destruyen sus nidos, por eso vosotros vais a ayudarlas. ¡Cread casas para las golondrinas y protegedlas!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Un_pajaro_viajero_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-107",
                name: "Comida sana",
                description:
                  "¿Seguís una dieta y un estilo de vida saludables?  ¡Diseñad un recipiente para transportar fruta y fomentadlos con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Comida_sana_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-108",
                name: "Aprende con los juegos",
                description:
                  "¿Os gustan los juegos de mesa? ¿Alguna vez habéis pensado en crear uno personalizado? ¡En este proyecto podréis diseñar un dado y las piezas del juego para crear vuestra propuesta!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Aprende_con_los_juegos_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-109",
                name: "Atrévete a inventar",
                description:
                  "¿Conocéis algún invento importante? ¿Sabríais decir quién lo fabricó y cómo? ¡Convertíos en inventores diseñando un sistema de poleas y experimentad con él!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Atrevete_a_inventar_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              },
              {
                guid: "1001-110",
                name: "Energías renovables",
                description:
                  "¿Sabéis por qué son importantes las energías renovables para el medioambiente? ¡Ahora podéis fabricar un molino que os ayude a entender y fomentar el uso de est tipo de energías!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Energias_renovables_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ],
                customTags: [
                  {
                    guid: "50",
                    type: "design_for_change",                    
                    tag: true,
                  },
                ]
              }
            ]
          }
        ]
      },
      /*{
        guid: "1006",
        collection: "Design for Change",
        description:
          'Acompaña a tus alumnos en su proceso de aprendizaje y guíales en los proyectos con las pautas que te facilitará Design for change para que cada uno viva su propia experiencia del "¡Yo puedo!".',
        thumbnail: toAbsoluteUrl("/app/media/channels_mock/thumb-dfc-ok.png"),
        icon: toAbsoluteUrl("/app/media/channels_mock/canal-dfc-bt.png"),
        color: "#f8466e",
        update_at: "2020-02-17T09:47:50.000Z",
        educationLevels: [
          {
            guid: "el2",
            level: "Primaria",
            educationYears: [
              {
                guid: "ey4",
                year: "1.º"
              },
              {
                guid: "ey5",
                year: "2.º"
              },
              {
                guid: "ey6",
                year: "3.º"
              },
              {
                guid: "ey7",
                year: "4.º"
              },
              {
                guid: "ey8",
                year: "5.º"
              },
              {
                guid: "ey9",
                year: "6.º"
              }
            ],
            contents: [
              {
                guid: "1001-67",
                name: "El dragón de Komodo",
                description:
                  "¿A quién no le gustaría tener su propio dragón? Descubrid el increíble mundo de estos animales y construid uno. Haced que ruja cuando encuentre una presa y que muerda con su poderosa mandíbula.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_dragon-komodo_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                visible: true,
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-61",
                name: "El caracol brillante",
                description:
                  "¿Cuánto sabéis de animales? ¡Construid el modelo de un caracol muy especial y aprended más sobre ellos!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_caracol_1P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey4",
                    year: "1.º"
                  }
                ]
              },
              {
                guid: "1001-62",
                name: "El explorador espacial",
                description:
                  "¿Sabéis que los científicos envían satélites al espacio para saber más del universo? ¡Fabricad tu propio satélite con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo-20-sat-lite-movil-1-p.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey4",
                    year: "1.º"
                  }
                ]
              },
              {
                guid: "1001-63",
                name: "El detector de presencias",
                description:
                  "¿Os habéis parado a pensar en cómo percibimos lo que nos rodea? ¿Os gustaría saber cuándo se acerca alguien aunque no estéis mirando ni escuchando? ¡Realizad este proyecto y construid un robot que os avise!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_robot-espia_2P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey5",
                    year: "2.º"
                  }
                ]
              },
              {
                guid: "1001-64",
                name: "El ventilador",
                description:
                  "Seguro que hay días en los que hace tanto calor que no sabéis qué hacer para refrescaros. ¡Con este proyecto prodrás construir tu propio ventilador!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_ventilador_2P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey5",
                    year: "2.º"
                  }
                ]
              },
              {
                guid: "1001-65",
                name: "El coche Speedy",
                description:
                  "¿Queréis construir vuestro propio coche de competición, hacer que corra más rápido y que termine todas las carreras con éxito? ¡Completad este proyecto y disfrutad de la carrera!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_coche_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-66",
                name: "Misión Luna",
                description:
                  "Seguro que habéis soñado con viajar por el espacio y explorar otros planetas. ¡Ahora podéis hacerlo! Construid vuestro vehículo de exploración espacial y conseguid que no choque con ningún obstáculo.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_luna_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-68",
                name: "Prevención de inundaciones",
                description:
                  "¿Cómo podemos prevenir una inundación? ¡Diseñad una compuerta automática para controlar el agua según los patrones de las precipitaciones!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_inundaciones_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-69",
                name: "Mensajes cifrados",
                description:
                  "¿Te gustaría enviar mensajes mediante códigos? ¡Realizad este proyecto y construid un mando para transferir señales!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_joystick_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-70",
                name: "El medidor",
                description:
                  "¿Os habéis dado cuenta de que estamos rodeados de números? ¡Conoced mejor el espacio que os rodea con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_medidor_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-71",
                name: "El tiovivo",
                description:
                  "¿Queréis construir vuestra propia atracción de feria, hacer que dé vueltas en ambos sentidos y que produzca sonidos cuando queráis? ¡Completad este proyecto y lo conseguiréis!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_tiovivo_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-72",
                name: "La grúa",
                description:
                  "Seguro que habéis visto grúas enormes que mueven objetos muy pesados como si nada. ¡Ahora podéis tener la vuestra! Construidla y conseguid que levante y desplace los objetos que deséis.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_grua_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-73",
                name: "Mariposas sin alas",
                description:
                  "¿Sabéis cómo es una oruga? ¿Os gustaría averiguarlo y poder construir una que pueda programarse? ¡Trabajad en este proyecto y haced que vuestra oruga emita sonidos y cambie de color!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_oruga_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-74",
                name: "¡Peligro, alud!",
                description:
                  "Los aludes de nieve pueden producir grandes desastres. Construid vuestro robot para provocar aludes controlados a distancia y evitar situaciones peligrosas.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_alud_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-75",
                name: "El puente móvil",
                description:
                  "Seguro que habéis visto puentes que se elevan para permitir el paso de los barcos, y que descienden para que continúe el tráfico sobre ellos. ¡Ahora podéis construir uno! Programadlo para que se eleve cuando se aproxime un barco.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_puente_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-76",
                name: "El verderón",
                description:
                  "¿Sabéis lo que es un verderón? Este que vais a construir será muy especial ¿podréis conseguir que se aproxime volando a su nido? ¡Haced que alimente a sus polluelos cuando estos le pidan comida!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_verderon_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-77",
                name: "El perezoso",
                description:
                  "El perezoso es un animal peculiar que vive colgado de las ramas de los árboles en los bosques tropicales. ¿Os gustaría construir uno robótico? ¡Poneos manos a la obra y no os dejéis llevar por la pereza!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_perezoso_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-78",
                name: "¡Canasta!",
                description:
                  "¿Verdad que el baloncesto es un deporte divertido? ¡Pues ahora podéis tener en vuestro equipo un robot que enceste por vosotros cuando le deis la orden! ¡Y, además, llevará la cuenta de las canastas que consiga! ¿Queréis comprobarlo?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_canasta_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-79",
                name: "El sapo corredor",
                description:
                  "¿Sabéis qué tipo de animal es un sapo corredor?  ¿Os gustaría averiguarlo y tener uno que podáis programar para que croe cuando localice una mosca? ¡Haced que corra adelante y atrás mientras atrapa a sus presas!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_sapo_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-80",
                name: "Cada vez más rápido",
                description:
                  "¿Sabéis cómo eran los primeros medios de transporte? ¿Cómo se ha conseguido que sean cada vez más rápidos? ¡Construid vuestro propio coche y descubridlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_velocidad_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-81",
                name: "Plantas y polinizadores",
                description:
                  "Seguro que os habéis fijado en que las flores atraen a los insectos ¿Alguna vez habéis pensado que quizá a las plantas les interesa llamar su atención? ¿Ocurre con todas las flores? ¡Realizad este proyecto y lo descubriréis!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_plantas_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-82",
                name: "Animales adaptados al medio",
                description:
                  "¿Os habéis dado cuenta de que los animales desarrollan distintas características dependiendo de los lugares en los que viven?  ¡Con este proyecto realizaréis el modelo de una serpiente y aprenderéis qué tipos de adaptaciones existen!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_serpiente_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-83",
                name: "Estructuras robustas",
                description:
                  "Los movimientos terrestres originan terremotos. ¿Sabéis qué los edificios se pueden construir de forma que no se caigan si se produce uno? ¡Con este proyecto aprenderéis cómo!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_estructura_robusta_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-84",
                name: "De renacuajo a rana",
                description:
                  "¿Alguna vez habéis visto un renacuajo y os habéis preguntado cómo se transforma en una rana adulta? ¡Descubridlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_rana_pag-32_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-85",
                name: "El vehículo explorador",
                description:
                  "A vuestro alrededor hay todo un mundo por explorar.  ¡Construid vuestro vehículo para descubrirlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_dirigir_pag-42_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-86",
                name: "El camión de reciclaje",
                description:
                  "La basura supone un gran problema para el medioambiente y su gestión sostenible es muy importante. ¡Construid el prototipo de un camión de reciclaje para llevar la basura a la planta de tratamiento adecuada!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_reciclaje_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-87",
                name: "Gorilas en movimiento",
                description:
                  "¿Sabéis cómo se mueven los animales vertebrados? ¡Construid el modelo de un gorila para estudiarlo!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo_gorila_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-88",
                name: "Rodeados de máquinas",
                description:
                  "¿Os imagináis cómo era la vida antes de que se inventasen las máquinas? ¿Sois conscientes de lo mucho que facilitan nuestra vida?  ¡Construid una mano robótica y aprendedlo con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/wedo20_agarre_pag_82_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-89",
                name: "El bailarín de break dance",
                description: "¿Demasiado tiempo sentados? ¡Cread un bailarín que os recuerde que os tenéis que mover!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_breakdance_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-90",
                name: "Fuera de servicio",
                description:
                  "Vuestro carro de reparto se ha vuelto loco, se choca con todo y no entrega los paquetes… ¿Seréis capaces de arreglarlo?",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_fuera-de-servicio_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-91",
                name: "El Dr. Piezas",
                description:
                  "Evitar el contagio de enfermedades es todo un reto de la medicina. ¡Programad un doctor para que la salud llegue a todos los rincones!  ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_Dr-Heartbeat_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-92",
                name: "Orden en la sala",
                description:
                  "¿Todo en orden? ¡Construid y programad  vuestro robot para que os ayude a ordenar la sala!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_base-de-conduccion_basica_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-93",
                name: "Un espacio seguro",
                description:
                  "¿Os imáginais un sitio seguro donde guardar vuestros objetos más apreciados? ¡Cread vuestro espacio seguro y programadlo para que solo podais abrirlo vosotros!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_caja-fuerte_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-94",
                name: "La silla mecánica",
                description:
                  "La ciudad está llena de obstáculos. ¡Programad vuestra silla mecánica para que llegue fácilmente a su destino!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_silla-ruedas_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-99",
                name: "Un planeta más limpio",
                description:
                  "¿Qué podéis hacer para vivir en un lugar menos contaminado? ¡Realizad este proyecto y contribuid a un planeta más limpio!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Un_planeta_mas_limpio_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-100",
                name: "Hacia la sostenibilidad",
                description:
                  "¿Sabéis para cuántas cosas puede servir un portalápices? ¡Fabricad uno para divulgar los objetivos de desarrollo sostenible!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Hacia_la_sostenibilidad_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-101",
                name: "Arte natural",
                description:
                  "¿Alguna vez habéis visitado un museo de la naturaleza? ¡Organizadlo para que todos en el colegio puedan aprender y disfrutar de uno!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Arte_natural_3P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey6",
                    year: "3.º"
                  }
                ]
              },
              {
                guid: "1001-102",
                name: "Continúa tu lectura",
                description:
                  "¿Os habéis parado a pensar en todos los beneficios que tiene la lectura? Ahora podéis crear vuestro propio marcapáginas personalizado y dinamizar vuestra propia campaña de animación a la lectura.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Continua_tu_lectura_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-103",
                name: "Juguetes para todos",
                description:
                  "¿Habéis jugado a la peonza alguna vez? ¿Os gustaría hacer la vuestra? ¡Fabricad una para una campaña de donación de juguetes!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Juguetes_para_todos_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-104",
                name: "Fiestas del mundo",
                description:
                  "¿Verdad que es súper divertido disfrazarse? ¡Completad este proyecto e investigad sobre los disfraces de diferentes celebraciones!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Fiestas_del_mundo_4P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey7",
                    year: "4.º"
                  }
                ]
              },
              {
                guid: "1001-105",
                name: "La canica azul",
                description:
                  "¿Sabéis que podemos medir cuánta lluvia cae? Con este proyecto aprenderéis cómo se hace y cómo se interpretan los resultados.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/I3D_La_canica_azul_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-106",
                name: "Un pájaro viajero",
                description:
                  "¿Conocéis la importancia de las golondrinas en el ecosistema? Algunas personas destruyen sus nidos, por eso vosotros vais a ayudarlas. ¡Cread casas para las golondrinas y protegedlas!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Un_pajaro_viajero_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-107",
                name: "Comida sana",
                description:
                  "¿Seguís una dieta y un estilo de vida saludables?  ¡Diseñad un recipiente para transportar fruta y fomentadlos con este proyecto!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Comida_sana_5P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey8",
                    year: "5.º"
                  }
                ]
              },
              {
                guid: "1001-108",
                name: "Aprende con los juegos",
                description:
                  "¿Os gustan los juegos de mesa? ¿Alguna vez habéis pensado en crear uno personalizado? ¡En este proyecto podréis diseñar un dado y las piezas del juego para crear vuestra propuesta!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Aprende_con_los_juegos_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-109",
                name: "Atrévete a inventar",
                description:
                  "¿Conocéis algún invento importante? ¿Sabríais decir quién lo fabricó y cómo? ¡Convertíos en inventores diseñando un sistema de poleas y experimentad con él!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Atrevete_a_inventar_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              },
              {
                guid: "1001-110",
                name: "Energías renovables",
                description:
                  "¿Sabéis por qué son importantes las energías renovables para el medioambiente? ¡Ahora podéis fabricar un molino que os ayude a entender y fomentar el uso de est tipo de energías!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/3D_Energias_renovables_6P.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el2",
                    level: "Primaria"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey9",
                    year: "6.º"
                  }
                ]
              }
            ]
          },
          {
            guid: "el3",
            level: "ESO",
            educationYears: [
              {
                guid: "ey10",
                year: "1.º"
              },
              {
                guid: "ey11",
                year: "2.º"
              }
            ],
            contents: [
              {
                guid: "1001-95",
                name: "La mano robótica",
                description:
                  "¿Alguna vez habéis pensado en diseñar algo para mejorar la vida de otra persona? Diseñad una prótesis de mano y encontrad otra forma de abrir una puerta o comer una manzana.",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_protesis_1E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey10",
                    year: "1.º"
                  }
                ]
              },
              {
                guid: "1001-96",
                name: "Robot en acción",
                description:
                  "Hay lugares que son inaccesibles para las personas. ¡Diseñad y programad vuestro robot para que llegue a todos los sitios!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/Spike_Nanobot_1E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey10",
                    year: "1.º"
                  }
                ]
              },
              {
                guid: "1001-97",
                name: "Automatízalo",
                description:
                  "Vuestro carro de reparto necesita mejoras para que podáis asumir más pedidos y cargar más paquetes. ¿Cómo podéis optimizarlo? ",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_automatizalo_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey11",
                    year: "2.º"
                  }
                ]
              },
              {
                guid: "1001-98",
                name: "Base de conducción ",
                description:
                  "¿Estáis preparados para superar retos? ¡Construid vuestra base de conducción y esforzaos para superarlos!",
                thumbnail: toAbsoluteUrl("/app/media/channels_mock/projects/spike_base-de-conduccion-avanzada_2E.png"),
                update_at: "2020-02-17T09:47:50.000Z",
                educationLevels: [
                  {
                    guid: "el3",
                    level: "ESO"
                  }
                ],
                educationYears: [
                  {
                    guid: "ey11",
                    year: "2.º"
                  }
                ]
              }
            ]
          }
        ]
      }*/
    ]
  }
};
