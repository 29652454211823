import { useTranslate } from '_core/hooks/useTranslate';
import { getGamifiedMedal, getGamifiedSubtitle, getGamifiedTitle } from './GamifiedToast.service';

function useGamifiedToast(props) {
  const { t } = useTranslate();
  const { gamified } = props;
  const title = getGamifiedTitle({ gamified, t });
  const subtitle = getGamifiedSubtitle({ gamified, t });
  const medal = getGamifiedMedal({ gamified });

  return { medal, subtitle, title };
}

export default useGamifiedToast;
