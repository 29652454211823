import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Hr from '_core/modules/atoms/Hr';
import Icon from '_core/modules/atoms/Icon';
import HeaderTabs from '_core/modules/components/tabs/HeaderTabs';
import FooterButtons from '_core/modules/compositions/FooterButtons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const DialogDefault = ({
  t,
  open,
  onClose,
  title,
  iconTitle,
  text,
  buttonTextCancel = t('actions:Cancel'),
  buttonTextSucess = t('actions:Confirm'),
  onSuccess,
  onCancel,
  disabledSuccess,
  children,
  className,
  separator,
  showHeader,
  customHeader,
  footerWithBackground,
  customFooterLeft,
  size,
  tabs,
  tabActive,
  onChangeTab,
  hideHeader,
  ...props
}) => {
  const { i18n, tReady, ...propsDialog } = props;

  return (
    <Dialog
      disableEnforceFocus={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={false}
      className={clsx('dialog', className, `dialog--size-${size}`, {
        'dialog--with-footer-with-background': footerWithBackground,
        'dialog--with-icon': iconTitle,
      })}
      {...propsDialog}
    >
      {!hideHeader && (title || onClose || customHeader) && showHeader && (
        <div className="dialog__header">
          <div className="dialog__title-container">
            {title && (
              <div className="dialog__title-wrapper">
                {iconTitle && <Icon type={iconTitle} color="primary" />}
                <span className="dialog__title">{title}</span>
              </div>
            )}
            {tabs && (
              <div className="dialog__title-tabs">
                <HeaderTabs tabs={tabs} tabActive={tabActive} onChangeTab={onChangeTab} />
              </div>
            )}
            <div className="dialog__close-container">
              {onClose && (
                <div className="dialog__close" onClick={onClose}>
                  <Icon size="big" type="close" />
                </div>
              )}
            </div>
          </div>
          {text && <span className="dialog__text">{text}</span>}
          {customHeader && <div className="dialog__custom-header">{customHeader}</div>}
          {separator && (
            <div className="dialog__hr">
              <Hr size="small" />
            </div>
          )}
        </div>
      )}
      {children && <DialogContent>{children}</DialogContent>}
      {(onSuccess || onCancel) && (
        <DialogActions>
          <div className="dialog__actions">
            <div className="dialog__actions-left">{customFooterLeft && customFooterLeft}</div>
            <FooterButtons
              buttonTextCancel={buttonTextCancel}
              buttonTextSucess={buttonTextSucess}
              onSuccess={onSuccess}
              onCancel={onCancel}
              disabledSuccess={disabledSuccess}
            />
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogDefault.propTypes = {
  buttonTextCancel: PropTypes.string,
  buttonTextSucess: PropTypes.string,
  hideHeader: PropTypes.bool,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  showHeader: PropTypes.bool,
  disabledSuccess: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'xxs', 'xs', 's', 'small', 'xl', 'xxl', 'all-screen']),
};

DialogDefault.defaultProps = {
  open: false,
  showHeader: true,
  disabledSuccess: false,
  size: 'default',
  hideHeader: false,
};

export default withTranslation(['common', 'actions', 'placeholder', 'types-resources'])(DialogDefault);
