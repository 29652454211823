import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './../Icon';
import useIconBubble from './useIconBubble';

const IconBubble = ({ colorIcon, sizeIcon, typeIcon, size, gradient, variant, background, className }) => {
  const { sizeIconState, colorIconState } = useIconBubble({ colorIcon, variant, size });

  return (
    <div
      className={clsx('icon-bubble', `icon-bubble--size-${size}`, `icon-bubble--background-${background}`, `icon-bubble--variant-${variant}`, className)}
      style={{ background: gradient && gradient }}
    >
      {typeIcon && <Icon type={typeIcon} color={colorIconState} size={sizeIcon || sizeIconState} />}
    </div>
  );
};

IconBubble.propTypes = {
  typeIcon: PropTypes.string,
  colorIcon: PropTypes.oneOf([
    'default',
    'gradient',
    'primary',
    'secondary',
    'white',
    'blue',
    'blue2',
    'black',
    'light',
    'success',
    'red',
    'yellow',
    'label',
    'green',
    'brown',
  ]),
  size: PropTypes.oneOf(['xs', 's', 'small', 'medium', 'l', 'big', 'xl']),
  gradient: PropTypes.string,
  background: PropTypes.oneOf(['default', 'green', 'orange', 'orange-primary', 'gray', 'dark', 'light', 'white']),
  variant: PropTypes.oneOf(['default', 'inverse', 'square']),
};

IconBubble.defaultProps = {
  size: 'small',
  variant: 'default',
  background: 'default',
};

export default IconBubble;
