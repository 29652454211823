export const USER_ACTIONS = {
  CONSUMPTION: 'consumption',
};

export function initUserForTracking(user) {
  window.analytics.identify(user.guid, {
    tenant: 'RFP',
    environment: 'production',
    user: user.guid,
    name: user.name,
    lastname: user.lastname,
    role: user.role_guid,
  });
}

export function trackAction(action, data) {
  window.analytics.track(action, { timestamp: Date.now(), tenant: 'RFP', environment: 'production', ...data });
}
