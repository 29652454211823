import React, { useState } from 'react';
import Icon from '_core/modules/atoms/Icon';
import InputText from '_core/modules/atoms/InputText';
import FooterButtons from '_core/modules/compositions/FooterButtons';
import { ValidateEmail } from '_core/utils/utils';

// import Icon from '../../atoms/Icon';
// import { lessonType } from '../../../variables/constant';

const ForgotPasswordForm = ({ t, onCancel, onSuccess, state }) => {
  const [email, setEmail] = useState('');

  function onSendEmail() {
    onSuccess(email);
  }

  return (
    <div className="forgot-password-form">
      {state === 'success' ? (
        <div className="forgot-password__success">
          <Icon type="check-checked-round-fill" color="primary" size="xl" />
          <div className="forgot-password__success-text">{t('recover:Great! Review you inbox to see the instructions')}</div>
        </div>
      ) : (
        <div>
          <div className="forgot-password__subtitle">{t('recover:Type your email to receive a recover link')}</div>
          <InputText
            placeholder={t('tucorreo@email.com')}
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />
          <div className="new-lesson-form__buttons">
            <FooterButtons
              buttonTextCancel={t('actions:Cancel')}
              buttonTextSucess={t('actions:Send')}
              onCancel={onCancel}
              onSuccess={onSendEmail}
              disabledSuccess={!ValidateEmail(email)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ForgotPasswordForm.defaultProps = {
  onCancel: () => {},
  onSuccess: () => {},
};

export default ForgotPasswordForm;
