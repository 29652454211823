import { CometChat } from '@cometchat/chat-sdk-javascript';
import { CometChatContacts, CometChatConversationsWithMessages } from '@cometchat/chat-uikit-react';
import { ContactsStyle, ConversationsConfiguration, UsersConfiguration, WithMessagesStyle } from '@cometchat/uikit-shared';
import { useCourses } from '_core/hooks/course/useCourses';
import Icon from '_core/modules/atoms/Icon';
import { removeDuplicatesInArray } from '_core/utils/utils';
import React, { useEffect, useState } from 'react';

const STEPS = {
  RECENTS: 0,
  CONTACTS: 1,
};

export function ChatWithMessages() {
  const { courses } = useCourses();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroups, setSelectedGroup] = useState(null);
  const [step, setStep] = useState(STEPS.RECENTS);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (courses?.length > 0) {
      let guids = courses.map((course) => course.school_group_guid);
      guids = removeDuplicatesInArray(guids);
      setTags(guids);
    }
  }, [courses]);

  const conversationConfig = new ConversationsConfiguration({
    menu: (
      <div className="chat-new-contact" onClick={onShowContacts}>
        <Icon type="new" color="primary" size="big" />
      </div>
    ),
  });

  function onShowContacts() {
    setSelectedUser(null);
    setStep(STEPS.CONTACTS);
  }

  function onUserSelected(user, group) {
    if (user) setSelectedUser(user);
    if (group) setSelectedGroup(group);
    setStep(STEPS.RECENTS);
  }

  function getStep() {
    if (step === STEPS.RECENTS)
      return (
        <CometChatConversationsWithMessages
          conversationsWithMessagesStyle={
            new WithMessagesStyle({
              width: '800px',
              height: '600px',
            })
          }
          conversationsConfiguration={conversationConfig}
          user={selectedUser}
          group={selectedGroups}
        />
      );
    if (step === STEPS.CONTACTS) {
      return (
        <CometChatContacts
          contactsStyle={
            new ContactsStyle({
              width: '800px',
              height: '600px',
            })
          }
          usersConfiguration={new UsersConfiguration({ usersRequestBuilder: new CometChat.UsersRequestBuilder().setLimit('50').setTags(tags) })}
          onItemClick={onUserSelected}
        />
      );
    }
  }

  return (
    <div className="chat-with-messages">
      <div>{getStep()}</div>
    </div>
  );
}
