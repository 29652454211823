import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './../Icon';

const Tag = ({ text, color, tooltip, size, icon, iconColor, onClick, styleText, style }) => {
  function renderTag() {
    return (
      <div style={style} className={`tag tag--color-${color} tag--size-${size} ${onClick ? 'tag--clickable' : ''}`} {...(onClick ? { onClick } : {})}>
        <span className="tag__text" style={styleText}>
          {text}
        </span>
        {icon && (
          <div className="tag__icon">
            <Icon color={iconColor} type={icon} />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {tooltip && (
        <Tooltip classes={{ tooltip: 'tag-tooltip', popper: 'tag-tooltip__popper' }} title={tooltip} placement="top">
          {renderTag()}
        </Tooltip>
      )}
      {!tooltip && renderTag()}
    </>
  );
};

Tag.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.oneOf(['default', 'primary']),
  size: PropTypes.oneOf(['default', 'big', 'medium']),
  iconColor: PropTypes.oneOf([
    '',
    ' ',
    'default',
    'gradient',
    'primary',
    'secondary',
    'white',
    'blue',
    'blue2',
    'black',
    'light',
    'success',
    'red',
    'yellow',
    'label',
    'green',
    'brown',
    'original',
  ]),
  onClick: PropTypes.func,
};

Tag.defaultProps = {
  text: '',
  color: 'default',
  size: 'default',
  iconColor: 'default',
};

export default Tag;
