import { EVENT_SOCKET, getMedal } from '_core/modules/components/gamified/gamified.service';
import i18next from 'i18next';

export function getGamifiedEvent(event) {
  return EVENT_SOCKET.find((item) => item.guid === event);
}

export function getGamifiedMedal({ gamified }) {
  return gamified ? getMedal(gamified?.medal) : '';
}

export function getGamifiedTitle({ gamified }) {
  const title = i18next.t('gamified:You ve gotten a medal');
  const find = EVENT_SOCKET.find((item) => item.guid === gamified?.guid);

  return find ? i18next.t(find.title) : title;
}

export function getGamifiedSubtitle({ gamified }) {
  const subtitle = i18next.t('gamified:You have answered correctly');
  const find = EVENT_SOCKET.find((item) => item.guid === gamified?.guid);

  return find ? i18next.t(find.subtitle, { number: find?.number }) : subtitle;
}
