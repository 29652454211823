import { isStudent } from './user';

export const notificationsTypes = {
  TODO: 'toDo',
  SOCIAL: 'social',
};

export const notificationsSubtypes = {
  SOCIAL: {
    COMMENT: 'stream-comment',
    POST: 'stream-post',
    ASSESSMENTCOMMENT: 'assessment-comment',
    JOBCOMMENT: 'job-comment',
  },
  TODO: {
    ASSIGNED: 'assessment-assigned',
    REASSIGNED: 'assessment-reassigned',
    EVALUATED: 'assessment-evaluated',
    ONEDAY: 'due-date-tomorrow',
    LATE: 'due-date-today',
    DELIVERATCOMPLETE: 'teacher-deliver-at-complete',
    PUBLISHPENDING: 'teacher-publish-jobs-pending',
    STUDENTDELIVER: 'teacher-job-delivered',
    ALLDELIVER: 'teacher-all-jobs-completed',
    RESOURCE_ASSIGNED: 'resource-assigned',
    RESOURCE_EVALUATED: 'resource-evaluated',
    RESOURCE_DELIVERED: 'resource-delivered',
  },
};

export const getTypeOfSubTypeNotification = (subtypeNotification) => {
  if (
    subtypeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.EVALUATED ||
    subtypeNotification === notificationsSubtypes.TODO.ONEDAY ||
    subtypeNotification === notificationsSubtypes.TODO.LATE ||
    subtypeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    subtypeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    subtypeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.ALLDELIVER ||
    subtypeNotification === notificationsSubtypes.TODO.RESOURCE_ASSIGNED ||
    subtypeNotification === notificationsSubtypes.TODO.RESOURCE_EVALUATED
  ) {
    return notificationsTypes.TODO;
  } else if (
    subtypeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.POST ||
    subtypeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    subtypeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT
  ) {
    return notificationsTypes.SOCIAL;
  }
};

export const isNotificationTypeToDo = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.TODO.ASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.REASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.EVALUATED ||
    typeNotification === notificationsSubtypes.TODO.ONEDAY ||
    typeNotification === notificationsSubtypes.TODO.LATE ||
    typeNotification === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
    typeNotification === notificationsSubtypes.TODO.PUBLISHPENDING ||
    typeNotification === notificationsSubtypes.TODO.STUDENTDELIVER ||
    typeNotification === notificationsSubtypes.TODO.ALLDELIVER ||
    typeNotification === notificationsSubtypes.TODO.RESOURCE_ASSIGNED ||
    typeNotification === notificationsSubtypes.TODO.RESOURCE_EVALUATED
  );
};

export const isNotificationTypeSocial = (typeNotification) => {
  return (
    typeNotification === notificationsSubtypes.SOCIAL.COMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.POST ||
    typeNotification === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
    typeNotification === notificationsSubtypes.SOCIAL.JOBCOMMENT
  );
};

export function getRouteToClickInNotification(notification, user) {
  let pathname = ``;
  let state = {};
  state.courseGuid = notification.data.course.guid;
  if (isNotificationTypeSocial(notification.type)) {
    if (notification.type === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT) {
      pathname = `/course/${notification.data.course.guid}/lesson/${notification.data.message.parentMessageData.lesson_guid}`;
    } else if (notification.type === notificationsSubtypes.SOCIAL.JOBCOMMENT) {
      if (isStudent(user)) {
        pathname = `/course/${notification.data.course.guid}/unit/${notification.data.section.guid}`;
      } else {
        pathname = `/course/${notification.data.course.guid}/lesson/${notification.data.lesson.guid}/results-assessment/${notification.data.lesson_item.guid}`;
      }
    } else {
      if (notification.data.message.parent_message?.lesson_item_content_guid) {
      } else {
        pathname = `/course/${notification.data.course.guid}`;
      }
    }

    if (notification.type === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT) {
      state.postGuid = notification.data.message.parentMessageData.lesson_item_guid;
    } else if (notification.type === notificationsSubtypes.SOCIAL.COMMENT) {
      if (notification.data.message.parent_message?.lesson_item_content_guid) {
        state.postGuid = notification.data.message.parentMessageData.lesson_item_guid;
      } else {
        state.postGuid = notification.data.message.parent_guid;
      }
      state.comment = '1';
    } else if (notification.type === notificationsSubtypes.SOCIAL.JOBCOMMENT) {
      state.courseGuid = notification.data.course.guid;
      state.unitGuid = notification.data.section.guid;
      state.lessonGuid = notification.data.lesson.guid;
      state.contentGuid = notification.data.content.guid;
      state.userGuid = notification.data.student.guid;
    } else {
      state.postGuid = notification.data.message.guid;
    }
  } else if (isNotificationTypeToDo(notification.type)) {
    if (notification.type === notificationsSubtypes.TODO.EVALUATED) {
      state.courseGuid = notification.data.course.guid;
      console.log('course guid añadido', state);
      pathname = `/results-assesment-individual/course/${notification.data.course.guid}/${notification.data.lesson_item.guid}/user/${notification.person_guid}`;
    } else if (
      notification.type === notificationsSubtypes.TODO.DELIVERATCOMPLETE ||
      notification.type === notificationsSubtypes.TODO.ALLDELIVER ||
      notification.type === notificationsSubtypes.TODO.PUBLISHPENDING
    ) {
      pathname = `/course/${notification.data.course.guid}/lesson/${notification.data.lesson.guid}/results-assessment/${notification.data.lesson_item.guid}`;
      state.courseGuid = notification.data.course.guid;
      console.log('course guid añadido', state);
    } else if (notification.type === notificationsSubtypes.TODO.STUDENTDELIVER) {
      pathname = `/results-assesment-individual/course/${notification.data.course.guid}/${notification.data.lesson_item.guid}/user/${notification.user.guid}`;
    } else if (notification.type === notificationsSubtypes.TODO.RESOURCE_ASSIGNED) {
      let data = JSON.parse(notification.data);
      let resourceType = data?.section?.type;
      if (resourceType === 'test') {
        pathname = `/course/${
          notification.data.course.guid
        }/resources/view-evaluation/${data.message.resource_material_guid.toString()}/${data.section.guid.toString()}/user/${user.guid}`;
      }
    } else {
      pathname = `/course/${notification.data.course.guid}/lesson/${notification.data.lesson.guid}/assessment/${notification.data.lesson_item.guid}`;
    }
  }
  return { pathname, state };
}

export function getTextNotificationsUnread(notificationsUnread) {
  let text = notificationsUnread;
  if (notificationsUnread >= 10) {
    text = '9+';
  }
  if (notificationsUnread >= 100) {
    text = '99+';
  }
  return text;
}
