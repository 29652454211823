import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as coreEntities from '_core/store/index';

export function useCourses() {
  const dispatch = useDispatch();

  const courses = useSelector((state) => coreEntities.courses.selectors.getCourses(state));

  useEffect(() => {
    if (!courses) {
      getCourses();
    }
  }, [courses]);

  async function getCourses(params = {}) {
    dispatch(coreEntities.courses.actions.getCourses({ params: { offset: 0, pageSize: 1000, ...params } }));
  }
  return { courses };
}
