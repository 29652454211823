import axios from 'axios';

export const CALENDARS = 'calendars';
export const CALENDAR = 'calendar';
export const CALENDAR_THEMES = 'calendar-themes';
export const CALENDAR_EVENTS = 'calendar/calendarGuid/events';
export const CALENDAR_HOLIDAYS = 'calendar/holiday';
export const CALENDAR_GOOGLE_SYNC = 'gcalendar-sync';

export function getCalendars(params) {
  return axios.get(CALENDARS, { params: params });
}

export function getCalendarEvents(params) {
  return axios.get(CALENDAR_EVENTS, { params: params });
}

export function getCalendarThemes(params) {
  return axios.get(CALENDAR_THEMES, { params: params });
}

export function getCalendarHolidays(params) {
  return axios.get(CALENDAR_HOLIDAYS, { params: params });
}

export function setCalendarHoliday(params) {
  return axios.post(CALENDAR_HOLIDAYS, params);
}

export function deleteCalendarHoliday(params) {
  return axios.delete(CALENDAR_HOLIDAYS, { data: { guid: [params] } });
}

export function putCalendarHoliday(params) {
  return axios.put(CALENDAR_HOLIDAYS + '/' + params.guid, params);
}

export function postExtraClasses(calendarGuid, params) {
  return axios.post(CALENDAR + '/' + calendarGuid + '/extra-classes', params);
}

export function getAllCalendarEvents(params) {
  return axios.get('calendar/' + params.calendarGuid + '/events?fromDate=' + params.fromDate + '&toDate=' + params.toDate + '&calendar[]=' + params.calendar);
}

export function syncCalendarWithGoogle(params) {
  return axios.post(CALENDAR_GOOGLE_SYNC, params);
}

export function editConferenceLink(params) {
  const { calendarGuid, eventGuid } = params.calendarData;
  /* 
  Para crear el Google Meet:

  {
    "has_conference": true,
    "timezone": "{deviceTimezone}", 
    "token": "{accessToken}"
  }

  El parámetro timezone tiene que tener formato “+00:00”, detectando la hora del dispositivo local se debe informar la diferencia en horas para llegar a UTC +00:00.
  Ejemplos:
  Si estoy en España, +1, debería enviar como timezone “-01:00”
  Si estoy en Argentina, -3, debería enviar “+03:00”

  ------------------------------------------
  Para desactivar la conferencia del evento:
  {
    "has_conference": false,
    "conference_link": ""
  }
  */
  return axios.put(`${CALENDAR}/${calendarGuid}/events/${eventGuid}`, params.body);
}
