/**
 * Siendo una url del tipo: "users/0046f281-bab4-11e9-8f5e-251ea8290df3" extrae la id, que es la ultima parte de la url
 * @param {*} url "users/0046f281-bab4-11e9-8f5e-251ea8290df3"
 * @returns Devuelve la id o null si no la puede obtener
 */
export const getIdFromUrl = (url) => {
  if (!url) return null;
  var id = url.substring(url.lastIndexOf('/') + 1);
  return id;
};

/**
 * Dada una url nos devuelve true es una url absoluta en otro caso devuelve false
 * @param {string} url  Ejemplo 1 para esta url: 'http://example.com' nos devuelve true.
 *                      Ejemplo 2 para esta url: '/myfolder/test.txt' nos devuelve false
 */
export function isAbsoluteUrl(url) {
  var r = new RegExp('^(?:[a-z]+:)?//', 'i');
  return r.test(url);
}

export const getDomain = (url, dots = 1) => {
  url = url.replace(/(https?:\/\/)?(www.)?/i, '');
  if (url.indexOf('/') !== -1) {
    url = url.split('/')[0];
  }
  if (url.indexOf(':3000') !== -1) {
    url = url.split(':3000')[0];
  }
  url = url.split('.');
  let subdomain = url.length > 3 || (url[2] && url[1].length > 3) || false;
  if (subdomain) {
    url = url.slice(url.length - (url.length - 1)).join('.');
  } else {
    url = url.join('.');
  }
  return url;
};
