import { getChatGroups } from '_core/utils/chat/chat';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { createDemoGroup, createGroup, deleteGroup, deleteUserFromGroup, getMyGroups, updateGroup } from '../crud/groups.crud';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_GROUPS, REDUCER_GROUPS } from './conf';
import { actions as actionCourses } from './courses.duck';
import { selectors as organizationSelector } from './organization.duck';

export const actionTypes = {
  GetGroups: 'cms/GET_GROUPS',
  SetGroups: 'cms/SET_GROUPS',
  CreateGroup: 'cms/CREATE_GROUP',
  AddOneGroup: 'cms/ADD_ONE_GROUP',
  DeleteGroup: 'cms/DELETE_GROUP',
  RemoveOneGroup: 'cms/REMOVE_ONE_GROUP',
  UpdateGroup: 'cms/UPDATE_GROUP',
  ReplaceGroup: 'cms/REPLACE_GROUP',
  DeleteUserFromGroup: 'cms/DELETE_USER_FROM_GROUP',
  RemoveUserFromGroup: 'cms/REMOVE_USER_FROM_GROUP',
  GetDemoGroup: 'cms/GET_DEMO_GROUP',
  SuccessCreateDemoGroup: 'cms/SUCCESS_CREATE_DEMO_GROUP',
  FailCreateDemoGroup: 'cms/FAIL_CREATE_DEMO_GROUP',
};

const initialState = {
  groups: null,
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_GROUPS, whitelist: ['groups'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetGroups:
      return { ...state /*, loading: true*/ };

    case actionTypes.SetGroups: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { groups: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    case actionTypes.AddOneGroup: {
      return { ...state, groups: { ...state.groups, length: state.groups.length + 1, schoolGroups: [...state.groups.schoolGroups, action.payload.params] } };
    }

    case actionTypes.RemoveOneGroup: {
      const groups = state.groups.schoolGroups.filter((group) => group.guid !== action.payload);
      return { ...state, groups: { ...state.groups, length: groups.length, schoolGroups: groups } };
    }

    case actionTypes.UpdateGroup: {
      return { ...state /*, loading: true*/ };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    case actionTypes.ReplaceGroup: {
      const modGroup = action.payload.params;
      return {
        ...state,
        loading: false,
        groups: { ...state.groups, schoolGroups: state.groups.schoolGroups.map((group) => (group.guid === modGroup.guid ? modGroup : group)) },
      };
    }

    case actionTypes.RemoveUserFromGroup: {
      const { groupGuid } = action.payload;
      const userGuid = action.payload.userGuid[0];
      let schoolGroups = state.groups.schoolGroups.map((group) => {
        return group.guid === groupGuid
          ? {
              ...group,
              users: group.users.filter((user) => user.guid !== userGuid),
            }
          : group;
      });

      return {
        ...state,
        groups: { ...state.groups, schoolGroups: schoolGroups },
      };
    }

    case actionTypes.GetDemoGroup: {
      return { ...state, groups: null, loading: true };
    }

    case actionTypes.FailCreateDemoGroup: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getGroups: (state) => {
    return state.entities && state.entities[REDUCER_GROUPS] ? state.entities[REDUCER_GROUPS].groups : null;
  },
  getGroup: (state, guid) => {
    return state.entities && state.entities[REDUCER_GROUPS] ? state.entities[REDUCER_GROUPS].groups.schoolGroups.find((item) => item.guid === guid) : null;
  },
  getGroupsLoading: (state) => {
    return state.entities && state.entities[REDUCER_GROUPS] ? state.entities[REDUCER_GROUPS].loading : null;
  },
};

export const actions = {
  getGroups: (params) => ({ type: actionTypes.GetGroups, payload: params }),
  SetGroups: (response) => ({ type: actionTypes.SetGroups, payload: { response } }),
  createGroup: (params) => ({ type: actionTypes.CreateGroup, payload: { params } }),
  addOneGroup: (params) => ({ type: actionTypes.AddOneGroup, payload: { params } }),
  deleteGroup: (params) => ({ type: actionTypes.DeleteGroup, payload: { params } }),
  removeOneGroup: (params) => ({ type: actionTypes.RemoveOneGroup, payload: params }),
  updateGroup: (params) => ({ type: actionTypes.UpdateGroup, payload: { params } }),
  replaceGroup: (params) => ({ type: actionTypes.ReplaceGroup, payload: { params } }),
  deleteUserFromGroup: (params) => ({ type: actionTypes.DeleteUserFromGroup, payload: params }),
  removeUserFromGroup: (params) => ({ type: actionTypes.RemoveUserFromGroup, payload: params }),
  getDemoGroup: (params) => ({ type: actionTypes.GetDemoGroup, payload: params }),
  failCreateDemoGroup: () => ({ type: actionTypes.FailCreateDemoGroup }),
  successCreateDemoGroup: () => ({ type: actionTypes.SuccessCreateDemoGroup }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetGroups, function* getGroupsSaga(action) {
    const client = yield select(organizationSelector.getClient);
    const { data } = yield getMyGroups(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      // if (data.data.schoolGroups) {
      //   let guids = data.data.schoolGroups.map((group) => group.guid);
      //   updateUserTags(guids);
      // }
      if (data?.data?.schoolGroups?.length > 0) {
        getChatGroups(data.data.schoolGroups);
      }
      yield put(actions.SetGroups(data));
      // if (data.data.length === 0 && NEED_DEMO_GROUP && client === CLIENT.SANTILLANA_ALFA) {
      //   yield put(actions.getDemoGroup(DEMO_GROUP_PAYLOAD));
      // }
      // Check for school groups with no courses if the get groups does not return results
      // if (data.data.length === 0 && !NEED_DEMO_GROUP) {
      //   const { data: schoolGroup } = yield getGroups(action.payload.params);
      //   if (schoolGroup && schoolGroup.status === 'success' && schoolGroup.data) {
      //     yield put(actions.SetGroups(schoolGroup));
      //   }
      // }
    }
  });

  yield takeLatest(actionTypes.GetDemoGroup, function* getDemoGroupSaga(action) {
    const { data } = yield createDemoGroup(action.payload);
    if (data && data.status === 'success') {
      yield put(actions.getGroups({ offset: 0, pageSize: 1000, schoolGuid: data.data.school.guid, orderBy: 'created_at desc', members: 'teachers' }));
      yield put(actionCourses.getCourses({ params: { offset: 0, pageSize: 1000 } }));
    } else {
      yield put(actions.failCreateDemoGroup());
    }
  });

  yield takeLatest(actionTypes.CreateGroup, function* createGroupSaga(action) {
    const { data } = yield createGroup(action.payload.params);
    if (data && data.status === 'success') {
      yield put(actions.addOneGroup(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteGroup, function* deleteGroupSaga(action) {
    const { data } = yield deleteGroup(action.payload.params);
    if (data && data.status === 'success') {
      const guid = data.data.guid[0];
      yield put(actions.removeOneGroup(guid));
    }
  });

  yield takeLatest(actionTypes.UpdateGroup, function* updateGroupSaga(action) {
    const { data } = yield updateGroup(action.payload.params);
    if (data && data.status === 'success') {
      yield put(actions.replaceGroup(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteUserFromGroup, function* deleteUsersFromGroupSaga(action) {
    const { data } = yield deleteUserFromGroup(action.payload.params);
    if (data && data.status === 'success') {
      yield put(actions.removeUserFromGroup({ userGuid: data.data.guid, groupGuid: action.payload.params.groupGuid }));
    }
  });
}
