import { cleanEventName } from '_core/lite/parsers';
import { COURSE_TYPES } from '_core/utils/constants/course';
import { notificationsSubtypes } from '_core/utils/notifications';
import { getGradientByDisciplineCourse } from '_core/utils/utils';

export function parseEvents(events = []) {
  return events.map((item) => {
    return {
      ...item,
      name: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      title: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      start: item.start_at,
      type: item.type_name,
      course: {
        gradient: item.education_discipline_guid ? getGradientByDisciplineCourse(item.education_discipline_guid) : null,
        name: item.school_group_name,
      },
      lesson: {
        name: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      },
    };
  });
}

export function parseUsers(users = []) {
  return users.map((item) => parseUser(item));
}

export function parseUser(user = {}) {
  user.lastName = user.lastname;
  user.thumbnail = user.avatar;
  user.guid = user.person_guid || user.guid;
  return user;
}

export function parsePosts(posts = []) {
  return posts.map((item) => {
    return {
      ...item,
      pin: item.is_pinned,
      text: item.message,
      createdAt: item.created_at,
      commentsLength: item.amount_comments,
      user: { name: item.creator_name, lastname: item.creator_lasname, guid: item.creator_guid },
      comments:
        item.comments && item.comments.length > 0
          ? item.comments.map((commItem) => ({
              ...commItem,
              text: commItem.message,
              user: { name: commItem.creator_name, lastName: commItem.creator_lasname, avatar: commItem.creator_avatar },
            }))
          : [],
    };
  });
}

export function parseCourses(courses = []) {
  return courses.map((item) => {
    let configuration = null;
    try {
      configuration = item.config ? JSON.parse(item.config) : null;
    } catch (e) {
      console.log(e, 'error parseCourses');
    }

    return {
      ...item,
      level: item.education_level_name,
      studentsLength: item.users.filter((user) => user.role_guid === 'R01').length,
      letter: item.name.substr(0, 1).toUpperCase(),
      color: item.theme_color,
      configuration: configuration,
      hasTimetable: item.has_timetable,
      isPersonalizedCourse: item.course_template_type === COURSE_TYPES.TEMPLATE_2,
    };
  });
}

export function parseNotifications(notifications = []) {
  let notificationsParse = [];
  let currentNotification = null;

  for (let notification of notifications) {
    currentNotification = parseOneNotification(notification);
    if (currentNotification) {
      notificationsParse.push(currentNotification);
    }
  }

  return notificationsParse;
}

function getTextNotification(data, notification) {
  let text = data.message && data.message.parent_message ? data.message.parent_message.message : '';
  if (notification.type === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT) {
    text = data.message.parentMessageData.lesson_item_name;
  }
  if (notification.type === notificationsSubtypes.SOCIAL.JOBCOMMENT) {
    text = data.comment.message;
  }

  if (notification.type === notificationsSubtypes.TODO.RESOURCE_ASSIGNED) {
    text = data.content.name;
  }

  return text;
}

function getSubtextNotification(data, notification) {
  let subtext = data.message ? data.message.message : null;
  if (notification.type === notificationsSubtypes.SOCIAL.JOBCOMMENT) {
    subtext = data.comment.message;
  }
  if (notification.type === notificationsSubtypes.TODO.RESOURCE_ASSIGNED) {
    subtext = data.content.name;
  }
  return subtext;
}

export function parseOneNotification(item) {
  try {
    let data = JSON.parse(item.data);
    return {
      ...item,
      user: parseUser(data.user || data.student),
      data,
      viewAt: item.viewed_at,
      date: item.created_at,
      subtext: getSubtextNotification(data, item),
      text: getTextNotification(data, item),
      className: data.school_group ? data.school_group.name : '',
      courseName: data.course?.name,
      consumedAt: item.consumed_at,
      unitName: data.section ? data.section.name : null,
      assessmentName: data.assesment?.name ? data.assesment.name : null,
      resourceName: data?.section?.type === 'test' ? data.content.name : null,
      isEvaluable: data.assesment && data.assesment.is_evaluable ? data.assesment.is_evaluable : 0,
      dueDate: data.deliver_at,
      course: {
        gradient: getGradientByDisciplineCourse(data.course?.discipline_guid),
      },
    };
  } catch (e) {
    console.log(e, 'error parseOneNotification');
    return null;
  }
}
