import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Icon from './../Icon';

export const utilsSelectCustomizable = {
  /**
   * Devuelve las opciones que son necesarias para el componente SelectCustomizable,
   * dichas opciones las compone recorriendo el array elements
   * y accediendo a la propiedad propertyToLabel para el valor label,
   * y a la propieddad propertyToValue para el valor value de las opciones
   * @param {array} elements
   * @param {string} propertyToLabel
   * @param {string} propertyToValue
   */
  getOptionsProperties(elements, propertyToLabel, propertyToValue) {
    return elements && propertyToLabel && propertyToValue
      ? elements.map((element) => {
          return {
            label: element[propertyToLabel],
            value: element[propertyToValue],
          };
        })
      : [];
  },
};

const SelectCustomizable = ({
  t,
  isMulti,
  options,
  defaultValue,
  value,
  placeholder = t('Select'),
  noOptionsMessage,
  variant,
  onChange,
  label,
  name,
  icon,
  size,
  error,
  canCreate,
  onCreateOption,
  menuPosition,
  iconTypeIndicator,
  isClearable,
  ...props
}) => {
  const IndicatorsContainer = ({ innerProps }) => {
    return (
      <div className="select-customizable__indicator " {...innerProps}>
        <Icon type={iconTypeIndicator ? iconTypeIndicator : 'chevron-down'} size="s" />
      </div>
    );
  };

  const formatGroupLabel = (data) => (
    <div className="select-customizable__group-label">
      <span>{data.label}</span>
      <span className="select-customizable__group-badge">{data.options.length}</span>
    </div>
  );

  return (
    <>
      {label && (
        <label className="select-customizable__label" title={label}>
          {label} {props.required && <sup>*</sup>}
        </label>
      )}
      {canCreate ? (
        <CreatableSelect
          menuPosition={'fixed'}
          menuPlacement={'auto'}
          options={options}
          className={`select-customizable select-customizable--${variant} select-customizable--size-${size}`}
          classNamePrefix="select-customizable"
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          isMulti={isMulti}
          onChange={onChange}
          onCreateOption={onCreateOption}
          createOptionPosition="last"
          isClearable={true}
          formatCreateLabel={(text) => {
            return `${t('placeholder:Create new section')}: ${text}`;
          }}
          name={name}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      ) : (
        <Select
          menuPosition={menuPosition}
          menuPlacement={'auto'}
          options={options}
          className={`select-customizable select-customizable--${variant} select-customizable--size-${size}`}
          classNamePrefix="select-customizable"
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          components={{ IndicatorsContainer }}
          noOptionsMessage={() => noOptionsMessage}
          isMulti={isMulti}
          onChange={onChange}
          name={name}
          formatGroupLabel={formatGroupLabel}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          isDisabled={props.isDisabled}
          isClearable={isClearable}
          {...props}
        />
      )}
      {error && <span className="select-customizable__error">{error}</span>}
    </>
  );
};

SelectCustomizable.propTypes = {
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['default']),
  size: PropTypes.oneOf(['default', 'small']),
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SelectCustomizable.defaultProps = {
  isMulti: false,
  options: [],
  noOptionsMessage: 'No hay opciones...',
  variant: 'default',
  size: 'default',
  menuPosition: 'fixed',
  onChange: () => {
    return null;
  },
};
export default withTranslation(['placeholder'])(SelectCustomizable);
