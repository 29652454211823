import { CometChatIncomingCall } from '@cometchat/chat-uikit-react';
import React from 'react';

export function IncomingCall() {
  return (
    <div className="incoming-call">
      <CometChatIncomingCall />
    </div>
  );
}
