import { CometChat } from '@cometchat/chat-sdk-javascript';
import { CometChatLocalize, CometChatUIKit } from '@cometchat/chat-uikit-react';
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
import { COMETCHAT_CONSTANTS } from '_core/variables/constant';

const UID = 'superhero1'; //Replace with your UID
const authKey = '2628a427e7317ed2a1e18f809fe458bb1ad2b741'; //Replace with your Auth Key

//create the builder
const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  .subscribePresenceForFriends()
  .build();

export function initChat(user) {
  CometChatUIKit.init(UIKitSettings)
    .then(() => {
      logUser(user);
    })
    .catch((error) => {
      console.error('Error initiazing CometChat: ', error);
    });
  CometChatLocalize.init('es');
}

export function logoutChat() {
  CometChatUIKit.logout();
}

function logUser(usr) {
  CometChatUIKit.getLoggedinUser().then((user) => {
    if (!user) {
      //Login user
      CometChatUIKit.login(usr.guid, authKey)
        .then((user) => {
          console.log('Login Successful:', { user });
          //mount your app
        })
        .catch((error) => {
          console.error('Login failed with exception:', error.code);
          if (error.code === 'ERR_UID_NOT_FOUND') {
            createUser(usr);
          }
        });
    } else {
      //mount your app
    }
  });
}

function createUser(usr) {
  console.log('Tangerine user: ', usr);
  let user = new CometChat.User(usr.guid);
  user.setName(usr.name + ' ' + usr.lastname);
  if (usr.avatar) user.setAvatar(usr.avatar);
  user.setRole(usr.role_guid === 'R02' ? 'TEACHER' : 'STUDENT');
  CometChatUIKit.createUser(user, authKey)
    .then((user) => {
      console.log('user created', user);

      CometChatUIKit.login(usr.guid, authKey)
        .then((user) => {
          console.log('Login Successful:', { user });
          //mount your app
        })
        .catch((error) => {
          console.error('Cometchat login failed with exception:', error.code);
        });
    })
    .catch((error) => {
      console.log('Cometchat error creating user: ', error);
    });
}

export function updateUserTags(tags) {
  CometChat.getLoggedinUser().then(
    (user) => {
      console.log('chat user: ', user);
      if (user) {
        user.setTags(tags);
        CometChat.updateCurrentUserDetails(user).then(
          (user) => {},
          (error) => {
            console.log('error', error);
          }
        );
      }
    },
    (error) => {
      console.error('error updating user: ', error);
    }
  );
}

export async function getUser() {
  CometChat.getLoggedinUser().then(
    (user) => {
      return user;
    },
    (error) => {
      return error;
    }
  );
}

export async function getChatGroups(groups) {
  try {
    let groupsRequest = new CometChat.GroupsRequestBuilder().setLimit(50).build();
    const chatGroups = await groupsRequest.fetchNext();
    groups.forEach((group) => {
      let chatGroup = chatGroups.find((chatGroup) => chatGroup.guid === group.guid);
      if (!chatGroup) {
        console.log('The chat group doesnt exist: ', group);
        createChatGroup(
          group.guid,
          group.name,
          group.users.filter((user) => user.role_guid === 'R01')
        );
      } else {
        addMembersToGroup(
          group.guid,
          group.users.filter((user) => user.role_guid === 'R01')
        );
      }
    });
  } catch (error) {
    console.error('Exception getting chat groups: ', error);
  }
}

export async function createChatGroup(guid, name, users) {
  try {
    console.log('create chat: ', guid, name);
    let group = new CometChat.Group(guid, name, CometChat.GROUP_TYPE.PRIVATE);
    let members = [];
    users.forEach((user) => {
      members.push(new CometChat.GroupMember(user.guid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT));
    });

    CometChat.createGroupWithMembers(group, members).then(
      (response) => {
        console.log('Group created successfully', response);
      },
      (error) => {
        console.log('Some error occured while creating group', error);
      }
    );
  } catch (error) {
    console.error('Exception creating chat group: ', error);
  }
}

async function addMembersToGroup(groupGuid, users) {
  try {
    let members = [];
    users.forEach((user) => {
      members.push(new CometChat.GroupMember(user.guid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT));
    });

    CometChat.addMembersToGroup(groupGuid, members, []).then(
      (response) => {
        console.log('Members added successfully:', response);
      },
      (error) => {
        console.log('Group joining failed with exception:', error);
      }
    );
  } catch (error) {
    console.error('Exception adding members to group: ', error);
  }
}

export async function joinGroup(groupGuid) {
  try {
    CometChat.joinGroup(groupGuid, CometChat.GROUP_TYPE.PRIVATE).then(
      (response) => {
        console.log('Group joined successfully:', response);
      },
      (error) => {
        console.log('Group joining failed with exception:', error);
      }
    );
  } catch (error) {
    console.error('Exception joining group: ', error);
  }
}
