import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { parseLanguages } from '_core/lite/parsers';
import { compareField } from '_core/utils/utils';
import { getLangs, getLangScopes } from '../crud/langs.crud';
import { PERSIST_LANGS, REDUCER_LANGS } from './conf';

export const actionTypes = {
  GetLangs: 'cms/GET_LANGS',
  SetLangs: 'cms/SET_LANGS',
  GetLangScopes: 'cms/GET_LANG_SCOPES',
  SetLangScopes: 'cms/SET_LANG_SCOPES',
};

const initialState = {
  langs: [],
  scopes: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_LANGS, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLangs:
      return { ...state, loading: true };

    case actionTypes.SetLangs: {
      const { response, isFilter } = action.payload;
      let languages = parseLanguages(response.data, isFilter);
      languages.sort((a, b) => {
        return compareField(a, b, 'description');
      });
      if (response && response.status === 'success' && response.data) {
        return { langs: languages, loading: false };
      }
      return { ...state, loading: false };
    }

    case actionTypes.SetLangScopes: {
      const { data } = action.payload;
      return { ...state, scopes: data };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLangs: (state) => {
    return state.entities && state.entities[REDUCER_LANGS] ? state.entities[REDUCER_LANGS].langs : null;
  },
  getLangScopes: (state) => {
    return state.entities && state.entities[REDUCER_LANGS] ? state.entities[REDUCER_LANGS].scopes : null;
  },
};

export const actions = {
  getLangs: (params) => ({ type: actionTypes.GetLangs, payload: { ...params } }),
  fulfillLangs: (response, isFilter) => ({ type: actionTypes.SetLangs, payload: { response, isFilter } }),
  getLangScopes: (params) => ({ type: actionTypes.GetLangScopes, payload: params }),
  fulfillLangScopes: (response) => ({ type: actionTypes.SetLangScopes, payload: response }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetLangs, function* getLangsSaga(action) {
    const { data } = yield getLangs();
    yield put(actions.fulfillLangs(data, action.payload.isFilter));
  });

  yield takeLatest(actionTypes.GetLangScopes, function* getLangScopesSaga() {
    const { data } = yield getLangScopes();
    yield put(actions.fulfillLangScopes(data));
  });
}
