import React, { useEffect, useState } from 'react';

import Button from '_core/modules/atoms/Button';
import InputCheckbox from '_core/modules/atoms/InputCheckbox';
import AddLicenseForm from '_core/modules/components/forms/AddLicenseForm';
import MosaicList from '_core/modules/components/lists/components/MosaicList';
import LicenseItem from '_core/modules/components/lists/licenses/LicenseItem';

import { useTranslate } from '_core/hooks/useTranslate';
import Icon from '_core/modules/atoms/Icon';
import { useToasts } from 'react-toast-notifications';

const RegisterStep = ({ onCheckLicense, onClearLicenses, onSignupMicrosoft, licenses, onGoBack, shouldConsent, onConsentRequest, isLoading }) => {
  const { addToast } = useToasts();
  const { t } = useTranslate();

  const [step, setStep] = useState(1);
  const [termsCheckBox, setTermsCheckBox] = useState(false);

  const onSignup = () => {
    if (!termsCheckBox) {
      addToast(t('login:Please accept the terms and conditions to register'), {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    onSignupMicrosoft();
  };

  useEffect(() => {
    if (licenses && licenses.length > 0) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [licenses]);

  const onBackClick = () => {
    onClearLicenses();
    if (step === 1) {
      onGoBack();
    } else {
      setStep(1);
    }
  };

  return (
    <div>
      {/* <div className="signup-step-back-button" onClick={onBackClick}>
        <Icon type="arrow-left" size="big" />
      </div> */}
      {step === 1 && (
        <>
          <div className="signup__description">
            <div className="signup-step-back-button" onClick={onBackClick}>
              <Icon type="arrow-left" size="big" />
            </div>{' '}
            {t('teams:Añade tu licencia para continuar')}
          </div>
          <AddLicenseForm onSubmit={onCheckLicense} />
          <div className="signup__license-disclaimer">
            <p>
              {t(
                'teams:Los estudiantes pueden acceder a los códigos de licencias de los libros digitales a través de su centro educativo o en los libros en formato papel'
              )}
            </p>
            <br />
            {/* <p>{t('teams:Si desea obtener información sobre cómo obtener una licencia, comuníquese con nosotros a través de xxx')}</p> */}
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="signup-step-license__licenses-title signup__description">
            <div className="signup-step-back-button" onClick={onBackClick}>
              <Icon type="arrow-left" size="big" />
            </div>
            {t('Licenses')}
          </div>
          <div className="signup-step-license__licenses" style={{ maxHeight: 'inherit' }}>
            <MosaicList
              columns={2}
              items={licenses.map((item) => (
                <LicenseItem {...item} configProgramPicture={true} onClickView={() => {}} onDelete={null} typeView="simple" />
              ))}
            />
          </div>
          <div className="account-form__field">
            <InputCheckbox
              name="accept"
              onChange={() => setTermsCheckBox(!termsCheckBox)}
              checked={termsCheckBox}
              label={
                <span>
                  {t('teams:He leído y acepto los')}
                  <a href="/terms" target="_blank" className="account-form__terms">
                    {' '}
                    {t('teams:Términos y Condiciones')}
                  </a>
                </span>
              }
            />
            {/* {error.terms && <ErrorText text={error['terms']} />} */}
          </div>
          <div style={{ marginTop: 8 }}>
            {!shouldConsent && (
              <Button
                testid="btn-sso-microsoft"
                color="white"
                text={t('teams:Accede con Microsoft')}
                icon="microsoft"
                loading={isLoading}
                iconPosition="left"
                onClick={onSignup}
              />
            )}
            {shouldConsent && (
              <Button text={t('teams:Haz clic aquí para aceptar el uso de Macmillan antes de acceder')} color="white" onClick={onConsentRequest} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterStep;
