import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getTypes } from "../crud/types.crud";
import {actionTypes as actionAuth} from "./auth.duck";
import { PERSIST_TYPES, REDUCER_TYPES } from "./conf";

const typesMedia = ['CTTY_03', 'CTTY_04', 'CTTY_05', 'CTTY_06', 'CTTY_07'];
const typeProject = "CTTY_17";

export const actionTypes = {
  GetTypes: "cms/GET_TYPES",
  SetTypes: "cms/SET_TYPES"  
};

const initialState = {
  data: [],
  loading:false  
};

export const reducer = persistReducer(
    { storage, key: PERSIST_TYPES, whitelist: ["data"] },
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.GetTypes: 
          return {...state, loading: true}          
        
        case actionTypes.SetTypes: {
          const { response } = action.payload;
          if (response && response.status === "success")
            return { data: response.data, loading:false };
          return {...state, loading: false};
        }

        case actionAuth.Logout: { //si logout, borrar estado redux
          const { reducersNoEmpty } = action.payload;
          if(reducersNoEmpty && reducersNoEmpty.indexOf(REDUCER_TYPES) > -1){
            return state; // == No limpio types
          }else return initialState; // == Limpio types
        }

        default:
          return state;
      }
    }
);

export const selectors = {
  getTypes: (state) => {
      return state.entities[REDUCER_TYPES].data
  },
  //devuelve solo los tipos de media y en formato {text, value} para un componente tipo select
  getTypesMediaOptions: (state) => {
    return state.entities[REDUCER_TYPES] ? 
        state.entities[REDUCER_TYPES].data
            .filter( type => (typesMedia.indexOf(type.guid) > -1))    
            .map( type => ({ text: type.name, value: type.guid}))                         
        : []
  }
}

export const selectorsUtils = {
  getType: (types, typeGuid) => {
    return types ? types.find((type)=> type.guid == typeGuid) : null;
  },
  getTypeProject: (types) => {
    return selectorsUtils.getType(types, typeProject);
  },
  getCustomTags: (types, typeGuid) => {
    const type = selectorsUtils.getType(types, typeGuid);
    var customTags = null;
    if(type && type.hasOwnProperty("custom_tags")) customTags = type.custom_tags;
    return customTags;
  },
  getCustomTagsProject: (types) => {
    return selectorsUtils.getCustomTags(types, typeProject);
  }
}

export const actions = {
  getTypes: (onSucessGetTypes = null, onSucessSetTypes = null) => ({type:actionTypes.GetTypes, payload:{ onSucessGetTypes: onSucessGetTypes , onSucessSetTypes: onSucessSetTypes }}),
  fulfillTypes: (response, onSucessSetTypes = null) => ({ type: actionTypes.SetTypes, payload:{ response: response, onSucessSetTypes: onSucessSetTypes} }),  
};

export function* saga() {  

    yield takeLatest(actionAuth.Login, function* getTypesSaga() {
        yield put(actions.getTypes());
    });

    yield takeLatest(actionTypes.GetTypes, function* getTypesSaga(action) {
        const { onSucessGetTypes, onSucessSetTypes } = action.payload;
        const { data } = yield getTypes();
        if (data && data.status === "success") onSucessGetTypes && onSucessGetTypes(data.data);        
        yield put(actions.fulfillTypes(data, onSucessSetTypes));
    });

    yield takeLatest(actionTypes.SetTypes, function* setTypesSaga(action) {
      const { response, onSucessSetTypes } = action.payload;
      if (response && response.status === "success") onSucessSetTypes && onSucessSetTypes(response.data);        
  });

}
