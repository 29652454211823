import io from 'socket.io-client';
import { defaultConfig } from './../../app/config/environment';
import { ACTION_BRODCAST, ACTION_NOTIFICATION, CONNECT, DISCONNECT } from './protocol';

const URL_WEBSOCKET = /*isModeProduction() ? getDomain(window.location.href) :*/ defaultConfig.BASE_DEV;
const PATH_WEBSOCKET = '/api/socket.io/';

const SOCKETS = {};

export default class Socket {
  constructor(authToken, onAction, onBroadcast, name) {
    this.token = authToken;
    this.onAction = onAction;
    this.onBroadcast = onBroadcast;
    this.name = name;
  }

  connect = () => {
    // Connect

    if (SOCKETS[this.name]) {
      this.socket = SOCKETS[this.name];
    } else {
      this.socket = io(defaultConfig.BASE_URL_, {
        path: PATH_WEBSOCKET,
        query: {
          token: this.token,
        },
        transports: ['websocket'],
      });

      // Set listeners
      this.socket.on(CONNECT, function () {});

      this.socket.on(DISCONNECT, function (err, msg) {});
    }

    if (this.onAction) {
      this.socket.on(ACTION_NOTIFICATION, (msgObject) => {
        this.onAction(msgObject);
      });
    }

    if (this.onBroadcast) {
      this.socket.on(ACTION_BRODCAST, (msgObject) => {
        this.onBroadcast(msgObject);
      });
    }
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.close();
    }
  };

  // Send a message over the socket
  sendAction = (action) => {
    if (this.socket) {
      this.socket.emit(ACTION_NOTIFICATION, action);
    }
  };
}
