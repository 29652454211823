import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { parseNavigatorLanguage } from '_core/utils/utils';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_I18N } from './conf';

const actionTypes = {
  SetLanguage: 'i18n/SET_LANGUAGE',
};

const initialState = {
  lang: parseNavigatorLanguage(navigator.language),
};

export const reducer = persistReducer({ storage, key: PERSIST_I18N }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetLanguage:
      return { ...state, lang: action.payload.lang };

    case actionAuth.Logout: {
      //Si logout, borrar estado redux
      return initialState;
    }

    case actionAuth.UserLoaded: {
      //Obtenemos el idioma del usuario
      const lang_id = action.payload.user.data.lang_id;
      if (lang_id) return { ...state, lang: lang_id };
      else return state;
    }

    default:
      return state;
  }
});

export const selectors = {
  getLanguage: (state) => {
    return (state[PERSIST_I18N] && state[PERSIST_I18N].lang) || null;
  },
};

export const actions = {
  setLanguage: (lang) => ({ type: actionTypes.SetLanguage, payload: { lang } }),
};
