import GamifiedToast from '_core/modules/components/gamified/GamifiedToast/GamifiedToast';
import clsx from 'clsx';
import React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import Icon from './../Icon';

const ToastCustom = ({ children, isGamified, ...props }) => {
  function getIcon() {
    if (props && props.appearance === 'success') return 'success';
    if (props && props.appearance === 'error') return 'warning';
    if (props && props.appearance === 'info') return 'info-circle';
  }

  function getColor() {
    if (props && props.appearance === 'success') return 'success';
    if (props && props.appearance === 'error') return 'red';
    if (props && props.appearance === 'info') return 'white';
  }

  return !isGamified ? (
    <DefaultToast {...props}>
      <div
        className={clsx('toast', {
          'toast--success': props && props.appearance === 'success',
          'toast--error': props && props.appearance === 'error',
          'toast--info': props && props.appearance === 'info',
        })}
      >
        <Icon color={getColor()} type={getIcon()} /> {children}
      </div>
    </DefaultToast>
  ) : (
    <GamifiedToast children={children} {...props} />
  );
};

export default ToastCustom;
