import * as coreEntities from '_core/store/index';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const InitsContainer = () => {
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));

  useEffect(() => {
    console.log('language: ', language);
    if (language === 'pt') require('moment/locale/pt.js');
    else require('moment/locale/es.js');
  }, [language]);

  // useEffect(() => {
  //   window.StonlyWidget('enableDebug');
  //   if (currentUser?.guid && client) {
  //     const data = {
  //       lang: currentUser.lang_id || DEFAULT_LANG,
  //       role: getRoleUserString(currentUser),
  //       client,
  //     };
  //     window.StonlyWidget('identify', currentUser.guid, data);
  //   } else {
  //     window.StonlyWidget('identify', null, { 'user-segment': '' });
  //   }
  // }, [currentUser, client]);

  // useEffect(() => {
  //   Sentry.init({
  //     dsn: 'https://c583e7250991f783148988b55836f209@o4505759437029376.ingest.sentry.io/4506257572626432',
  //     integrations: [
  //       new Sentry.BrowserTracing({
  //         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //         //tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  //       }),
  //       new Sentry.Replay(),
  //     ],
  //     // Performance Monitoring
  //     tracesSampleRate: 1.0, // Capture 100% of the transactions
  //     // Session Replay
  //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   });
  // }, []);

  return <></>;
};

export default InitsContainer;
