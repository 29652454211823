import { CometChat } from '@cometchat/chat-sdk-javascript';
import { useUser } from '_core/lite/hooks/useUser';
import Icon from '_core/modules/atoms/Icon';
import { isTeams } from '_core/utils/teamsTab';
import React, { useEffect, useState } from 'react';
import { ChatWithMessages } from '../ChatWithMessages/ChatWithMessages';

export function ChatButton() {
  const { authToken } = useUser();
  const [isVisible, setIsVisible] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [showBubble, setShowBubble] = useState(false);

  useEffect(() => {
    if (authToken && !isTeams()) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [authToken]);

  function onClose() {
    console.log('close');
    setIsOpened(false);
  }

  function onOpen() {
    setIsOpened(!isOpened);
    setShowBubble(false);
  }

  CometChat.addMessageListener(
    authToken,
    new CometChat.MessageListener({
      onTextMessageReceived: (textMessage) => {
        console.log('Text message received successfully', textMessage);
        setShowBubble(true);
      },
      onMediaMessageReceived: (mediaMessage) => {
        console.log('Media message received successfully', mediaMessage);
        setShowBubble(true);
      },
      onCustomMessageReceived: (customMessage) => {
        console.log('Custom message received successfully', customMessage);
        setShowBubble(true);
      },
      onMessagesDelivered: (messageReceipt) => {
        console.log('Message Delivered', messageReceipt);
      },
      onMessagesRead: (messageReceipt) => {
        console.log('Message Read', messageReceipt);
      },
    })
  );

  return (
    <>
      {isVisible && (
        <div className="chat-button">
          {isOpened && (
            <div className="chat-button__messages-wrapper">
              <div className="chat-button__messages-header">
                <div className="chat-button__messages-close" onClick={onClose}>
                  <Icon type="close" color="black" size="big" />
                </div>
              </div>
              <ChatWithMessages />
            </div>
          )}
          <div className="chat-button__button" onClick={onOpen}>
            <img src={`/images/chat_${isOpened ? 'opened' : 'closed'}.png`} alt="chat" />
            {showBubble && !isOpened && <div className="chat-button__bubble" />}
          </div>
        </div>
      )}
    </>
  );
}
