import PropTypes from 'prop-types';
import React from 'react';

const ImageBubble = ({ size, backgroundColor, imageUrl }) => {
  return (
    <div className={`image-bubble image-bubble--size-${size}`} style={{ backgroundColor: backgroundColor }}>
      <div className="image-bubble__picture" style={{ backgroundImage: `url("${imageUrl}")` }}></div>
    </div>
  );
};

ImageBubble.propTypes = {
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string,
};

ImageBubble.defaultProps = {
  backgroundColor: '',
  imageUrl: '',
};

export default ImageBubble;
