import i18next from 'i18next';
import { types } from 'react-lemonade-editor';

export const LEMONADE_QUESTION_TYPES = {
  LONG_TEXT: 'longtextV2',
  UPLOAD_FILE: 'uploadfile',
};

export const LEMONADE_QUESTION_TYPES_VANILLA = {
  LONG_TEXT: 'essay.RichText',
  UPLOAD_FILE: 'other.UploadFile',
  AUDIO: 'other.AudioRecorder',
  VIDEO: 'other.Video',
};

export function filterTemplatesByType(templates, searchStrings) {
  return templates
    .map((parentTemplate) => {
      const matchingChildren = parentTemplate.children.filter((child) => {
        return searchStrings.some((searchString) => child.name.includes(searchString));
      });

      if (matchingChildren.length > 0) {
        return { ...parentTemplate, children: matchingChildren };
      } else {
        return null;
      }
    })
    .filter((template) => template !== null);
}

export const LEMONADE_TEMPLATES = [
  {
    id: 'multiple',
    name: 'Type test',
    selected: true,
    children: [
      {
        name: 'multipleChoice.Single',
        title: 'Single Choice',
        description: 'Multiple choice single description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-single.png',
        selected: true,
      },
      {
        name: 'multipleChoice.Multiple',
        title: 'Multiple Choice',
        description: 'Multiple choice multiple description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-multiple.png',
        selected: true,
      },
      {
        name: 'multipleChoice.TrueFalse',
        title: 'True/ False',
        description: 'Multiple choice true false description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-true-false.png',
        selected: true,
      },
      {
        name: 'multipleChoice.BlockLetter',
        title: 'Multiple choice Block',
        description: 'Multiple choice block letter description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-block-letter.png',
        selected: true,
      },
      {
        name: 'choiceMatrix.Table',
        title: 'Multiple choice matrix table',
        description: 'Multiple choice matrix table description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-choice-matrix-table.png',
        selected: true,
      },
      {
        name: 'choiceMatrix.Inline',
        title: 'Multiple choice matrix inline',
        description: 'Multiple choice matrix inline description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-choice-matrix-inline.png',
        selected: true,
      },
    ],
  },
  {
    id: 'typing',
    name: i18next.t('lemonade:Complete with text'),
    selected: true,

    children: [
      {
        name: 'essay.ShortText',
        title: 'Texto corto',
        description: 'Cloze text description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-short.png',
        selected: true,
      },

      {
        name: 'cloze.Text',
        title: 'Cloze text',
        description: 'Cloze drag and drop description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-cloze.png',
        selected: true,
      },
      {
        name: 'labelImage.TextV2',
        title: 'Label image text',
        description: 'Cloze math description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-image.png',
        selected: true,
      },
    ],
  },
  {
    id: 'cloze',
    name: i18next.t('lemonade:Drag and select'),
    selected: true,

    children: [
      {
        name: 'cloze.DropDown',
        title: 'Cloze dropdown',
        description: 'Label image text description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-dropdown.png',
        idAux: 'clozedropdown',
        selected: true,
      },
      {
        name: 'cloze.Association',
        title: 'Cloze drag and drop',
        description: 'Label image dropdown description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-draganddrop.png',
        selected: true,
      },
      {
        name: 'labelImage.DropDownV2',
        title: 'Label image dropdown',
        description: 'Label image drag and drop description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-image-dropdown.png',
        selected: true,
      },
      {
        name: 'labelImage.DragDropV2',
        title: 'Label image drag and drop',
        description: 'Label image drag and drop description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-image-draganddrop.png',
        selected: true,
      },
    ],
  },
  {
    id: 'scheme',
    name: 'Order and relate',
    selected: true,

    children: [
      {
        name: 'classify.OrderList',
        title: 'Classify order list',
        description: 'Classify classification description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-order.png',
        selected: true,
      },
      {
        name: 'classify.MatchList',
        title: 'Classify match list',
        description: 'Classify match list description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-match-list.png',
        selected: true,
      },
      {
        name: 'classify.LinkingLines',
        title: 'Classify Linking Lines',
        description: 'Classify order list description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-linking-lines.png',
        selected: true,
      },
      {
        name: 'other.WordSearch',
        title: 'Word search',
        description: 'Upload file description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-wordsearch.png',
        idAux: 'wordsearch',
        selected: true,
      },

      {
        name: 'other.ClickToFind',
        title: 'Click to Find',
        description: 'Click to Find description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-clicktofind.png',
        selected: true,
      },
      {
        name: 'other.Crossword',
        title: 'Crossword',
        description: 'fment description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-crossword.png',
        selected: true,
      },
    ],
  },
  {
    id: 'classify',
    name: 'Classify classification',
    selected: true,

    children: [
      {
        name: 'classify.Classification',
        title: 'Classify classification',
        description: 'Upload file description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/classify-classification.png',
        selected: true,
      },
    ],
  },
  {
    id: 'math',
    name: i18next.t('lemonade:Math'),
    selected: true,

    children: [
      {
        name: 'math.ClozeMath',
        title: 'Cloze math',
        description: 'Upload file description',
        isAutocomplete: true,
        image: '/assets/lemonade-templates/math-cloze.png',
        selected: true,
      },
    ],
  },
  {
    id: 'text',
    name: 'Manual evaluation',
    selected: true,

    children: [
      {
        name: 'other.Statement',
        title: 'Statement',
        description: 'Upload file description',
        isAutocomplete: false,
        image: '/assets/lemonade-templates/text-short.png',
        idAux: 'statement',
        selected: true,
      },
      {
        name: 'essay.RichText',
        title: 'Long text',
        description: 'Upload file description',
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-essay.png',
        selected: true,
      },

      {
        name: 'other.Pencil',
        title: 'Pencil',
        description: 'Upload file description',
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-pencil.png',
        selected: true,
      },
      {
        name: 'other.UploadFile',
        title: 'Upload file',
        description: 'Upload file description',
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-upload-file.png',
        selected: true,
      },
      {
        name: 'other.AudioRecorder',
        title: 'Audio Recorder',
        description: 'Upload file description',
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-audio-recorder.png',
        idAux: 'audiorecorder',
        selected: true,
      },
    ],
  },
];

export const TYPESLEMONADE = [
  {
    id: 'multiple',
    name: i18next.t('lemonade:Type test'),
    children: [
      {
        guid: 'multipleChoice.Single.IA',
        title: i18next.t('lemonade:Single Choice AI'),
        description: i18next.t('lemonade:Multiple choice single by AI description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-single-ia.png',
      },
      {
        guid: 'multipleChoice.Single',
        title: i18next.t('lemonade:Single Choice'),
        description: i18next.t('lemonade:Multiple choice single description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-single.png',
      },
      {
        guid: 'multipleChoice.Multiple',
        title: i18next.t('lemonade:Multiple Choice'),
        description: i18next.t('lemonade:Multiple choice multiple description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-multiple.png',
      },
      {
        guid: 'multipleChoice.TrueFalse',
        title: i18next.t('lemonade:True/ False'),
        description: i18next.t('lemonade:Multiple choice true false description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-true-false.png',
      },
      {
        guid: 'multipleChoice.BlockLetter',
        title: i18next.t('lemonade:Multiple choice Block'),
        description: i18next.t('lemonade:Multiple choice block letter description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-multiple-choice-block-letter.png',
      },
      {
        guid: 'choiceMatrix.Table',
        title: i18next.t('lemonade:Multiple choice matrix table'),
        description: i18next.t('lemonade:Multiple choice matrix table description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-choice-matrix-table.png',
      },
      {
        guid: 'choiceMatrix.Inline',
        title: i18next.t('lemonade:Multiple choice matrix inline'),
        description: i18next.t('lemonade:Multiple choice matrix inline description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/typetest-choice-matrix-inline.png',
      },
    ],
  },
  {
    id: 'typing',
    name: i18next.t('lemonade:Complete with text'),
    children: [
      {
        guid: 'essay.ShortText',
        title: i18next.t('lemonade:Texto corto'),
        description: i18next.t('lemonade:Cloze text description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-short.png',
      },

      {
        guid: 'cloze.Text',
        title: i18next.t('lemonade:Cloze text'),
        description: i18next.t('lemonade:Cloze drag and drop description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-cloze.png',
      },
      {
        guid: 'labelImage.TextV2',
        title: i18next.t('lemonade:Label image text'),
        description: i18next.t('lemonade:Cloze math description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/text-image.png',
      },
    ],
  },
  {
    id: 'cloze',
    name: i18next.t('lemonade:Drag and select'),
    children: [
      {
        guid: 'cloze.DropDown',
        title: i18next.t('lemonade:Cloze dropdown'),
        description: i18next.t('lemonade:Label image text description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-dropdown.png',
        idAux: 'clozedropdown',
      },
      {
        guid: 'cloze.Association',
        title: i18next.t('lemonade:Cloze drag and drop'),
        description: i18next.t('lemonade:Label image dropdown description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-draganddrop.png',
      },
      {
        guid: 'labelImage.DropDownV2',
        title: i18next.t('lemonade:Label image dropdown'),
        description: i18next.t('lemonade:Label image drag and drop description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-image-dropdown.png',
      },
      {
        guid: 'labelImage.DragDropV2',
        title: i18next.t('lemonade:Label image drag and drop'),
        description: i18next.t('lemonade:Label image drag and drop description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/dragselect-image-draganddrop.png',
      },
    ],
  },
  {
    id: 'scheme',
    name: i18next.t('lemonade:Order and relate'),
    children: [
      {
        guid: 'classify.OrderList',
        title: i18next.t('lemonade:Classify order list'),
        description: i18next.t('lemonade:Classify classification description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-order.png',
      },
      {
        guid: 'classify.MatchList',
        title: i18next.t('lemonade:Classify match list'),
        description: i18next.t('lemonade:Classify match list description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-match-list.png',
      },
      {
        guid: 'classify.LinkingLines',
        title: i18next.t('lemonade:Classify Linking Lines'),
        description: i18next.t('lemonade:Classify order list description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/order-linking-lines.png',
      },
      {
        guid: 'other.WordSearch',
        title: i18next.t('lemonade:Word search'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-wordsearch.png',
        idAux: 'wordsearch',
      },

      {
        guid: 'other.ClickToFind',
        title: i18next.t('lemonade:Click to Find'),
        description: i18next.t('lemonade:Click to Find description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-clicktofind.png',
      },
      {
        guid: 'other.Crossword',
        title: i18next.t('lemonade:Crossword'),
        description: i18next.t('lemonade:fment description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/identify-crossword.png',
      },
    ],
  },
  {
    id: 'classify',
    name: i18next.t('lemonade:Classify classification'),
    children: [
      {
        guid: 'classify.Classification',
        title: i18next.t('lemonade:Classify classification'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/classify-classification.png',
      },
    ],
  },
  {
    id: 'math',
    name: i18next.t('lemonade:Math'),
    children: [
      {
        guid: 'math.ClozeMath',
        title: i18next.t('lemonade:Cloze math'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: true,
        image: '/assets/lemonade-templates/math-cloze.png',
      },
    ],
  },
  {
    id: 'text',
    name: i18next.t('lemonade:Manual evaluation'),
    children: [
      {
        guid: 'other.Statement',
        title: i18next.t('lemonade:Statement'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: false,
        image: '/assets/lemonade-templates/text-short.png',
        idAux: 'statement',
      },
      {
        guid: 'essay.RichText',
        title: i18next.t('lemonade:Long text'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-essay.png',
      },

      {
        guid: 'other.Pencil',
        title: i18next.t('lemonade:Pencil'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-pencil.png',
      },
      {
        guid: 'other.UploadFile',
        title: i18next.t('lemonade:Upload file'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-upload-file.png',
      },
      {
        guid: 'other.AudioRecorder',
        title: i18next.t('lemonade:Audio Recorder'),
        description: i18next.t('lemonade:Upload file description'),
        isAutocomplete: false,
        image: '/assets/lemonade-templates/manual-audio-recorder.png',
        idAux: 'audiorecorder',
      },
    ],
  },
];

export function parseTemplateIdForLemonade(template) {
  return types[template.split('.')[0]][template.split('.')[1]];
}

export function findImageByTypeQuestion(type) {
  let find = null;

  for (const typeLemonade of TYPESLEMONADE) {
    find = typeLemonade.children.find((item) => item.guid === type || item.idAux === type);
    if (find) break;
  }

  return find ? find.image : 'null';
}

export function questionsParseLemonade(type) {
  let find = null;

  for (const typeLemonade of TYPESLEMONADE) {
    find = typeLemonade.children.find((item) => item.guid === type || item.idAux === type);
    if (find) break;
  }

  return find ? find.image : 'null';
}

export function getLemonadeQuestionType(data) {
  try {
    data = JSON.parse(data);
    return data.type;
  } catch (ex) {
    return null;
  }
}

export function getEmptyResponseForTypeOfQuestion(type) {
  if (type === LEMONADE_QUESTION_TYPES.UPLOAD_FILE) {
    return [];
  } else return '';
}

export function parseToGetLemonadeObject(activityLemonade) {
  const data = activityLemonade.data || activityLemonade;
  return {
    ...data,
    data: data.data ? data.data : data,
    extravalues: data.data?.extraValues ? data.data.extraValues : data.extraValues,
    metadata: data.data?.metadata ? data.data.metadata : data.metadata,
    title: data.data?.title ? data.data.title : data.title,
    type: data.data?.type ? data.data.type : data.type,
  };
}

export function parseLemonadeData(activityLemonade, questionGuid) {
  return {
    data: activityLemonade?.data?.data || activityLemonade?.data || activityLemonade,
    extravalues: activityLemonade.data?.data?.extraValues || activityLemonade.data?.extraValues || activityLemonade.extraValues,
    metadata: activityLemonade.data?.data?.metadata || activityLemonade.data?.metadata || activityLemonade.metadata,
    title: activityLemonade.data?.data?.title || activityLemonade.data?.title || activityLemonade?.title || activityLemonade?.name,
    type: activityLemonade.data?.data?.type || activityLemonade.data?.type || activityLemonade.type,
    reference:
      activityLemonade.data?.data?.reference ||
      activityLemonade.data?.reference ||
      activityLemonade.reference ||
      activityLemonade.question_guid ||
      questionGuid ||
      `${new Date().getTime() + Math.random()}`,
    guid: activityLemonade.data?.data?.guid || activityLemonade.data?.guid || activityLemonade.guid,
    ...activityLemonade,
  };
}

export function getLemonadeObject({ data, extravalues, metadata, title, type }) {
  return {
    data,
    extravalues,
    metadata,
    title,
    type,
  };
}

export function questionIsAutoevaluative(data) {
  try {
    data = JSON.parse(data);
    return data.data?.isEvaluable || data.data?.data?.isEvaluable ? 1 : 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export function parseDataToObject(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}

export function getLemonadeType(data) {
  try {
    data = JSON.parse(data);
    return data.type;
  } catch (e) {
    console.error('Exception parsing data to get type of question: ', e);
  }
}
