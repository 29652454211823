import React from 'react';

function MedalFirst() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
        fill="#C12A31"
      />
      <path
        d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
        fill="#E6A22A"
      />
      <path
        d="M30.156 15.0693H29.1405V18.493H30.156C30.8748 18.493 31.5401 18.8164 31.9814 19.3802C32.4226 19.944 32.5743 20.6643 32.3975 21.3566L30.7603 27.7656H29.1405V31.1893H32.1001C32.8883 31.1893 33.5758 30.6578 33.7697 29.8987L35.7365 22.1991C36.1768 20.4755 35.7994 18.6819 34.7007 17.2782C33.6019 15.8743 31.9455 15.0693 30.156 15.0693Z"
        fill="#E6A22A"
      />
      <path
        d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
        fill="#DE3E46"
      />
      <path
        d="M24.1278 64.0002C13.0575 64.0002 4 54.8916 4 43.7589C4 32.6262 13.0575 23.5176 24.1278 23.5176C35.198 23.5176 44.2555 32.6262 44.2555 43.7589C44.2555 54.8916 35.198 64.0002 24.1278 64.0002Z"
        fill="#E6A22A"
      />
      <path
        d="M24.1278 60.6941C34.54 60.6941 42.9808 52.2057 42.9808 41.7347C42.9808 31.2638 34.54 22.7754 24.1278 22.7754C13.7156 22.7754 5.27478 31.2638 5.27478 41.7347C5.27478 52.2057 13.7156 60.6941 24.1278 60.6941Z"
        fill="#E8A700"
      />
      <path
        d="M9.27313 43.9511C9.27313 34.5058 16.9009 26.8277 26.2842 26.8277C30.4613 26.8277 34.2751 28.3512 37.2415 30.8496C34.1541 27.1324 29.4927 24.7559 24.2864 24.7559C14.9031 24.7559 7.27539 32.434 7.27539 41.8792C7.27539 47.1198 9.63635 51.812 13.3291 54.9807C10.7866 51.9948 9.27313 48.1558 9.27313 43.9511Z"
        fill="#E5A300"
      />
      <path d="M37.4792 28.3755C44.8594 35.7973 44.8594 47.8071 37.4792 55.229C30.099 62.6508 18.1565 62.6508 10.7764 55.229" fill="black" fillOpacity="0.05" />
      <path
        d="M44.3683 41.6913C44.3683 52.8993 35.3316 61.9852 24.1842 61.9852C13.0368 61.9852 4 52.8993 4 41.6913C4 30.4833 13.0368 21.3975 24.1842 21.3975C35.3316 21.3975 44.3683 30.4833 44.3683 41.6913ZM8.10376 41.6913C8.10376 50.6205 15.3032 57.8591 24.1842 57.8591C33.0651 57.8591 40.2645 50.6205 40.2645 41.6913C40.2645 32.7621 33.0651 25.5235 24.1842 25.5235C15.3032 25.5235 8.10376 32.7621 8.10376 41.6913Z"
        fill="#FFC84B"
      />
      <path
        d="M32.7651 57.0847C32.9404 57.3992 32.8295 57.7982 32.5106 57.963C30.0008 59.2606 27.2201 59.9494 24.3919 59.9697C21.5637 59.9901 18.7734 59.3415 16.2453 58.0803C15.9241 57.92 15.8075 57.5227 15.9784 57.2057C16.1492 56.8888 16.5427 56.7723 16.8644 56.9316C19.1994 58.0884 21.7736 58.6831 24.3826 58.6643C26.9916 58.6455 29.557 58.0138 31.8753 56.8234C32.1946 56.6595 32.5898 56.7703 32.7651 57.0847Z"
        fill="#FFE09B"
      />
      <path
        d="M42.5243 36.6713C42.8256 36.5845 43.0006 36.2683 42.9056 35.968C41.6784 32.0894 39.2827 28.6841 36.0434 26.2275C32.653 23.6563 28.5224 22.2641 24.2756 22.2612C20.0287 22.2584 15.8963 23.645 12.5024 26.2117C9.25987 28.664 6.85968 32.0661 5.62733 35.943C5.53193 36.2432 5.70655 36.5597 6.00769 36.6468C6.30884 36.734 6.62242 36.5588 6.71835 36.2589C7.88219 32.6199 10.1389 29.4269 13.1846 27.1236C16.3816 24.7058 20.2743 23.3996 24.2748 23.4022C28.2753 23.4049 32.1662 24.7163 35.3601 27.1384C38.4027 29.4459 40.6552 32.6419 41.8142 36.2824C41.9097 36.5825 42.2231 36.7581 42.5243 36.6713Z"
        fill="#FFE09B"
      />
      <path
        d="M28 53.49C28 53.83 27.0029 54 25.0088 54C23.0342 54 22.0469 53.83 22.0469 53.49V38.7L18.8798 38.73C18.2933 38.73 18 37.77 18 35.85C18 35.19 18.0587 34.55 18.176 33.93C18.3128 33.31 18.5376 33 18.8504 33H26.8856C27.218 33 27.4819 33.08 27.6774 33.24C27.8925 33.38 28 33.56 28 33.78V53.49Z"
        fill="#C6881A"
      />
      <path
        d="M28 51.49C28 51.83 27.0029 52 25.0088 52C23.0342 52 22.0469 51.83 22.0469 51.49V36.7L18.8798 36.73C18.2933 36.73 18 35.77 18 33.85C18 33.19 18.0587 32.55 18.176 31.93C18.3128 31.31 18.5376 31 18.8504 31H26.8856C27.218 31 27.4819 31.08 27.6774 31.24C27.8925 31.38 28 31.56 28 31.78V51.49Z"
        fill="#FFF0BB"
      />
    </svg>
  );
}

export default MedalFirst;
