import * as coreEntities from '_core/store/index';
import { TEMPLATE_TYPES } from '_core/variables/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useKidsTemplate() {
  const user = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const template = useSelector((state) => coreEntities.ui.selectors.getTemplate(state));

  const [isKidsTemplate, setIsKidsTemplate] = useState(false);

  useEffect(() => {
    if (template === TEMPLATE_TYPES.KIDS && user?.role_guid === 'R01') {
      setIsKidsTemplate(true);
    }
  }, [template, user]);

  return { isKidsTemplate };
}
