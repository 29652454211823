import axios from 'axios';

// UNIDAD DELETE
export function deleteSectionOfLesson(params, courseGuid) {
  let path = `front/lesson-items`;
  if (courseGuid) path = path + `?referenced_course_guid=${courseGuid}`;
  return axios.delete(path, params);
}
// UNIDAD UPDATE
export function putSectionOfLesson(params, courseGuid) {
  let path = 'front/lesson-items/' + params.guid;
  if (courseGuid) path = path + `?referenced_course_guid=${courseGuid}`;
  return axios.put(path, params.data);
}

// Add new section to lesson
export function postNewContentInLessonItem(params) {
  return axios.post(`front/lesson-items`, params);
}

export function putReorder(params) {
  return axios.put('front/lesson-items', params.data);
}

export function getLesson(guid) {
  return axios.get('front/lessons/' + guid);
}

export function getItemsOfLesson(params, courseGuid) {
  let path = `front/lessons/${params.guid}/items`;
  if (courseGuid) path = path + `?courseGuid=${courseGuid}`;
  return axios.get(path, params.data);
}

export function getLessonItemMessages(params) {
  //return axios.get('front/courses/' + params.guid + '/items');
  return axios.get(`front/courses/${params.courseGuid}/messages/${params.courseMessageGuid}`, params.data);
}

export function getLessonItems(lessonItemGuid, courseGuid) {
  let path = `front/lesson-items/${lessonItemGuid}`;
  if (courseGuid) path = path + `?courseGuid=${courseGuid}`;
  return axios.get(path);
}

export function getContent(lessonItemGuid) {
  //return axios.get('front/courses/' + params.guid + '/items');
  return axios.get(`cms/contents/${lessonItemGuid}`);
}
export function getTimetable(courseGuid) {
  //return axios.get('front/courses/' + params.guid + '/items');
  return axios.get(`front/courses/${courseGuid}/timetable`);
}

//IA

export function sendTextToIA(lessonItemGuid, toAge, text) {
  return axios.post('front/lesson-items/' + lessonItemGuid + '/ia', {
    toAge: toAge,
    text: text,
  });
}

// MENSAJE

export function postMessaje(params) {
  //return axios.get(`front/courses/${params.courseGuid}/messages/${params.courseMessageGuid}`, params.data );
  return axios.post(`front/courses/${params.courseGuid}/messages`, params.data);
}
export function deleteMessaje(params) {
  //return axios.get(`front/courses/${params.courseGuid}/messages/${params.courseMessageGuid}`, params.data );
  return axios.delete(`front/courses/${params.courseGuid}/messages`, { data: params.data });
}
export function putMessaje(params) {
  //return axios.get(`front/courses/${params.courseGuid}/messages/${params.courseMessageGuid}`, params.data );
  return axios.put(`front/courses/${params.courseGuid}/messages/${params.messajeGuid}`, params.data);
}

// NEW CONTENT

export function postNewContent(params) {
  return axios.post(`cms/contents`, params);
}
export function updateContent(params) {
  return axios.put(`cms/contents`, { guid: params.guid, data: params.data });
}

// GET MS TOKEN

export function getMsToken() {
  return axios.get('msir-launch-params');
}

/*
export function getTimetable(params) {
  //return axios.get('front/courses/' + params.guid + '/items');
  return axios.get('front/courses/' + params.guid + '/timetable');
}

export function postUnit(params) {
  // UNIDAD ADD
  return axios.post('front/courses/' + params.guid + '/items', params.data);
}
export function deleteUnit(params) {
  // UNIDAD DELETE
  return axios.delete('front/courses/' + params.guid + '/items', { data: { guid: [params.guid] } });
}

export function editUnit(params) {
  // UNIDAD EDIT TITLE
  return axios.put('front/courses/' + params.course_guid + '/items/' + params.guid, params.data);
  //front/courses/{courseGuid}/items/{itemGuid}
}

export function editUnitOrder(params) {
  // UNIDAD EDIT ORDER
  return axios.put('front/courses/' + params.course_guid + '/items', params.data);
}

export function createLesson(payload) {
  //return axios.post('lms/programs/' + payload.program_id + '/lessons', payload.data);
}

export function getLessons(params) {
  // GET LESSONS
  return axios.get('front/courses/' + params.guid + '/items');
}

export function postLesson(params) {
  // LESSON ADD
  return axios.post('front/courses/' + params.guid + '/lessons', params.data);
}

export function editLesson(payload) {
  // LESSON EDIT TITLE
  return axios.put('front/courses/' + payload.course_guid + '/lessons/' + payload.guid, payload.data);
}

// DELETE front/courses/{programGuid}/items
export function deleteLesson(params) {
  return axios.delete('front/courses/' + params.course_guid + '/items', { data: { guid: [params.guid] } });
}

// GET lms/programs/lessons/{lessonGuid}/thumbnail
// GET lms/programs/lessons/{lessonGuid}/thumbnail-blob
export function getTokenForThumbUpload(params) {
  return axios.get('lms/programs/lessons/' + params.guid + '/thumbnail-blob');
}

// GET lms/programs/{programGuid}
export function getProgramDetails(params) {
  return axios.get('lms/programs/' + params.guid);
}

export function reorderLessons(params) {
  return axios.put('front/courses/' + params.programGuid + '/items', params);
}

export function getLesson(guid) {
  return axios.get('front/lessons/' + guid);
}
*/
