import React from 'react';

function MedalTop() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_102_4693)">
        <path
          d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
          fill="#C12A31"
        />
        <path
          d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
          fill="#E6A22A"
        />
        <path
          d="M30.156 15.0693H29.1405V18.493H30.156C30.8748 18.493 31.5401 18.8164 31.9814 19.3802C32.4226 19.944 32.5743 20.6643 32.3975 21.3566L30.7603 27.7656H29.1405V31.1893H32.1001C32.8883 31.1893 33.5758 30.6578 33.7697 29.8987L35.7365 22.1991C36.1768 20.4755 35.7994 18.6819 34.7007 17.2782C33.6019 15.8743 31.9455 15.0693 30.156 15.0693Z"
          fill="#E6A22A"
        />
        <path
          d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
          fill="#DE3E46"
        />
        <path
          d="M24.1278 64.0002C13.0575 64.0002 4 54.8916 4 43.7589C4 32.6262 13.0575 23.5176 24.1278 23.5176C35.198 23.5176 44.2555 32.6262 44.2555 43.7589C44.2555 54.8916 35.198 64.0002 24.1278 64.0002Z"
          fill="#E6A22A"
        />
        <path
          d="M24.1278 60.6941C34.54 60.6941 42.9808 52.2057 42.9808 41.7347C42.9808 31.2638 34.54 22.7754 24.1278 22.7754C13.7156 22.7754 5.27478 31.2638 5.27478 41.7347C5.27478 52.2057 13.7156 60.6941 24.1278 60.6941Z"
          fill="#C78300"
        />
        <path
          d="M9.27313 43.9511C9.27313 34.5058 16.9009 26.8277 26.2842 26.8277C30.4613 26.8277 34.2751 28.3512 37.2415 30.8496C34.1541 27.1324 29.4927 24.7559 24.2864 24.7559C14.9031 24.7559 7.27539 32.434 7.27539 41.8792C7.27539 47.1198 9.63635 51.812 13.3291 54.9807C10.7866 51.9948 9.27313 48.1558 9.27313 43.9511Z"
          fill="#E5A300"
        />
        <path
          d="M37.4792 28.3755C44.8594 35.7973 44.8594 47.8071 37.4792 55.229C30.099 62.6508 18.1565 62.6508 10.7764 55.229"
          fill="black"
          fillOpacity="0.05"
        />
        <path
          d="M44.3989 41.6913C44.3989 52.8993 35.3622 61.9852 24.2148 61.9852C13.0674 61.9852 4.03064 52.8993 4.03064 41.6913C4.03064 30.4833 13.0674 21.3975 24.2148 21.3975C35.3622 21.3975 44.3989 30.4833 44.3989 41.6913ZM8.1344 41.6913C8.1344 50.6205 15.3338 57.8591 24.2148 57.8591C33.0958 57.8591 40.2952 50.6205 40.2952 41.6913C40.2952 32.7621 33.0958 25.5235 24.2148 25.5235C15.3338 25.5235 8.1344 32.7621 8.1344 41.6913Z"
          fill="#FFC84B"
        />
        <path
          d="M32.7651 57.0847C32.9404 57.3992 32.8295 57.7982 32.5106 57.963C30.0008 59.2606 27.2201 59.9494 24.3919 59.9697C21.5637 59.9901 18.7734 59.3415 16.2453 58.0803C15.9241 57.92 15.8075 57.5227 15.9784 57.2057C16.1492 56.8888 16.5427 56.7723 16.8644 56.9316C19.1994 58.0884 21.7736 58.6831 24.3826 58.6643C26.9916 58.6455 29.557 58.0138 31.8753 56.8234C32.1946 56.6595 32.5898 56.7703 32.7651 57.0847Z"
          fill="#FFE09B"
        />
        <path
          d="M42.5243 36.6713C42.8256 36.5845 43.0006 36.2683 42.9056 35.968C41.6784 32.0894 39.2827 28.6841 36.0434 26.2275C32.653 23.6563 28.5224 22.2641 24.2756 22.2612C20.0287 22.2584 15.8963 23.645 12.5024 26.2117C9.25987 28.664 6.85968 32.0661 5.62733 35.943C5.53193 36.2432 5.70655 36.5597 6.00769 36.6468C6.30884 36.734 6.62242 36.5588 6.71835 36.2589C7.88219 32.6199 10.1389 29.4269 13.1846 27.1236C16.3816 24.7058 20.2743 23.3996 24.2748 23.4022C28.2753 23.4049 32.1662 24.7163 35.3601 27.1384C38.4027 29.4459 40.6552 32.6419 41.8142 36.2824C41.9097 36.5825 42.2231 36.7581 42.5243 36.6713Z"
          fill="#FFE09B"
        />
        <path
          d="M15.1105 35.3291L19.6429 41.2229L23.6809 34.3452C23.7939 34.2062 23.9369 34.0945 24.0991 34.0185C24.2614 33.9424 24.4387 33.9039 24.6178 33.9058C24.797 33.9078 24.9734 33.9501 25.1339 34.0297C25.2945 34.1093 25.435 34.2241 25.545 34.3655L29.3984 41.2218L34.0772 35.2708C34.261 35.1122 34.4888 35.0136 34.7301 34.9881C34.9714 34.9625 35.2148 35.0113 35.4276 35.1279C35.6405 35.2445 35.8126 35.4233 35.921 35.6404C36.0294 35.8576 36.069 36.1026 36.0343 36.3428L34.2476 47.7147H14.9592L13.1451 36.3988C13.1091 36.1573 13.1482 35.9105 13.257 35.6919C13.3659 35.4733 13.5393 35.2935 13.7538 35.1768C13.9683 35.06 14.2135 35.012 14.4562 35.0393C14.6988 35.0665 14.9273 35.1677 15.1105 35.3291ZM15.0522 49.8588H34.1106V51.0499C34.1106 51.3659 33.9851 51.6688 33.7617 51.8922C33.5383 52.1156 33.2354 52.2411 32.9194 52.2411H16.2433C15.9274 52.2411 15.6244 52.1156 15.401 51.8922C15.1777 51.6688 15.0522 51.3659 15.0522 51.0499L15.0522 49.8588Z"
          fill="#E6A22A"
        />
        <path
          d="M15.1105 33.4234L19.6429 39.3172L23.6809 32.4395C23.7939 32.3005 23.9369 32.1888 24.0991 32.1127C24.2614 32.0366 24.4387 31.9981 24.6178 32.0001C24.797 32.002 24.9734 32.0444 25.1339 32.1239C25.2945 32.2035 25.435 32.3183 25.545 32.4597L29.3984 39.316L34.0772 33.365C34.261 33.2065 34.4888 33.1078 34.7301 33.0823C34.9714 33.0568 35.2148 33.1056 35.4276 33.2222C35.6405 33.3388 35.8126 33.5176 35.921 33.7347C36.0294 33.9518 36.069 34.1968 36.0343 34.437L34.2476 45.809H14.9592L13.1451 34.493C13.1091 34.2515 13.1482 34.0047 13.257 33.7861C13.3659 33.5676 13.5393 33.3877 13.7538 33.271C13.9683 33.1543 14.2135 33.1063 14.4562 33.1335C14.6988 33.1608 14.9273 33.262 15.1105 33.4234ZM15.0522 47.953H34.1106V49.1442C34.1106 49.4601 33.9851 49.7631 33.7617 49.9865C33.5383 50.2098 33.2354 50.3353 32.9194 50.3353H16.2433C15.9274 50.3353 15.6244 50.2098 15.401 49.9865C15.1777 49.7631 15.0522 49.4601 15.0522 49.1442L15.0522 47.953Z"
          fill="#FFD159"
        />
        <path
          d="M15.5541 35.0093L19.624 40.9557L24 34.0001C24.0888 33.9015 24.2011 33.8223 24.3286 33.7684C24.456 33.7144 24.5953 33.6871 24.736 33.6885C24.8768 33.6899 25.0153 33.7199 25.1414 33.7764C25.2675 33.8328 25.3779 33.9142 25.4643 34.0144L29.2873 40.9557L33.4755 35.0093C33.6198 34.8969 33.7988 34.8269 33.9883 34.8088C34.1779 34.7907 34.3691 34.8253 34.5363 34.908C34.7035 34.9907 34.8387 35.1174 34.9239 35.2714C35.009 35.4253 35.0401 35.5991 35.0128 35.7694L33.4755 40.9557C33.4755 40.9557 27.6642 41.7058 24 42.5C20.6163 43.2334 15.4353 44.7909 15.4353 44.7909L14.0102 35.7677C13.9819 35.5965 14.0126 35.4215 14.0982 35.2666C14.1837 35.1116 14.3199 34.9841 14.4884 34.9013C14.6569 34.8185 14.8495 34.7845 15.0401 34.8038C15.2307 34.8232 15.4102 34.8949 15.5541 35.0093ZM15.5083 48.3111H30.4793V49.1556C27.5 49.1556 16.5 49.1556 15.5083 49.1556L15.5083 48.3111Z"
          fill="#FFE09B"
        />
      </g>
      <defs>
        <clipPath id="clip0_102_4693">
          <rect width="48" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MedalTop;
