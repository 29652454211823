export function initAssistant(user, client = 'EDELVIVES_DIGITAL_PLUS') {
  window.addEventListener('load', function () {
    if (typeof window.tangeriniaWidget !== 'undefined') {
      try {
        window.tangeriniaWidget.setClient(client);
        window.tangeriniaWidget.setUser(user?.guid, user?.role_name.toUpperCase(), user?.name, user?.avatar);
        window.tangeriniaWidget.setIsActive(user?.hasAssistant);
      } catch (error) {
        console.error('Exception initializing ai assistant', error);
      }
    }
  });
}

export function modifyUser(user) {
  if (typeof window.tangeriniaWidget !== 'undefined' && typeof window.tangeriniaWidget.user !== 'undefined' && user?.guid) {
    try {
      window.tangeriniaWidget.setUser(user?.guid, user?.role_name.toUpperCase(), user?.name, user?.avatar);
      window.tangeriniaWidget.setIsActive(user?.hasAssistant);
    } catch (error) {
      console.error('Exception modifying user for ai assistant', error);
    }
  }
}

export function setScopeInfo(course) {
  if (course?.name && typeof window.tangeriniaWidget !== 'undefined' && typeof window.tangeriniaWidget.user !== 'undefined') {
    try {
      window.tangeriniaWidget.setScopeName(course.name);
      window.tangeriniaWidget.setScopeId(course.type === 'referenced' ? course.program_guid : course.guid);
    } catch (error) {
      console.error('Exception setting scope info for ai assistant', error);
    }
  }
}

export function setCourse(course) {
  console.log('set buddy course: ', course);
  if (course?.name && typeof window.tangeriniaWidget !== 'undefined' && typeof window.tangeriniaWidget.user !== 'undefined') {
    try {
      window.tangeriniaWidget.setCourse({
        name: course.name,
        guid: course.guid,
        parentName: course.school_group_name,
        contentsUrls: [
          { url: `${window.location.origin}/course/${course.program_guid}/program`, name: course.name, guid: course.guid },
          ...course.books.map((book) => ({ guid: book.guid, name: book.name, url: `${window.location.origin}/viewerfull/${book.guid}` })),
        ],
      });
    } catch (error) {
      console.error('Exception setting scope info for ai assistant', error);
    }
  }
}
