import React from 'react';

function User7() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_80_11299)">
        <circle cx="18" cy="18" r="17" fill="#E0E0E0" />
      </g>
      <mask id="mask0_80_11299" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="34" height="34">
        <circle cx="18" cy="18" r="17" fill="#78604D" />
      </mask>
      <g mask="url(#mask0_80_11299)">
        <path
          d="M30.9217 29.7501C30.5809 27.0234 28.1092 26.4961 27.5242 25.6789C26.4999 24.2501 27.5772 22.7194 26.1219 21.5219H9.87794C8.42269 22.7194 9.49994 24.2501 8.47494 25.6794C7.89044 26.4966 5.41894 27.0251 5.07794 29.7501C4.85919 31.5001 6.25419 33.5686 9.13994 34.0931H26.8599C29.7457 33.5691 31.1404 31.5001 30.9217 29.7501Z"
          fill="#BF4F4F"
        />
        <path d="M18 4.75476C10.05 4.75476 6.68327 12.7945 9.43002 18.2935L26.582 17.957C29.329 12.458 25.9513 4.75476 18 4.75476Z" fill="#BF4F4F" />
        <path
          d="M8.83508 18.0713C8.17833 18.6263 8.15158 19.6326 8.53333 20.4398C8.96958 21.3628 10.2001 22.1636 11.1643 21.6758L10.5703 17.6641C9.70558 17.5471 9.32808 17.6546 8.83508 18.0713Z"
          fill="#FF8888"
        />
        <path
          d="M27.1649 18.0712C26.6717 17.6544 26.2944 17.5462 25.4297 17.6639L24.8357 21.6749C25.7999 22.1627 27.0304 21.3619 27.4667 20.4389C27.8484 19.6324 27.8217 18.6262 27.1649 18.0712Z"
          fill="#FF8888"
        />
        <path
          d="M10.7565 20.1668C10.696 20.1668 10.6364 20.1522 10.5827 20.1241C10.5291 20.0961 10.4831 20.0555 10.4485 20.0058C10.36 19.8803 10.2426 19.7779 10.1062 19.7073C9.96976 19.6367 9.81838 19.5999 9.66478 19.6001C9.61519 19.6047 9.56517 19.5994 9.51765 19.5845C9.47012 19.5696 9.42605 19.5454 9.38799 19.5132C9.34993 19.4811 9.31865 19.4417 9.29598 19.3974C9.27331 19.353 9.2597 19.3046 9.25595 19.2549C9.2522 19.2053 9.25837 19.1554 9.27412 19.1081C9.28987 19.0609 9.31488 19.0172 9.34768 18.9797C9.38048 18.9423 9.42041 18.9117 9.46516 18.8898C9.5099 18.8679 9.55855 18.8552 9.60828 18.8523C9.89219 18.8421 10.1742 18.903 10.4285 19.0297C10.6828 19.1563 10.9014 19.3446 11.0643 19.5773C11.1034 19.6335 11.1264 19.6994 11.1308 19.7678C11.1351 19.8362 11.1206 19.9044 11.0889 19.9652C11.0572 20.0259 11.0094 20.0768 10.9508 20.1123C10.8922 20.1478 10.825 20.1666 10.7565 20.1666V20.1668Z"
          fill="#ED4559"
        />
        <path
          d="M25.2434 20.1668C25.1749 20.1668 25.1077 20.148 25.0491 20.1125C24.9905 20.077 24.9427 20.0262 24.911 19.9654C24.8793 19.9047 24.8648 19.8364 24.8692 19.7681C24.8735 19.6997 24.8965 19.6338 24.9356 19.5776C25.0989 19.3452 25.3175 19.1572 25.5718 19.0306C25.826 18.904 26.1078 18.8428 26.3916 18.8526C26.4414 18.8554 26.49 18.8682 26.5348 18.8901C26.5795 18.9119 26.6194 18.9425 26.6522 18.98C26.685 19.0175 26.71 19.0611 26.7258 19.1084C26.7415 19.1556 26.7477 19.2055 26.744 19.2552C26.7402 19.3049 26.7266 19.3533 26.7039 19.3976C26.6813 19.442 26.65 19.4814 26.6119 19.5135C26.5739 19.5456 26.5298 19.5699 26.4823 19.5848C26.4347 19.5997 26.3847 19.605 26.3351 19.6003C26.1815 19.5997 26.0299 19.6363 25.8934 19.707C25.757 19.7776 25.6396 19.8803 25.5514 20.0061C25.5168 20.0557 25.4708 20.0963 25.4171 20.1243C25.3635 20.1523 25.3039 20.1669 25.2434 20.1668Z"
          fill="#ED4559"
        />
        <path
          d="M24.8715 34.64H11.1285C9.94623 34.64 9.23498 33.84 9.85998 32.8362C10.8185 31.2972 12.2822 29.9837 14.1175 29.435C15.315 29.0775 15.985 28.5125 16.28 27.735C16.3945 27.4334 16.4579 27.1149 16.4675 26.7925H19.5325C19.5421 27.1149 19.6055 27.4334 19.72 27.735C20.015 28.5125 20.685 29.0775 21.8825 29.435C23.7177 29.985 25.1825 31.2972 26.14 32.8362C26.765 33.8397 26.0537 34.64 24.8715 34.64Z"
          fill="#FF8888"
        />
        <path
          d="M22.2642 29.9223L22.3087 32.9418C22.3092 32.9749 22.303 33.0078 22.2907 33.0385C22.2783 33.0693 22.26 33.0972 22.2367 33.1208C22.2135 33.1444 22.1857 33.1631 22.1552 33.1759C22.1246 33.1887 22.0918 33.1953 22.0587 33.1953H13.5054C13.4391 33.1953 13.3755 33.1689 13.3287 33.122C13.2818 33.0752 13.2554 33.0116 13.2554 32.9453V30.128C13.2553 30.0854 13.2443 30.0436 13.2235 30.0064C13.2028 29.9693 13.1729 29.938 13.1368 29.9155C13.1006 29.893 13.0594 29.8801 13.0168 29.8779C12.9743 29.8757 12.932 29.8844 12.8937 29.903C11.9408 30.3494 11.0972 30.9989 10.4221 31.806C9.74695 32.6132 9.25668 33.5583 8.98569 34.575C8.97543 34.6122 8.97391 34.6513 8.98127 34.6892C8.98862 34.7271 9.00465 34.7627 9.02809 34.7934C9.05153 34.824 9.08174 34.8488 9.11637 34.8659C9.151 34.8829 9.1891 34.8917 9.22769 34.8915H26.7729C26.8114 34.8916 26.8493 34.8827 26.8838 34.8657C26.9182 34.8487 26.9483 34.824 26.9717 34.7935C26.9951 34.763 27.0111 34.7275 27.0185 34.6897C27.0259 34.652 27.0245 34.6131 27.0144 34.576C26.7189 33.4695 26.1647 32.449 25.3975 31.5987C24.6303 30.7483 23.672 30.0923 22.6017 29.6848C22.5636 29.6705 22.5226 29.6658 22.4823 29.671C22.442 29.6762 22.4035 29.6911 22.3703 29.7145C22.337 29.7379 22.31 29.769 22.2915 29.8052C22.273 29.8414 22.2636 29.8816 22.2642 29.9223Z"
          fill="#2B8ED8"
        />
        <path
          d="M21.7615 31.5C20.5143 31.6715 19.2585 31.7732 18 31.8048C16.7415 31.7732 15.4857 31.6715 14.2385 31.5L13.9583 34.8908H22.0208L21.7615 31.5Z"
          fill="#FFE8E6"
        />
        <path
          d="M13.3001 28.8706C12.5541 29.0991 11.2463 29.6044 10.4306 30.5181C10.3587 30.6 10.3155 30.7031 10.3076 30.8118C10.2997 30.9205 10.3275 31.0287 10.3868 31.1201L12.8118 34.8906H14.6868L13.9448 29.2824C13.9353 29.2101 13.91 29.1409 13.8709 29.0794C13.8318 29.0179 13.7796 28.9657 13.7182 28.9265C13.6568 28.8873 13.5876 28.862 13.5153 28.8523C13.4431 28.8427 13.3696 28.8489 13.3001 28.8706Z"
          fill="#44ABEA"
        />
        <path
          d="M22.6999 28.8708C23.4459 29.0993 24.7536 29.6045 25.5694 30.5183C25.6412 30.6001 25.6844 30.7033 25.6923 30.8119C25.7002 30.9206 25.6724 31.0289 25.6131 31.1203L23.1881 34.8905H21.3131L22.0559 29.2825C22.0654 29.2104 22.0906 29.1412 22.1297 29.0798C22.1688 29.0184 22.2209 28.9662 22.2822 28.927C22.3435 28.8878 22.4127 28.8624 22.4848 28.8527C22.5569 28.843 22.6303 28.8492 22.6999 28.8708Z"
          fill="#44ABEA"
        />
        <path
          d="M19.72 27.735C19.3925 27.8375 19.09 27.9225 18.825 27.99C18.2839 28.13 17.7161 28.13 17.175 27.99C16.91 27.9225 16.6075 27.8375 16.28 27.735C16.3943 27.3474 16.4573 26.9465 16.4675 26.5425H19.5325C19.5427 26.9465 19.6058 27.3474 19.72 27.735Z"
          fill="#ED4559"
        />
        <path
          d="M10.1921 16.45C10.2321 16.847 10.3046 17.3197 10.3436 17.8397C10.5006 19.926 10.7704 22.784 12.5436 24.6527C13.8271 26.0057 15.9294 26.67 17.1761 26.991C17.7172 27.1309 18.285 27.1309 18.8261 26.991C20.0729 26.67 22.1761 26.0057 23.4586 24.6527C25.1989 22.8182 25.4719 20.0295 25.6319 17.9542C25.6754 17.3885 25.7664 16.876 25.8094 16.45C23.0624 7.70824 12.1249 7.33324 10.1921 16.45Z"
          fill="#FFA6A2"
        />
        <path
          d="M13.5466 17.8123C13.4709 17.812 13.397 17.7889 13.3347 17.7459C13.2724 17.7029 13.2245 17.642 13.1975 17.5713C13.1704 17.5005 13.1654 17.4233 13.1831 17.3496C13.2008 17.276 13.2404 17.2095 13.2966 17.1588C13.8851 16.6275 15.0194 16.4838 15.6574 17.0838C15.6932 17.1176 15.722 17.1581 15.7421 17.203C15.7623 17.2479 15.7734 17.2964 15.7748 17.3456C15.7763 17.3949 15.768 17.4439 15.7505 17.4899C15.7329 17.5359 15.7065 17.5781 15.6727 17.6139C15.6389 17.6497 15.5984 17.6785 15.5535 17.6987C15.5085 17.7188 15.4601 17.7299 15.4109 17.7314C15.3616 17.7328 15.3126 17.7245 15.2666 17.707C15.2206 17.6895 15.1784 17.6631 15.1426 17.6293C14.8401 17.3438 14.1624 17.3865 13.7994 17.7145C13.7302 17.7775 13.6401 17.8123 13.5466 17.8123Z"
          fill="#A54646"
        />
        <path
          d="M14.6365 18.97C15.0598 18.97 15.403 18.6268 15.403 18.2035C15.403 17.7802 15.0598 17.437 14.6365 17.437C14.2132 17.437 13.87 17.7802 13.87 18.2035C13.87 18.6268 14.2132 18.97 14.6365 18.97Z"
          fill="#BF4F4F"
        />
        <path
          d="M12.9377 15.75C12.859 15.75 12.7823 15.7253 12.7185 15.6793C12.6546 15.6332 12.6069 15.5683 12.582 15.4936C12.5571 15.419 12.5563 15.3384 12.5798 15.2632C12.6033 15.1881 12.6498 15.1223 12.7127 15.075C13.441 14.534 15.1817 14.5723 15.5762 14.778C15.6644 14.8242 15.7306 14.9034 15.7603 14.9983C15.7901 15.0933 15.7809 15.1961 15.7347 15.2843C15.6886 15.3724 15.6094 15.4387 15.5144 15.4684C15.4195 15.4981 15.3166 15.4889 15.2285 15.4428C14.9862 15.3493 13.6222 15.3338 13.1607 15.677C13.0961 15.7246 13.018 15.7502 12.9377 15.75Z"
          fill="#A54646"
        />
        <path
          d="M22.4531 17.8121C22.3606 17.8118 22.2716 17.7773 22.2031 17.7154C21.8403 17.3874 21.1623 17.3446 20.8598 17.6301C20.824 17.6639 20.7819 17.6904 20.7358 17.7079C20.6898 17.7254 20.6408 17.7337 20.5916 17.7323C20.5423 17.7308 20.4939 17.7197 20.4489 17.6996C20.404 17.6794 20.3635 17.6506 20.3297 17.6148C20.2959 17.579 20.2695 17.5368 20.2519 17.4908C20.2344 17.4448 20.2261 17.3958 20.2276 17.3465C20.229 17.2973 20.2401 17.2488 20.2603 17.2039C20.2804 17.159 20.3092 17.1184 20.3451 17.0846C20.9831 16.4829 22.1176 16.6271 22.7058 17.1596C22.7621 17.2104 22.8017 17.2771 22.8194 17.3509C22.837 17.4247 22.8319 17.502 22.8047 17.5728C22.7774 17.6436 22.7293 17.7045 22.6668 17.7474C22.6042 17.7903 22.5302 17.8132 22.4543 17.8131L22.4531 17.8121Z"
          fill="#A54646"
        />
        <path
          d="M21.3637 18.97C21.787 18.97 22.1302 18.6268 22.1302 18.2035C22.1302 17.7802 21.787 17.437 21.3637 17.437C20.9403 17.437 20.5972 17.7802 20.5972 18.2035C20.5972 18.6268 20.9403 18.97 21.3637 18.97Z"
          fill="#BF4F4F"
        />
        <path
          d="M23.0622 15.75C22.9815 15.7497 22.903 15.7234 22.8385 15.675C22.3775 15.3315 21.0135 15.3473 20.7605 15.4455C20.7171 15.4679 20.6697 15.4815 20.6211 15.4855C20.5724 15.4895 20.5235 15.4839 20.477 15.4689C20.4305 15.454 20.3875 15.43 20.3503 15.3983C20.3132 15.3667 20.2826 15.328 20.2605 15.2845C20.2153 15.1953 20.2071 15.0919 20.2377 14.9967C20.2683 14.9015 20.3353 14.8222 20.424 14.776C20.8185 14.5703 22.5595 14.5323 23.2875 15.073C23.3504 15.1202 23.3969 15.1861 23.4204 15.2612C23.4439 15.3363 23.4431 15.4169 23.4182 15.4916C23.3933 15.5663 23.3456 15.6312 23.2817 15.6772C23.2179 15.7233 23.1412 15.748 23.0625 15.748L23.0622 15.75Z"
          fill="#A54646"
        />
        <path
          d="M18.0001 21.4229H17.028C16.8145 21.4229 16.6175 21.6781 16.6963 22.0186C16.8113 22.5044 17.1855 23.1531 18.0001 23.1531C18.8146 23.1531 19.1888 22.5044 19.3038 22.0186C19.3826 21.6781 19.1856 21.4229 18.9721 21.4229H18.0001Z"
          fill="white"
        />
        <path
          d="M18.4608 20.4833C18.3978 20.4833 18.3358 20.4674 18.2805 20.4372C18.2252 20.4069 18.1785 20.3632 18.1446 20.31C18.0998 20.2872 18.0503 20.2753 18.0001 20.2753C17.9498 20.2753 17.9003 20.2872 17.8556 20.31C17.8119 20.3785 17.7473 20.4309 17.6713 20.4595C17.5953 20.4881 17.5122 20.4912 17.4342 20.4685C17.3563 20.4457 17.2879 20.3983 17.2392 20.3334C17.1905 20.2684 17.1643 20.1894 17.1643 20.1083C17.1643 19.629 17.8343 19.5253 18.0001 19.5253C18.1658 19.5253 18.8358 19.629 18.8358 20.1083C18.8358 20.2077 18.7963 20.3031 18.726 20.3734C18.6556 20.4438 18.5603 20.4833 18.4608 20.4833Z"
          fill="#FF7B7B"
        />
        <path
          d="M10.6139 24.1147C10.5547 24.1148 10.4963 24.1008 10.4436 24.074C10.3908 24.0472 10.3452 24.0083 10.3104 23.9605C9.53536 22.8955 9.77911 21.7765 10.0379 20.5922C10.2879 19.453 10.5694 18.1615 10.0836 16.5575C10.0685 16.5101 10.0629 16.4602 10.0672 16.4106C10.0715 16.3611 10.0857 16.3129 10.1088 16.2689C10.1319 16.2248 10.1636 16.1858 10.2019 16.1541C10.2403 16.1225 10.2845 16.0987 10.3321 16.0843C10.3798 16.0699 10.4297 16.0651 10.4792 16.0702C10.5287 16.0753 10.5767 16.0902 10.6203 16.114C10.664 16.1378 10.7025 16.1701 10.7336 16.2089C10.7647 16.2478 10.7877 16.2924 10.8014 16.3402C11.3439 18.131 11.0264 19.5845 10.7709 20.7522C10.5209 21.8982 10.3391 22.7272 10.9169 23.519C10.9576 23.5749 10.982 23.641 10.9875 23.71C10.993 23.7789 10.9793 23.8481 10.948 23.9097C10.9166 23.9714 10.8688 24.0232 10.8098 24.0594C10.7509 24.0955 10.683 24.1147 10.6139 24.1147Z"
          fill="#A54646"
        />
        <path
          d="M25.3863 24.1147C25.3171 24.1147 25.2493 24.0955 25.1903 24.0594C25.1314 24.0232 25.0836 23.9714 25.0522 23.9097C25.0208 23.8481 25.0071 23.7789 25.0126 23.71C25.0181 23.641 25.0426 23.5749 25.0833 23.519C25.6611 22.7262 25.4798 21.8982 25.2293 20.7522C24.9751 19.5845 24.6563 18.131 25.2001 16.3402C25.23 16.2465 25.2957 16.1683 25.3828 16.1225C25.47 16.0767 25.5716 16.067 25.6659 16.0955C25.7601 16.1241 25.8393 16.1885 25.8864 16.2749C25.9336 16.3613 25.9448 16.4628 25.9178 16.5575C25.4321 18.1615 25.7143 19.453 25.9635 20.5922C26.2223 21.7765 26.4668 22.8955 25.6911 23.9605C25.6561 24.0085 25.6102 24.0475 25.5573 24.0743C25.5043 24.1011 25.4457 24.115 25.3863 24.1147Z"
          fill="#A54646"
        />
        <path
          d="M25.4973 11.75C25.4973 11.75 23.6563 7.84375 18 7.84375C12.3438 7.84375 10.5028 11.75 10.5028 11.75C9.16528 14.2435 8.96803 16.7355 10.7423 16.875C13.0223 17.0542 13.7723 14.8453 14.382 12.7C14.3959 12.6512 14.4265 12.6089 14.4685 12.5805C14.5104 12.5521 14.5611 12.5394 14.6115 12.5446C14.6619 12.5498 14.7089 12.5726 14.7442 12.6091C14.7794 12.6455 14.8007 12.6932 14.8043 12.7437C14.9465 14.6032 15.9905 16.1438 18 16.1438C20.0095 16.1438 21.0535 14.6032 21.1958 12.7437C21.1993 12.6932 21.2206 12.6455 21.2559 12.6091C21.2911 12.5726 21.3381 12.5498 21.3885 12.5446C21.439 12.5394 21.4896 12.5521 21.5316 12.5805C21.5736 12.6089 21.6042 12.6512 21.618 12.7C22.2278 14.8455 22.9778 17.0545 25.2578 16.875C27.032 16.7355 26.8348 14.2435 25.4973 11.75Z"
          fill="#BF4F4F"
        />
      </g>
      <defs>
        <filter id="filter0_i_80_11299" x="1" y="1" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_80_11299" />
        </filter>
      </defs>
    </svg>
  );
}

export default User7;
