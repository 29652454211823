import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import * as coreEntities from '_core/store/index';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TermsOfUse = () => {
  const { t } = useTranslate();
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const [legal, setLegal] = useState('');

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let legalScope = scopes.filter((scope) => scope.scope === 'legal');
      if (legalScope && legalScope.length > 0) {
        setLegal(legalScope[0].json);
      }
    }
  }, [scopes]);

  return (
    <div style={{ padding: '40px' }}>
      <TextHtml text={legal} />
    </div>
  );
};

export default TermsOfUse;
