import React from 'react';

function MedalThird() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
        fill="#154CDA"
      />
      <path
        d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
        fill="#E6A22A"
      />
      <path
        d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
        fill="#3E6BDE"
      />
      <path
        d="M24.1278 63.6027C13.0575 63.6027 4 54.4941 4 43.3614C4 32.2287 13.0575 23.1201 24.1278 23.1201C35.198 23.1201 44.2555 32.2287 44.2555 43.3614C44.2555 54.4941 35.198 63.6027 24.1278 63.6027Z"
        fill="#E6A22A"
      />
      <path
        d="M24.1278 60.2966C34.54 60.2966 42.9808 51.8083 42.9808 41.3373C42.9808 30.8663 34.54 22.3779 24.1278 22.3779C13.7156 22.3779 5.27478 30.8663 5.27478 41.3373C5.27478 51.8083 13.7156 60.2966 24.1278 60.2966Z"
        fill="#E8A700"
      />
      <path
        d="M9.27313 43.5536C9.27313 34.1084 16.9009 26.4303 26.2842 26.4303C30.4613 26.4303 34.2751 27.9537 37.2415 30.4521C34.1541 26.735 29.4927 24.3584 24.2864 24.3584C14.9031 24.3584 7.27539 32.0365 7.27539 41.4818C7.27539 46.7224 9.63635 51.4145 13.3291 54.5833C10.7866 51.5974 9.27313 47.7583 9.27313 43.5536Z"
        fill="#E5A300"
      />
      <path
        d="M37.4792 27.978C44.8594 35.3998 44.8594 47.4097 37.4792 54.8315C30.099 62.2533 18.1565 62.2533 10.7764 54.8315"
        fill="black"
        fillOpacity="0.05"
      />
      <path
        d="M44.3683 41.2939C44.3683 52.5018 35.3316 61.5877 24.1842 61.5877C13.0368 61.5877 4 52.5018 4 41.2939C4 30.0859 13.0368 21 24.1842 21C35.3316 21 44.3683 30.0859 44.3683 41.2939ZM8.10376 41.2939C8.10376 50.2231 15.3032 57.4616 24.1842 57.4616C33.0651 57.4616 40.2645 50.2231 40.2645 41.2939C40.2645 32.3646 33.0651 25.1261 24.1842 25.1261C15.3032 25.1261 8.10376 32.3646 8.10376 41.2939Z"
        fill="#FFC84B"
      />
      <path
        d="M32.7651 56.6873C32.9404 57.0017 32.8295 57.4007 32.5106 57.5655C30.0008 58.8632 27.2201 59.5519 24.3919 59.5723C21.5637 59.5927 18.7734 58.9441 16.2453 57.6828C15.9241 57.5226 15.8075 57.1252 15.9784 56.8083C16.1492 56.4913 16.5427 56.3748 16.8644 56.5342C19.1994 57.691 21.7736 58.2856 24.3826 58.2668C26.9916 58.248 29.557 57.6163 31.8753 56.426C32.1946 56.262 32.5898 56.3728 32.7651 56.6873Z"
        fill="#FFE09B"
      />
      <path
        d="M42.5243 36.2739C42.8256 36.1871 43.0006 35.8708 42.9056 35.5706C41.6784 31.692 39.2827 28.2866 36.0434 25.83C32.653 23.2588 28.5224 21.8666 24.2756 21.8638C20.0287 21.8609 15.8963 23.2476 12.5024 25.8143C9.25987 28.2665 6.85968 31.6686 5.62733 35.5456C5.53193 35.8457 5.70655 36.1622 6.00769 36.2494C6.30884 36.3365 6.62242 36.1614 6.71835 35.8614C7.88219 32.2225 10.1389 29.0294 13.1846 26.7261C16.3816 24.3083 20.2743 23.0021 24.2748 23.0048C28.2753 23.0075 32.1662 24.3189 35.3601 26.741C38.4027 29.0484 40.6552 32.2444 41.8142 35.885C41.9097 36.185 42.2231 36.3606 42.5243 36.2739Z"
        fill="#FFE09B"
      />
      <path
        d="M17 51.6778C17 51.1074 17.1396 50.4657 17.4188 49.7528C17.698 49.0194 17.9672 48.4389 18.2265 48.0111C18.4858 47.563 18.6353 47.3389 18.6752 47.3389C19.8319 48.0519 21.0484 48.4083 22.3248 48.4083C23.1225 48.4083 23.7607 48.2454 24.2393 47.9194C24.7379 47.5935 24.9872 47.1454 24.9872 46.575C24.9872 45.9843 24.7578 45.6074 24.2991 45.4444C23.8604 45.2815 23.1524 45.1694 22.1752 45.1083H21.0983C20.859 45.1083 20.6296 44.813 20.4103 44.2222C20.2108 43.6111 20.1111 43.0204 20.1111 42.45C20.1111 41.4926 20.3903 41.0037 20.9487 40.9833L21.8162 40.9528C22.3547 40.9528 22.9131 40.8 23.4915 40.4944C24.0897 40.1685 24.3889 39.7611 24.3889 39.2722C24.3889 38.4167 23.641 37.9889 22.1453 37.9889C21.0285 37.9889 19.9516 38.213 18.9145 38.6611C18.0171 37.0111 17.5684 35.7278 17.5684 34.8111C17.5684 33.8741 18.1966 33.1713 19.453 32.7028C20.7293 32.2343 22.3248 32 24.2393 32C26.1538 32 27.6496 32.4074 28.7265 33.2222C29.8034 34.037 30.3419 35.1472 30.3419 36.5528C30.3419 39.0176 29.3048 40.912 27.2308 42.2361C29.7436 43.275 31 45.037 31 47.5222C31 49.4574 30.3319 51.0259 28.9957 52.2278C27.6595 53.4093 25.745 54 23.2521 54C21.6766 54 20.2407 53.7759 18.9444 53.3278C17.6481 52.8593 17 52.3093 17 51.6778Z"
        fill="#C6881A"
      />
      <path
        d="M17 49.6778C17 49.1074 17.1396 48.4657 17.4188 47.7528C17.698 47.0194 17.9672 46.4389 18.2265 46.0111C18.4858 45.563 18.6353 45.3389 18.6752 45.3389C19.8319 46.0519 21.0484 46.4083 22.3248 46.4083C23.1225 46.4083 23.7607 46.2454 24.2393 45.9194C24.7379 45.5935 24.9872 45.1454 24.9872 44.575C24.9872 43.9843 24.7578 43.6074 24.2991 43.4444C23.8604 43.2815 23.1524 43.1694 22.1752 43.1083H21.0983C20.859 43.1083 20.6296 42.813 20.4103 42.2222C20.2108 41.6111 20.1111 41.0204 20.1111 40.45C20.1111 39.4926 20.3903 39.0037 20.9487 38.9833L21.8162 38.9528C22.3547 38.9528 22.9131 38.8 23.4915 38.4944C24.0897 38.1685 24.3889 37.7611 24.3889 37.2722C24.3889 36.4167 23.641 35.9889 22.1453 35.9889C21.0285 35.9889 19.9516 36.213 18.9145 36.6611C18.0171 35.0111 17.5684 33.7278 17.5684 32.8111C17.5684 31.8741 18.1966 31.1713 19.453 30.7028C20.7293 30.2343 22.3248 30 24.2393 30C26.1538 30 27.6496 30.4074 28.7265 31.2222C29.8034 32.037 30.3419 33.1472 30.3419 34.5528C30.3419 37.0176 29.3048 38.912 27.2308 40.2361C29.7436 41.275 31 43.037 31 45.5222C31 47.4574 30.3319 49.0259 28.9957 50.2278C27.6595 51.4093 25.745 52 23.2521 52C21.6766 52 20.2407 51.7759 18.9444 51.3278C17.6481 50.8593 17 50.3093 17 49.6778Z"
        fill="#FFF0BB"
      />
    </svg>
  );
}

export default MedalThird;
