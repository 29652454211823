import Icon from '_core/modules/atoms/Icon';
import React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import useGamifiedToast from './useGamifiedToast';

function GamifiedToast(props) {
  const { onDismiss } = props;
  const { medal, subtitle, title } = useGamifiedToast(props);

  return (
    <div className="gamified-toast">
      <DefaultToast {...props}>
        <div className="d-flex">
          {medal}

          <div className="d-flex flex-column ml-4">
            <span className="gamified-toast__title">{title}</span>
            <span className="gamified-toast__subtitle">{subtitle}</span>
          </div>

          <div className="hover-basic" onClick={onDismiss}>
            <Icon size="big" type="close" />
          </div>
        </div>
      </DefaultToast>
    </div>
  );
}

export default GamifiedToast;
