import { getGamifiedEvent } from '_core/modules/components/gamified/GamifiedToast/GamifiedToast.service';
import { useToasts } from 'react-toast-notifications';

function useGamifiedToast() {
  const { addToast } = useToasts();

  function openToast(gamified) {
    addToast(undefined, {
      appearance: 'success',
      autoDismiss: true,
      isGamified: true,
      gamified,
    });
  }

  function onActionSocketGamifed(message) {
    if (message.type === 'gamification-achievement') {
      const { data } = message;
      const { event } = JSON.parse(data);
      const gamified = getGamifiedEvent(event);
      openToast(gamified);
    }
  }

  return { openToast, onActionSocketGamifed };
}

export default useGamifiedToast;
