import useMathRender from '_core/hooks/useMathRender';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const TextHtml = ({ text, className, onClick, id }) => {
  const rootNode = useRef(null);
  useMathRender(rootNode, [text]);

  return <div ref={rootNode} id={id} dangerouslySetInnerHTML={{ __html: text }} className={clsx('text-html', className)} onClick={onClick} />;

  // return <div onClick={onClick} className={clsx('text-html', className)} dangerouslySetInnerHTML={{ __html: text }}></div>;
};

TextHtml.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

TextHtml.defaultProps = {
  id: 'useMathRender',
  onClick: () => {},
  text: '',
};

export default TextHtml;
