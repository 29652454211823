import React from 'react';

function MedalCheck() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
        fill="#154CDA"
      />
      <path
        d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
        fill="#E6A22A"
      />
      <path
        d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
        fill="#3E6BDE"
      />
      <path
        d="M24.1278 63.6027C13.0575 63.6027 4 54.4941 4 43.3614C4 32.2287 13.0575 23.1201 24.1278 23.1201C35.198 23.1201 44.2555 32.2287 44.2555 43.3614C44.2555 54.4941 35.198 63.6027 24.1278 63.6027Z"
        fill="#E6A22A"
      />
      <path
        d="M24.1278 60.2966C34.54 60.2966 42.9808 51.8083 42.9808 41.3373C42.9808 30.8663 34.54 22.3779 24.1278 22.3779C13.7156 22.3779 5.27478 30.8663 5.27478 41.3373C5.27478 51.8083 13.7156 60.2966 24.1278 60.2966Z"
        fill="#E8A700"
      />
      <path
        d="M9.27313 43.5536C9.27313 34.1084 16.9009 26.4303 26.2842 26.4303C30.4613 26.4303 34.2751 27.9537 37.2415 30.4521C34.1541 26.735 29.4927 24.3584 24.2864 24.3584C14.9031 24.3584 7.27539 32.0365 7.27539 41.4818C7.27539 46.7224 9.63635 51.4145 13.3291 54.5833C10.7866 51.5974 9.27313 47.7583 9.27313 43.5536Z"
        fill="#E5A300"
      />
      <path
        d="M37.4792 27.978C44.8594 35.3998 44.8594 47.4097 37.4792 54.8315C30.099 62.2533 18.1565 62.2533 10.7764 54.8315"
        fill="black"
        fillOpacity="0.05"
      />
      <path
        d="M44.3683 41.2939C44.3683 52.5018 35.3316 61.5877 24.1842 61.5877C13.0368 61.5877 4 52.5018 4 41.2939C4 30.0859 13.0368 21 24.1842 21C35.3316 21 44.3683 30.0859 44.3683 41.2939ZM8.10376 41.2939C8.10376 50.2231 15.3032 57.4616 24.1842 57.4616C33.0651 57.4616 40.2645 50.2231 40.2645 41.2939C40.2645 32.3646 33.0651 25.1261 24.1842 25.1261C15.3032 25.1261 8.10376 32.3646 8.10376 41.2939Z"
        fill="#FFC84B"
      />
      <path
        d="M32.7651 56.6873C32.9404 57.0017 32.8295 57.4007 32.5106 57.5655C30.0008 58.8632 27.2201 59.5519 24.3919 59.5723C21.5637 59.5927 18.7734 58.9441 16.2453 57.6828C15.9241 57.5226 15.8075 57.1252 15.9784 56.8083C16.1492 56.4913 16.5427 56.3748 16.8644 56.5342C19.1994 57.691 21.7736 58.2856 24.3826 58.2668C26.9916 58.248 29.557 57.6163 31.8753 56.426C32.1946 56.262 32.5898 56.3728 32.7651 56.6873Z"
        fill="#FFE09B"
      />
      <path
        d="M42.5243 36.2739C42.8256 36.1871 43.0006 35.8708 42.9056 35.5706C41.6784 31.692 39.2827 28.2866 36.0434 25.83C32.653 23.2588 28.5224 21.8666 24.2756 21.8638C20.0287 21.8609 15.8963 23.2476 12.5024 25.8143C9.25987 28.2665 6.85968 31.6686 5.62733 35.5456C5.53193 35.8457 5.70655 36.1622 6.00769 36.2494C6.30884 36.3365 6.62242 36.1614 6.71835 35.8614C7.88219 32.2225 10.1389 29.0294 13.1846 26.7261C16.3816 24.3083 20.2743 23.0021 24.2748 23.0048C28.2753 23.0075 32.1662 24.3189 35.3601 26.741C38.4027 29.0484 40.6552 32.2444 41.8142 35.885C41.9097 36.185 42.2231 36.3606 42.5243 36.2739Z"
        fill="#FFE09B"
      />
      <path
        d="M22.2641 53.1809C21.735 53.7355 20.865 53.7442 20.3253 53.2003L12.5068 45.3296C11.9628 44.7814 11.9646 42.4879 12.5108 41.942L14.4862 39.9676C15.0186 39.4355 15.8702 39.4368 16.401 39.9706L21.0049 45.9962C21.5462 46.5406 21.7349 45.8315 22.2629 45.2737L31.9979 33.1283C32.5259 32.5705 33.398 32.5595 33.9393 33.1039L35.8921 35.0675C36.4281 35.6064 36.437 38.4144 35.912 38.9646L22.2641 53.1809Z"
        fill="#C6881A"
      />
      <path
        d="M21.2302 50.9624C20.6976 51.5412 19.822 51.5503 19.2788 50.9827L11.4095 42.7685C10.8619 42.1963 10.8638 41.2598 11.4135 40.69L13.4017 38.6295C13.9376 38.0741 14.7947 38.0755 15.329 38.6326L19.2749 42.7356C19.8198 43.3038 20.6975 43.2923 21.229 42.7102L31.9699 32.0468C32.5014 31.4646 33.3792 31.4531 33.924 32.0213L35.8894 34.0706C36.4289 34.6331 36.4379 35.5515 35.9095 36.1257L21.2302 50.9624Z"
        fill="#FFD159"
      />
      <path
        d="M20.8741 47.711C20.5162 48.0911 19.9278 48.097 19.5628 47.7243L13.2751 41.3312C12.9072 40.9555 12.9085 40.3406 13.2779 39.9665L13.6138 39.6137C13.9739 39.249 14.5498 39.2499 14.9088 39.6157L19.5602 44.3096C19.9263 44.6826 20.5161 44.6751 20.8732 44.2929L32.0905 33.2917C32.4476 32.9094 33.0374 32.9019 33.4035 33.2749L33.7241 33.6204C34.0866 33.9897 34.0927 34.5928 33.7376 34.9698L20.8741 47.711Z"
        fill="#FFDF96"
      />
    </svg>
  );
}

export default MedalCheck;
