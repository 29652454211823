import React from 'react';

function Position({ position }) {
  function PositionNumber() {
    return <span className="gamified-position">{position}</span>;
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <PositionNumber />

      <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_76_10733)">
          <rect width="28" height="32" rx="8" fill="#C0C2C4" shapeRendering="crispEdges" />
          <rect x="2" y="2" width="24" height="28" rx="6" fill="#F7F7F7" />
          <g filter="url(#filter1_d_76_10733)"></g>
          <rect x="1.5" y="1.5" width="25" height="29" rx="6.5" stroke="#E1E5EC" strokeWidth="3" shapeRendering="crispEdges" />
        </g>
        <defs>
          <filter id="filter0_d_76_10733" x="0" y="0" width="28" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.801406 0 0 0 0 0.823622 0 0 0 0 0.8625 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10733" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10733" result="shape" />
          </filter>
          <filter
            id="filter1_d_76_10733"
            x="6.54614"
            y="9.80591"
            width="14.4651"
            height="13.1941"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10733" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10733" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default Position;
