import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import * as coreEntities from '_core/store/index';

const PrivacyPolicy = () => {
  const { t } = useTranslate();
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let privacyScope = scopes.filter((scope) => scope.scope === 'privacy');
      if (privacyScope && privacyScope.length > 0) {
        setPrivacy(privacyScope[0].json);
      }
    }
  }, [scopes]);

  return (
    <div style={{ padding: '40px' }}>
      <TextHtml text={privacy} />
    </div>
  );
};

export default PrivacyPolicy;
