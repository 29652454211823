export const DEFAULT_LANG = 'es';

export const NEED_DEMO_GROUP = false;

export const MAX_FILE_UPLOAD_SIZE = 200; // size in megabytes

export const tinyBirdToken =
  'p.eyJ1IjogIjNjYWY3MzhhLTBlNjMtNDNkZi04OGM0LWJiODA0ODQzMzQ3NiIsICJpZCI6ICI4NDdlMTMyMy1lZjAxLTQzODYtYjIzNS03ZDE3YTRmZmNlOTIiLCAiaG9zdCI6ICJldV9zaGFyZWQifQ.yQLl5GSMwMYe-ssL7WSrs56-SRFAb28pr32DcEGakyg';

export const clientIdGoogle = {
  'http://localhost:3001': '700159560438-v3t3abr2hmqghhvtskdhgmmbribppj89.apps.googleusercontent.com',
  'https://santillanarfp1.oneclick.es': '700159560438-v3t3abr2hmqghhvtskdhgmmbribppj89.apps.googleusercontent.com',
  'https://santillanarfp2.oneclick.es': '700159560438-v3t3abr2hmqghhvtskdhgmmbribppj89.apps.googleusercontent.com',
};

export const googleAPIkey = 'AIzaSyCrpnXTFZY-huoouZ0K2Jrumn-Th1mqrTA';

export const clientIdMicrosoft = {
  'http://localhost:3001': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineaws.tk': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineaws2.tk': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://tangerineqa1.tk': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://tangerineqa12.tk': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://tangerinedemo1.tk': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://tangerinedemo2.tk': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://tangerine.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://macmillan-dev.oneclicklabs.es': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://macmillan-qa.oneclicklabs.es': '03e8f4fd-54e6-47c3-a2bc-0f238c993a26',
  'https://digital.macmillaneducation.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://macmillan-prod.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://localhost:3005': '640d7b86-bc8c-4695-b923-16025e6cf019',
  'https://santillanarfp1.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
  'https://santillanarfp2.oneclick.es': '4b5cb50c-660c-404c-b485-bb494f437654',
};

export const clientIdAmplitude = {
  'http://localhost:3001': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://tangerine-dev1.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://alfa-santillana-dev.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://macmillan-dev.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://tangerine-latest.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://digital.macmillaneducation.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://macmillan-prod.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://alfa-santillana.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.cr': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.com.pa': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://proyectoalfa.santillana.com.ni': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://santillanarfp1.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://santillanarfp2.oneclick.es': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
};

export const tenants = {
  macmillan: 'MACMILLAN',
  tangerine: '83d6cd67-0eab-4ddc-900f-4ddb70dc0d3d',
  edelvives: 'EDELVIVES_DIGITAL_PLUS',
};

export const CONFIG = {
  googleKeys: {
    CLIENT_ID: '700159560438-v3t3abr2hmqghhvtskdhgmmbribppj89.apps.googleusercontent.com',
    DEVELOPER_KEY: 'AIzaSyCrpnXTFZY-huoouZ0K2Jrumn-Th1mqrTA',
    SCOPE: ['https://www.googleapis.com/auth/drive.readonly'],
  },
  isBooksGradebookAllowed: true,
  gradebookScopes: [
    {
      label: 'Workbook',
      value: 'toolbox',
    },
    {
      label: 'Program',
      value: 'program',
    },
  ],
};

export const defaultTimes = [
  {
    id: 'Annually',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
];

export const TEMPLATES = {
  VANILLA: 'vanilla',
  RETRO: 'retro',
  PRIMARY: 'primary-2022',
  SECONDARY: 'secondary-2022',
  PQLCO: 'secondary-pqlco',
  UNOI: 'unoi',
};

export const DEMO_GROUP_PAYLOAD = {
  limit_courses: 0,
  limit_courses_jobs: 0,
  name: 'Mi clase demo',
  students: [
    {
      username: 'student01@oneclick.es',
    },
  ],
  messages: [],
};
