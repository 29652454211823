import { useKidsTemplate } from '_core/hooks/useKidsTemplate';
import Bubble from '_core/modules/atoms/Bubble';
import Icon from '_core/modules/atoms/Icon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const HeaderTabs = ({ tabs, tabActive, onChangeTab, onDisableTab, typeView, align, isURLBased, themeColor }) => {
  const { isKidsTemplate } = useKidsTemplate();
  const [activeTabState, setActiveTabState] = useState(tabActive);
  const [hasChangedPath, setHasChangedPath] = useState(false);
  const [tabToChangeTo, setTabToChangeTo] = useState(tabs[0]);
  const [backgroundColor, setBackgroundColor] = useState({});

  const history = useHistory();

  useEffect(() => {
    let style = {};
    if (isKidsTemplate) {
      if (themeColor) {
        style = { backgroundColor: themeColor };
      } else {
        style = { backgroundColor: '#e0e0e0' };
      }
    }
    setBackgroundColor(style);
  }, [isKidsTemplate]);

  useEffect(() => {
    return history.listen(() => {
      setHasChangedPath(true);
    });
  }, [history]);

  function onClickChangeTab(tab) {
    if (isURLBased) {
      setTabToChangeTo(tab);
    } else {
      if (!tab.disabled) setActiveTabState(tab.id);
      else onDisableTab(tab.id);
    }
    if (tab.onClick) {
      tab.onClick();
    }
  }

  useEffect(() => {
    if (hasChangedPath && isURLBased && tabToChangeTo) {
      setActiveTabState(tabToChangeTo.id);
      setHasChangedPath(false);
    }
  }, [hasChangedPath]);

  useEffect(() => {
    if (onChangeTab) {
      onChangeTab(activeTabState);
    }
  }, [activeTabState]);

  useEffect(() => {
    setActiveTabState(tabActive);
  }, [tabActive]);

  function renderText(text, icon, iconKids, active) {
    return (
      <div className={`header-tabs__tab-text-container `}>
        {icon && (
          <div className="header-tabs__tab-icon">
            <Icon type={icon} color={active ? 'primary' : 'default'} />
          </div>
        )}
        {iconKids && isKidsTemplate && (
          <div className="header-tabs__tab-icon">
            <Icon type={iconKids} color={active ? 'primary' : 'default'} />
          </div>
        )}
        <span className="header-tabs__tab-text">{text}</span>
      </div>
    );
  }

  return (
    <div className={clsx('header-tabs', `header-tabs__type-view-${typeView}`, `header-tabs__align-${align}`)}>
      {tabs.map((tab) => (
        <div
          key={Math.random()}
          onClick={() => {
            onClickChangeTab(tab);
          }}
          className={clsx('header-tabs__tab', `header-tabs__tab--id-${tab.id}`, {
            'header-tabs__tab--active': tab.id === activeTabState,
            'header-tabs__tab--with-icon': tab.icon,
            'header-tabs__tab--with-icon-kids': tab.iconKids && isKidsTemplate,
            // [`background-color-${tab.discipline}`]: tab.discipline && isKidsTemplate && tab.id === activeTabState,
            'header-tabs__tab--disabled': tab.disabled,
          })}
          style={tab.id === activeTabState ? backgroundColor : {}}
          title={tab.name}
        >
          {tab.bubble && (
            <div className="header-tabs__bubble">
              <Bubble />
            </div>
          )}
          {tab.to && <Link to={tab.to}>{renderText(tab.name, tab.icon, tab.iconKids, tab.id === activeTabState)}</Link>}
          {!tab.to && renderText(tab.name, tab.icon, tab.iconKids, tab.id === activeTabState)}
        </div>
      ))}
    </div>
  );
};

HeaderTabs.propTypes = {
  tabs: PropTypes.array, // tabs: [{id: 1, name: "primera Tab"}]
  tabActive: PropTypes.string,
  onChangeTab: PropTypes.func,
  typeView: PropTypes.oneOf(['default', 'border', 'background', 'background-round', 'vertical']),
  align: PropTypes.oneOf(['default', 'left', 'right']),
  isURLBased: PropTypes.bool,
};

HeaderTabs.defaultProps = {
  tabs: [],
  align: 'default',
  typeView: 'default',
  isURLBased: false,
  onDisableTab: () => {},
};

export default HeaderTabs;
