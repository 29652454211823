import ArrowOrange from '../assets/img/arrow-orange.svg';
import coverTestBlocked from '../assets/img/cover-test/cover-blocked.png';
import coverTestConfirmation from '../assets/img/cover-test/cover-confirmation.png';
import coverTestDefault from '../assets/img/cover-test/cover-default.png';
import coverTestStarted from '../assets/img/cover-test/cover-started.png';
import coverTestFormativeDueDate from '../assets/img/cover-test/img_task_duedate.png';
import coverTestFormative from '../assets/img/cover-test/img_task_start.png';
import DefaultUser from '../assets/img/default/default-user.png';
import VideoContentPreview from '../assets/img/default/default-video-preview.jpeg';
import imageLayoutProgress from '../assets/img/image-layout-progress.jpeg';
import LogoBig from '../assets/img/logo/logo-big.png';
import LogoIconS from '../assets/img/logo/logo-icon-s.png';
import NoCourses from '../assets/img/no-items/no-courses.png';
import NoGroups from '../assets/img/no-items/no-groups.png';
import NoItem from '../assets/img/no-items/no-item.png';
import NoPosts from '../assets/img/no-items/no-posts.png';
import NoQuestionsNew from '../assets/img/no-items/no-questions-new.png';
import NoQuestions from '../assets/img/no-items/no-questions.png';
import NoTasks from '../assets/img/no-items/no-tasks.png';
import NoUsers from '../assets/img/no-items/no-users.png';
import Signup2 from '../assets/img/signup/img01.png';
import Signup3 from '../assets/img/signup/img02.png';
import Signup4 from '../assets/img/signup/img03.png';
import Signup5 from '../assets/img/signup/img06.png';
import Signup1 from '../assets/img/signup/ipad.png';

export default {
  getImage: (id) => {
    let componentImage;

    switch (id) {
      case 'logoBig':
        componentImage = LogoBig;
        break;

      case 'logoIconS':
        componentImage = LogoIconS;
        break;

      case 'noCourses':
        componentImage = NoCourses;
        break;

      case 'noGroups':
        componentImage = NoGroups;
        break;

      case 'noItem':
        componentImage = NoItem;
        break;

      case 'noPosts':
        componentImage = NoPosts;
        break;

      case 'noQuestions':
        componentImage = NoQuestions;
        break;

      case 'noQuestionsNew':
        componentImage = NoQuestionsNew;
        break;

      case 'defaultUser':
        componentImage = DefaultUser;
        break;

      case 'videoContentPreview':
        componentImage = VideoContentPreview;
        break;

      case 'login':
        componentImage = Signup1;
        break;

      case 'signup1':
        componentImage = Signup1;
        break;

      case 'signup2':
        componentImage = Signup2;
        break;

      case 'signup3':
        componentImage = Signup3;
        break;

      case 'signup4':
        componentImage = Signup4;
        break;

      case 'signup5':
        componentImage = Signup5;
        break;

      case 'noTasks':
        componentImage = NoTasks;
        break;

      case 'noUsers':
        componentImage = NoUsers;
        break;

      case 'noPictureProgram':
        componentImage = NoItem;
        break;

      case 'arrow-orange':
        componentImage = ArrowOrange;
        break;

      case 'cover-test-started':
        componentImage = coverTestStarted;
        break;

      case 'cover-test-blocked':
        componentImage = coverTestBlocked;
        break;

      case 'cover-test-default':
        componentImage = coverTestDefault;
        break;

      case 'cover-test-confirmation':
        componentImage = coverTestConfirmation;
        break;

      case 'cover-test-formative':
        componentImage = coverTestFormative;
        break;

      case 'cover-test-formative-late':
        componentImage = coverTestFormativeDueDate;
        break;

      case 'image-layout-progress':
        componentImage = imageLayoutProgress;
        break;

      default:
        componentImage = LogoBig;
        break;
    }

    return componentImage;
  },
};
