export const timesUnitIds = {
  annually: 'Annually',
  semiannually: 'Semi-annually',
  quarterly: 'Quarterly',
  bimonthly: 'Bimonthly',
};

export const timesUnitLenthIds = {
  1: 'Annually',
  2: 'Semi-annually',
  3: 'Quarterly',
  4: 'Bimonthly',
};

export const timesUnit = [
  { name: timesUnitIds.annually, periods: [{ name: 'School year', id: 'Annually' }] },
  {
    name: timesUnitIds.semiannually,
    periods: [
      { name: 'Semester 1', id: 'semester1' },
      { name: 'Semester 2', id: 'semester2' },
    ],
  },
  {
    name: timesUnitIds.quarterly,
    periods: [
      { name: 'Quarter 1', id: 'quarter1' },
      { name: 'Quarter 2', id: 'quarter2' },
      { name: 'Quarter 3', id: 'quarter3' },
    ],
  },
  {
    name: timesUnitIds.bimonthly,
    periods: [
      { name: 'Bimester 1', id: 'bimester1' },
      { name: 'Bimester 2', id: 'bimester2' },
      { name: 'Bimester 3', id: 'bimester3' },
      { name: 'Bimester 4', id: 'bimester4' },
    ],
  },
];

export const typeContentsIcons = {
  CTTY_01: 'pdf',
  CTTY_03: 'video',
  CTTY_04: 'audio',
  CTTY_05: 'pdf',
  CTTY_06: 'link-content',
  CTTY_07: 'image',
  CTTY_08: 'task2',
  CTTY_09: 'task',
  CTTY_10: 'task2',
  CTTY_13: 'html-interactive',
  CTTY_12: 'pdf',
  CTTY_20: 'xapi',
  CTTY_VIDEOLESSON: 'videolesson',
  questionary: 'questionary',
  test: 'questionary',
  videolesson: 'videolesson',
  CTTY_14: 'task2',
  audiorecorder: 'audio',
  uploadfile: 'pdf',
  longtextV2: 'task2',
  video: 'video',
};

export const typeContentNames = {
  CTTY_03: 'Video',
  CTTY_04: 'Audio',
  CTTY_05: 'PDF',
  CTTY_06: 'Link',
  CTTY_07: 'Image',
  CTTY_08: 'Task',
  CTTY_09: 'Quizz',
  CTTY_10: 'Scorm',
  CTTY_13: 'Interactive',
  CTTY_12: 'PDF',
  CTTY_20: 'XAPI',
  CTTY_VIDEOLESSON: 'Videolesson',
  CTTY_14: 'Task',
  CTTY_LTI: 'LTI Content',
};

export const CONTENT_TYPES = {
  VIDEO: 'CTTY_03',
  LINK: 'CTTY_06',
  IMAGE: 'CTTY_07',
  MINT: 'CTTY_MINTBLOCK',
  OFFICE: 'CTTY_12',
  PDF: 'CTTY_05',
  TEST: 'CTTY_09',
  QUESTION: 'CTTY_14',
  VIDEOLESSON: 'CTTY_VIDEOLESSON',
  XAPI: 'CTTY_20',
  SCORM: 'CTTY_10',
  LTI: 'CTTY_LTI',
};

export const LTI_CONTENT_TYPES = {
  AUDIO: 'audio',
  DOCUMENT: 'document',
  VIDEO: 'video',
  HTML: 'html',
  PDF: 'pdf',
  PPT: 'ppt',
  EXCEL: 'excel',
  DOCX: 'docx',
};

export const CONTENT_TYPE_NAMES = {
  FIXED_LAYOUT: 'Fixed Layout',
  RETRO_BOOK: 'Retro book',
  MINT_LESSON: 'Mint Lesson',
};

export function getIdTypeContent(id) {
  let idValue = '';
  for (let type in typeContentsIcons) {
    if (typeContentsIcons[type] === id) {
      idValue = type;
      break;
    }
  }

  return idValue;
}

export const lessonType = {
  online: {
    text: 'Online',
    icon: 'online',
    value: 'online',
  },
  onsite: {
    text: 'Onsite',
    icon: 'onsite',
    value: 'onsite',
  },
};

export const weekdays = [
  { name: 'monday', id: 'monday' },
  { name: 'tuesday', id: 'tuesday' },
  { name: 'wednesday', id: 'wednesday' },
  { name: 'thursday', id: 'thursday' },
  { name: 'friday', id: 'friday' },
  { name: 'saturday', id: 'saturday' },
  { name: 'sunday', id: 'sunday' },
];

export const devicesIcon = {
  desktop: 'desktop',
  tablet: 'tablet',
  smartphone: 'device',
};

export const licenseType = {
  personal: 'LICENSE_PERSONAL',
  school: 'SCHOOL_GROUP',
};

export const role = {
  student: 'R01',
  teacher: 'R02',
};

export const studentStreamType = {
  private: 'private',
  onlyComment: 'onlyComment',
  postAndComment: 'postAndComment',
};

export const EMBED_FROM = {
  program: 'program',
  unit: 'unit',
  lesson: 'lesson',
  activity: 'activity',
};

export const CLIENT = {
  TANGERINE: 'TANGERINE',
  EDELVIVES: 'EDELVIVES_DIGITAL_PLUS',
  MACMILLAN: 'MACMILLAN',
  SANTILLANA_ALFA: 'SANTILLANA_ALFA',
  SANTILLANA_UNOI: 'SANTILLANA_UNOI',
};

export const LESSON_TYPES_IDS = {
  TEACHER: 'teacher',
  MINT: 'mint',
  POWER: 'power',
};

export const LESSON_TYPES = [
  {
    name: 'lesson:Lite',
    id: LESSON_TYPES_IDS.TEACHER,
  },
  {
    name: 'lesson:Mint',
    id: LESSON_TYPES_IDS.MINT,
  },
  {
    name: 'Pro',
    id: LESSON_TYPES_IDS.POWER,
  },
];

export const AMPLITUDE_EVENT = {
  START: 'START',
  SEND_MESSAGE: 'SEND MESSAGE',
  SEND_COMMENT: 'SEND COMMENT',
  PROJECT_LESSON: 'PROJECT LESSON',
  REMOTE_DEVICE_CONNECTED: 'REMOTE DEVICE CONNECTED',
  PERSONALIZE_PROGRAM: 'PERSONALIZE PROGRAM',
  EDIT_EDITORIAL_LESSON: 'EDIT EDITORIAL LESSON',
  CREATE_NEW_LESSON: 'CREATE NEW LESSON',
  CREATE_NEW_UNIT: 'CREATE NEW UNIT',
  REVIEW_TASK: 'REVIEW TASK',
  ASSESSMENT_FEEDBACK: 'ASSESSMENT FEEDBACK',
  OPEN_CONTENT: 'OPEN CONTENT',
  SET_DELIVER_DATE: 'SET DELIVER DATE',
  OPEN_GRADES: 'OPEN GRADES',
  MINT_HIGHLIGHT: 'MINT HIGHLIGHT',
  SHARE_GOOGLE_CLASSROOM: 'SHARE ON GOOGLE CLASSROOM',
  ANSWER_ACTIVITY: 'ANSWER ACTIVITY',
  DOWNLOAD_DESKTOP_APP: 'DOWNLOAD DESKTOP APP',
  OPEN_TODO: 'OPEN TODO',
  OPEN_IMMERSIVE_READER: 'OPEN IMMERSIVE READER',
  OPEN_LESSON: 'OPEN LESSON',
  HIGHLIGHTER: 'HIGHLIGHTER',
  STUDENTS_VIEW_RESULTS: 'STUDENTS VIEW RESULTS',
  HOME_LIBRARY: 'HOME LIBRARY',
  HOME_CLASSROOM: 'HOME CLASSROOM',
  OPEN_COURSE: 'OPEN COURSE',
  CHANGE_LANGUAGE: 'CHANGE LANGUAGE',
  CREATE_MATERIAL: 'CREATE MATERIAL',
  CREATE_ACTIVITY: 'CREATE ACTIVITY',
  ADD_ACTIVITY_FROM_LIBRARY: 'ADD ACTIVITY FROM LIBRARY',
  ADD_MINT_BLOCK: 'ADD MINT BLOCK',
  CREATE_VIDEOLESSON: 'CREATE VIDEOLESSON',
  CREATE_EVALUATION: 'CREATE EVALUATION',
  PAGINATION_OPEN_WIDGET: 'OPEN PAGINATION WIDGET',
  PAGINATION_GO_TO_PAGE: 'PAGINATION GO TO PAGE',
  PAGINATION_OPEN_BOOK: 'PAGINATION OPEN BOOK',
};

export const APP_LINKS = {
  WINDOWS: 'https://releases-apps.s3.amazonaws.com/edv-electron/Edelvives-Digital-Plus+Setup+1.2.1.exe',
  LINUX: 'https://releases-apps.s3.amazonaws.com/edv-electron/Edelvives-Digital-Plus_1.2.1_amd64.deb',
  MAC: 'https://releases-apps.s3.amazonaws.com/edv-electron/Edelvives-Digital-Plus-1.2.1.dmg',
};

export const STUDENT_AGES = {
  ey1: 3,
  ey2: 4,
  ey4: 6,
  ey5: 7,
  ey6: 8,
  ey7: 9,
  ey8: 10,
  ey9: 11,
  ey10: 12,
  ey11: 13,
  ey12: 14,
  ey13: 15,
  ey14: 16,
  ey15: 17,
  'ey-es-01': 3,
  'ey-es-02': 4,
  'ey-es-03': 5,
  'ey-es-04': 6,
  'ey-es-05': 7,
  'ey-es-06': 8,
  'ey-es-07': 9,
  'ey-es-08': 10,
  'ey-es-09': 11,
  'ey-es-10': 12,
  'ey-es-11': 13,
  'ey-es-12': 14,
  'ey-es-13': 15,
  'ey-es-90': 16,
  'ey-es-91': 17,
  'ey-ar-01': 3,
  'ey-ar-02': 4,
  'ey-ar-03': 5,
  'ey-ar-04': 6,
  'ey-ar-05': 7,
  'ey-ar-06': 8,
  'ey-ar-07': 9,
  'ey-ar-08': 10,
  'ey-ar-09': 11,
  'ey-ar-10': 12,
  'ey-ar-11': 13,
  'ey-ar-12': 14,
  'ey-ar-13': 15,
  'ey-ar-90': 16,
  'ey-ar-91': 17,
};

export const AI_ASSISTANT_SCOPE = {
  HOME: 'HOME',
  COURSE: 'COURSE',
  LESSON: 'LESSON',
  XAPI: 'XAPI',
};

export const AI_ASSISTANT_DIALOG_TYPE = {
  TEXT: 'TEXT',
  QUESTION: 'QUESTION',
};
export const VIDEOLESSON_EMPTY = {
  id: '',
  url: '',
  thumbnailUrl: '',
  duration: '',
  title: '',
  type: '',
  sections: [],
  references: [],
  questions: {},
  preferences: { allowSkipAhead: true, allowRepeatQuestion: true },
};

export const COMETCHAT_CONSTANTS = {
  APP_ID: '248471f8512d331c', //Replace with your App ID
  REGION: 'eu', //Replace with your App Region
  AUTH_KEY: '862001767374f67591efcff818f603fcf1a41932', //Replace with your Auth Key
};

export const VIDEOLESSON_DUMMY = {
  id: '69300081-2aa8-4d47-9463-225b466a8b0d',
  url: 'https://www.youtube.com/watch?v=6Jfk8ic3KVk',
  thumbnailUrl: '',
  duration: 29426,
  title: 'Título de la Videolección',
  type: '',
  sections: [
    {
      start: 4461,
      end: 6206,
    },
  ],
  references: [
    {
      id: '6e6d41c2-aadd-4d60-83fe-37e5e0ae94fe',
      reference: 'fbac1748-1a17-4857-bc45-f2e2b38ff000',
      type: 'mcq',
      template: 'Multiple choice – multiple response',
      time: 4851,
    },
    {
      id: 'ac3f6751-a4d7-4f2b-9ed1-b233299a7fe0',
      reference: '13353580-8e9d-41ba-a46f-6015a6febd4a',
      type: 'mcq',
      template: 'Multiple choice – multiple response',
      time: 4941,
    },
    {
      id: '930df19c-1029-4cce-aa57-449f9cb11378',
      reference: '5e694b3e-bf48-4369-b175-04b44f0e4b94',
      type: 'mcq',
      template: 'Multiple choice – multiple response',
      time: 5699,
    },
  ],
  preferences: {
    allowSkipAhead: true,
    allowRepeatQuestion: true,
  },
  questions: {
    'fbac1748-1a17-4857-bc45-f2e2b38ff000': {
      title: 'Internal name 2',
      type: 'mcq',
      data: {
        type: 'mcq',
        multiple_responses: true,
        options: [
          {
            label: '<p>Praga</p>',
            value: '0',
            feedback: '',
          },
          {
            label: '<p>Paris</p>',
            value: '1',
            feedback: '',
          },
          {
            label: '<p>Barcelona</p>',
            value: '2',
            feedback: '',
          },
          {
            label: '<p>Madrid</p>',
            value: '3',
            feedback: '',
          },
        ],
        stimulus: '<p>Selecciona las ciudades de España</p>',
        shuffle_options: true,
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: ['0', '2'],
          },
          penalty: 0,
        },
        ui_style: {
          type: 'Multiple choice – multiple response',
          columns: 1,
          labelType: '',
          customClass: '',
          inlineFeedback: false,
        },
        isEvaluable: true,
        attempts: {
          attempts: 0,
        },
        hints: [],
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          config: {
            visibility: true,
            sequence: 'stack',
          },
        },
        showCheckIcon: true,
      },
      metadata: {
        name: 'Multiple choice – multiple response',
      },
      reference: 'fbac1748-1a17-4857-bc45-f2e2b38ff000',
      extraValues: {},
    },
    '5e694b3e-bf48-4369-b175-04b44f0e4b94': {
      title: 'Internal name 1',
      type: 'mcq',
      data: {
        type: 'mcq',
        multiple_responses: true,
        options: [
          {
            label: '<p>Avión</p>',
            value: '0',
            feedback: '',
          },
          {
            label: '<p>Perro</p>',
            value: '1',
            feedback: '',
          },
          {
            label: '<p>Gato</p>',
            value: '2',
            feedback: '',
          },
          {
            label: '<p>Coche</p>',
            value: '3',
            feedback: '',
          },
        ],
        stimulus: '<p>Selecciona los animales</p>',
        shuffle_options: true,
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: ['0', '2'],
          },
          penalty: 0,
        },
        ui_style: {
          type: 'Multiple choice – multiple response',
          columns: 1,
          labelType: '',
          customClass: '',
          inlineFeedback: false,
        },
        isEvaluable: true,
        attempts: {
          attempts: 0,
        },
        hints: [],
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          config: {
            visibility: true,
            sequence: 'stack',
          },
        },
        showCheckIcon: true,
      },
      metadata: {
        name: 'Multiple choice – multiple response',
      },
      reference: '5e694b3e-bf48-4369-b175-04b44f0e4b94',
      extraValues: {},
    },
    '13353580-8e9d-41ba-a46f-6015a6febd4a': {
      title: 'Internal name 1',
      type: 'mcq',
      data: {
        type: 'mcq',
        multiple_responses: true,
        options: [
          {
            label: '<p>Avión</p>',
            value: '0',
            feedback: '',
          },
          {
            label: '<p>Perro</p>',
            value: '1',
            feedback: '',
          },
          {
            label: '<p>Gato</p>',
            value: '2',
            feedback: '',
          },
          {
            label: '<p>Coche</p>',
            value: '3',
            feedback: '',
          },
        ],
        stimulus: '<p>Selecciona los animales</p>',
        shuffle_options: true,
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: ['0', '2'],
          },
          penalty: 0,
        },
        ui_style: {
          type: 'Multiple choice – multiple response',
          columns: 1,
          labelType: '',
          customClass: '',
          inlineFeedback: false,
        },
        isEvaluable: true,
        attempts: {
          attempts: 0,
        },
        hints: [],
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          config: {
            visibility: true,
            sequence: 'stack',
          },
        },
        showCheckIcon: true,
      },
      metadata: {
        name: 'Multiple choice – multiple response',
      },
      reference: '13353580-8e9d-41ba-a46f-6015a6febd4a',
      extraValues: {},
    },
  },
};

export const TEMPLATE_TYPES = {
  VANILLA: 'vanilla',
  KIDS: 'kids',
};

export const ONBOARDING_STEPS = {
  program: [
    {
      title: 'Edit lessons',
      description: 'Adjust content to your needs. Add text, audiovisual or interactive boxes; move them or modify their size and color',
      image: window.location.origin + '/assets/onboarding/programa1.gif',
    },
    {
      title: 'Add related material',
      description: 'Complement the lessons with PDFs, images, videos or any related content',
      image: window.location.origin + '/assets/onboarding/programa2.gif',
    },
    {
      title: 'Design tasks and quizzes',
      description:
        'Link specific tasks (activities, video lessons, essays, etc.) to different lessons or create test assessments to review what you have studied',
      image: window.location.origin + '/assets/onboarding/programa3.gif',
    },
  ],
  home: [
    {
      title: 'Access your content',
      description: "Redeem licenses for digital programs or join another teacher's class to co-teach",
      image: window.location.origin + '/assets/onboarding/home1.gif',
    },
    {
      title: 'Create a class and share it with your students',
      description: 'Create a class with all the programs you need and share the class code with your students so they can join it',
      image: window.location.origin + '/assets/onboarding/home2.gif',
    },
    {
      title: 'Check your tasks, notifications and access the calendar',
      description:
        'Access the tasks submitted by your students to correct them, the discussion threads about materials or tasks and your planning for all the courses',
      image: window.location.origin + '/assets/onboarding/home3.gif',
    },
  ],
  calendar: [
    {
      title: 'Personalise your view',
      description: 'Choose how you want to view your calendar, weekly or monthly, and hide or show classes according to your needs',
      image: window.location.origin + '/assets/onboarding/calendar1.gif',
    },
    {
      title: 'Move events in the calendar and add holidays',
      description: "Configure your class schedule as needed, move sessions or add non-teaching days, everything will be reflected in your students' calendar",
      image: window.location.origin + '/assets/onboarding/calendar2.gif',
    },
    {
      title: 'Sync your calendar with Google',
      description: 'Sync your calendar to be able to view events in Google Calendar and create video conferences with your students',
      image: window.location.origin + '/assets/onboarding/calendar3.gif',
    },
  ],
};
