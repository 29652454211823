import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_HISTORY, REDUCER_HISTORY } from './conf';

const actionTypes = {
  SetHistory: 'history/SET_HISTORY',
  CleanHistory: 'history/CLEAN_HISTORY',
  SetHomeTab: 'history/SET_HOME_TAB',
  SetTasksTab: 'history/SET_TASKS_TAB',
  SetDeeplink: 'history/SET_DEEPLINK',
  SetGrades: 'history/SET_GRADES',
  SetFrom: 'history/SET_FROM',
  SetPowerTab: 'history/SET_POWER_TAB',
};

const initialState = {
  history: {},
  deeplink: null,
  from: null,
  home: {
    tab: 'classrooms',
  },
  tasks: {
    tab: 0, //pending o published
  },
  grades: [],
  powerTab: 'content',
};

export const reducer = persistReducer({ storage, key: PERSIST_HISTORY }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetHistory:
      return { ...state, history: { ...state.history, ...action.payload } };

    case actionTypes.CleanHistory:
      return { ...state, history: {}, from: null };

    case actionTypes.SetHomeTab: {
      return { ...state, home: { ...state.home, tab: action.payload } };
    }

    case actionTypes.SetTasksTab: {
      return { ...state, tasks: { ...state.tasks, tab: action.payload } };
    }

    case actionTypes.SetDeeplink: {
      return { ...state, deeplink: action.payload };
    }

    case actionTypes.SetGrades: {
      let { courseGuid, unitGuid } = action.payload;
      let grades = [];
      grades = state.grades;
      if (grades.length > 0 && grades.findIndex((item) => item.courseGuid === courseGuid) !== -1) {
        grades = grades.map((item) => (item.courseGuid === courseGuid ? { courseGuid, unitGuid } : item));
      } else {
        grades = [...grades, { courseGuid, unitGuid }];
      }
      return { ...state, grades: grades };
    }

    case actionTypes.SetFrom: {
      return { ...state, from: action.payload };
    }

    case actionTypes.SetPowerTab: {
      return { ...state, powerTab: action.payload };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
});

export const actions = {
  setHistory: (payload) => ({ type: actionTypes.SetHistory, payload }),
  cleanHistory: () => ({ type: actionTypes.CleanHistory }),
  setHomeTab: (payload) => ({ type: actionTypes.SetHomeTab, payload }),
  setTasksTab: (payload) => ({ type: actionTypes.SetTasksTab, payload }),
  setDeeplink: (payload) => ({ type: actionTypes.SetDeeplink, payload }),
  setGrades: (payload) => ({ type: actionTypes.SetGrades, payload }),
  setFrom: (payload) => ({ type: actionTypes.SetFrom, payload }),
  setPowerTab: (payload) => ({ type: actionTypes.SetPowerTab, payload }),
};

export const selectors = {
  getHistory: (state) => {
    return state[REDUCER_HISTORY].history;
  },
  getHomeTab: (state) => {
    return state[REDUCER_HISTORY].home.tab;
  },
  getDeeplink: (state) => {
    return state[REDUCER_HISTORY].deeplink;
  },
  getGrades: (state) => {
    return state[REDUCER_HISTORY].grades;
  },
  getTasksTab: (state) => {
    return state[REDUCER_HISTORY].tasks.tab;
  },
  getFrom: (state) => {
    return state[REDUCER_HISTORY].from;
  },
  getPowerTab: (state) => {
    return state[REDUCER_HISTORY].powerTab;
  },
};
