/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import * as microsoftTeams from '@microsoft/teams-js';
import { LayoutContextProvider, changeFavicon, frontoffice, i18next } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import * as routerHelpers from '_core/utils/RouterHelpers';
import { initAssistant, modifyUser } from '_core/utils/aiAssistant/aiAssistant';
import { initChat } from '_core/utils/chat/chat';
import amplitude from 'amplitude-js';
import resources from 'app/translations/resources';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { notificationsTypes } from '../../_core/utils/notifications';
import { defaultConfig } from '../config/environment';
import LoginPage from '../pages/LoginPage/LoginPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TabAuthEnd from '../pages/Teams/TabAuthEnd';
import TabAuthStart from '../pages/Teams/TabAuthStart';
import TermsOfUse from '../pages/TermsOfUse';
import SamlCallback from '../pages/saml/SamlCallback';
import SamlError from '../pages/saml/SamlError';
import { isEditorial } from '../utils/users';
import { isInPublisher } from '../utils/utils';

import { isTeams } from '_core/utils/teamsTab';
import { isStudent, isTeacher } from '_core/utils/user';
import { initUserForTracking } from '_core/utils/userEvents';
import { CLIENT } from '_core/variables/constant';
import { clientIdAmplitude } from 'app/config/config';
import clsx from 'clsx';

const ErrorView = React.lazy(() => import('app/modules/views/ErrorView/ErrorView'));
const NotFound = React.lazy(() => import('app/pages/ContentNotFoundPage/ContentNotFoundPage'));
const BarMenuContainer = React.lazy(() => import('app/containers/BarMenuContainer'));
const HomePage = React.lazy(() => import('app/pages/HomePage/HomePage'));
const CoursePage = React.lazy(() => import('app/pages/CoursePage/CoursePage'));
const LessonPage = React.lazy(() => import('app/pages/LessonPage/LessonPage'));
const TasksStudentView = React.lazy(() => import('app/modules/views/TasksView/TasksStudentView/TasksStudentView'));
const DeepLinkView = React.lazy(() => import('_core/lite/views/DeepLinkView'));
const AddLicense = React.lazy(() => import('app/modules/views/AddLicenseView/AddLicenseView'));
const TasksView = React.lazy(() => import('app/modules/views/TasksView/TasksView'));
const LogoutPage = React.lazy(() => import('../modules/containers/Logout'));
const ToolsProjectContainer = React.lazy(() => import('_core/lite/views/ToolsProjectView/ToolsProjectContainer'));
const AnswerTest = React.lazy(() => import('../pages/AnswerTest'));
const AnswerVideolesson = React.lazy(() => import('../pages/AnswerVideolesson/AnswerVideolesson'));
// const AssessmentResult = React.lazy(() => import('../pages/AssessmentResult'));
const AssessmentResult = React.lazy(() => import('../modules/views/CustomAssessmentResultsView/CustomAssessmentResultsView'));
const AssessmentResultMint = React.lazy(() => import('../pages/AssessmentResultMint'));
const VideolessonResultsPage = React.lazy(() => import('../pages/VideolessonResults/VideolessonResults'));
const AssessmentResultIndividualStudent = React.lazy(() => import('../pages/AssessmentResultIndividual'));
const AssessmentResultIndividual = React.lazy(() => import('../modules/views/CustomAssessmentResultsView/CustomAssessmentResultsView'));
const AssessmentResult2 = React.lazy(() => import('../pages/AssessmentResultToMock'));
const AuthPage = React.lazy(() => import('../pages/auth'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));
const CalendarPage = React.lazy(() => import('../pages/CalendarPage/CalendarPage'));
const LemonTest = React.lazy(() => import('../pages/LemonTest'));
const NewAssessment = React.lazy(() => import('../pages/NewAssessment'));
const NewContentView = React.lazy(() => import('../pages/NewContent'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const ProjectionMint = React.lazy(() => import('../pages/ProjectionMint'));
const Projection = React.lazy(() => import('../pages/Projection'));
const RemotePage = React.lazy(() => import('../pages/RemotePage'));
const Viewer = React.lazy(() => import('../pages/viewers/ViewersContainer'));
const ScormResult = React.lazy(() => import('../pages/ScormResultPage/ScormResultPage'));
const XapiResult = React.lazy(() => import('../pages/XapiResultsPage/XapiResultsPage'));
const ScormIndividualResult = React.lazy(() => import('../pages/ScormIndividualResultPage/ScormIndividualResultPage'));
const XapiIndividualResult = React.lazy(() => import('../pages/XapiIndividualResultPage/XapiIndividualResultPage'));
const IndividualActivityPage = React.lazy(() => import('../pages/IndividualActivityPage/IndividualActivityPage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));
const TestComponentView = React.lazy(() => import('app/modules/views/TestComponentView/TestComponentView'));
const MSTeamsConfiguration = React.lazy(() => import('../pages/MSTeamsConfiguration.js'));
const UiPage = React.lazy(() => import('../pages/UiPage/UiPage'));
const VideolessonPage = React.lazy(() => import('app/pages/VideolessonPage/VideolessonPage'));
const VideolessonRedirect = React.lazy(() => import('_core/lite/views/VideolessonRedirect'));
const ClassroomUsersPage = React.lazy(() => import('../pages/ClassroomUsersPage/ClassroomUsersPage'));

export const APP_IS_PUBLISHER = isInPublisher(defaultConfig) || false;
// http://localhost:3005/auth/login/{token}}/deeplink?link=course&38021190-17e6-11ed-a395-0f156a9b1207&program
let callbackToken = null;

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();

  const [teamsContentUrl, setTeamsContentUrl] = useState('');
  const [isTeamsDark, setIsTeamsDark] = useState(false);

  const theme = useSelector((store) => frontoffice.ui.selectors.getTheme(store));
  const userRoleGuid = useSelector((state) => auth.selectors.getUserRoleGuid(state));
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const user = useSelector((state) => auth.selectors.getUser(state));
  const oauthAccounts = useSelector((state) => auth.selectors.getOauthAccounts(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  const template = useSelector((state) => coreEntities.ui.selectors.getTemplate(state));

  checkIfCallbackTokenExists();

  useEffect(() => {
    initAssistant(user, 'TANGERINE');
  }, []);

  useEffect(() => {
    modifyUser(user);
    if (user?.guid) {
      initUserForTracking(user);
      initChat(user);
    }
  }, [user]);

  const { isAuthorized, roleUser, hasAuthToken, userLastLocation, license } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      hasAuthToken: Boolean(auth.authToken),
      roleUser: (auth.user && auth.user.role_guid) || null,
      userLastLocation: routerHelpers.getLastLocation(),
      license: auth.license,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      checkApiHealth();
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
    }
    dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country: user?.country_guid }));
    dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    dispatch(frontoffice.langs.actions.getLangScopes());
  }, [isAuthorized]);

  useEffect(() => {
    if (!lms) dispatch(coreEntities.organization.actions.getOrganization());
  }, [lms]);

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      setAppNameAndFavicon();
    }
  }, [scopes]);

  useEffect(() => {
    if (client && isAuthorized) {
      let account = oauthAccounts?.filter((acc) => acc.provider !== '');
      let amplitudeKey = clientIdAmplitude[window.location.origin];
      let schoolGuid = null;
      let schoolName = null;
      if (user.schools && user.schools.length > 0) {
        schoolGuid = user.schools[0].guid;
        schoolName = user.schools[0].name;
      }
      amplitude.getInstance().init(amplitudeKey, user.guid);
      amplitude.getInstance().setUserProperties({
        client: client,
        role: user.role_name,
        source: isTeams() ? 'MS Teams' : user.source,
        provider: account && account.length > 0 ? account[0].provider : 'user and pass',
        schoolGuid: schoolGuid,
        schoolName: schoolName,
      });
    }
  }, [client, isAuthorized]);

  useEffect(() => {
    if (isTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.getContext().then((context) => {
          setIsTeamsDark(context.app.theme === 'dark');
        });
        microsoftTeams.pages.getConfig().then((pageConfig) => {
          if (pageConfig.contentUrl) {
            setTeamsContentUrl(pageConfig.contentUrl.replace(window.location.origin, ''));
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      frontoffice.ui.actions.setUiColors({
        color: getComputedStyle(document.documentElement).getPropertyValue('--color-first'),
        colorAlpha: getComputedStyle(document.documentElement).getPropertyValue('--color-first-alpha'),
        colorGradient1: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-1'),
        colorGradient2: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-2'),
      })
    );
  }, []);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  useEffect(() => {
    dispatch(
      frontoffice.ui.actions.setUiColors({
        color: getComputedStyle(document.documentElement).getPropertyValue('--color-first'),
        colorAlpha: getComputedStyle(document.documentElement).getPropertyValue('--color-first-alpha'),
        colorGradient1: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-1'),
        colorGradient2: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-2'),
      })
    );
  }, []);

  useEffect(() => {
    if (tenant?.guid) {
      let tenantName = tenant.guid;
      let languageKeys = Object.keys(resources);
      languageKeys = languageKeys.filter((lang) => lang.length === 2);
      languageKeys.forEach(function (lang) {
        resources[lang].map((langScope, index) => {
          Object.keys(langScope).forEach(function (langScopeNs) {
            let langScopeTenant = {};
            let allScopes = langScope[langScopeNs];
            if (tenantName !== CLIENT.TANGERINE && resources[lang + '_' + tenantName]) {
              langScopeTenant = resources[lang + '_' + tenantName][index];
              allScopes = { ...langScopeTenant[langScopeNs], ...langScope[langScopeNs] };
            }
            i18next.addResources(lang, langScopeNs, allScopes);
          });
        });
      });
    }
  }, [tenant]);

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response.hasOwnProperty('error')) {
      history.push('/error');
    }
  }

  function setAppNameAndFavicon() {
    //defaultConfig.BASE_URL_.includes('rfp1')
    let theName = scopes.filter((scope) => scope.scope.includes('name'));
    if (theName.length > 0) {
      if (defaultConfig.BASE_URL_.includes('rfp1')) {
        theName = theName.filter((scope) => scope.scope.includes('1'));
        document.title = theName[0].json;
      } else if (defaultConfig.BASE_URL_.includes('rfp2')) {
        theName = theName.filter((scope) => scope.scope.includes('2'));
        document.title = theName[0].json;
      }
      document.title = 'Tangerine';
    }
    let theFavicon = scopes.filter((scope) => scope.scope.includes('favicon'));
    if (theFavicon.length > 0) {
      let url = '/app/favicon.ico';
      if (defaultConfig.BASE_URL_.includes('rfp1')) {
        url = theFavicon.filter((scope) => scope.scope.includes('1'))[0]?.json || url;
      } else if (defaultConfig.BASE_URL_.includes('rfp2')) {
        url = theFavicon.filter((scope) => scope.scope.includes('2'))[0]?.json || url;
      }

      changeFavicon(url);
    }
  }

  function checkIfCallbackTokenExists() {
    const path = window.location.pathname;
    // We check this in case it comes from the backoffice and we are already logged in with another user
    if (path.includes('/auth/login/')) {
      const splitted = path.split('/');
      callbackToken = splitted.length > 4 ? splitted[splitted.length - 2] : splitted[splitted.length - 1];
    }
    if (path === '/auth/login') {
      callbackToken = null;
    }
  }

  function redirectFromLoginWithToken() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/login/')) {
      const splitted = path.split('/');
      if (splitted.length > 4) {
        const newPath = '/' + splitted[splitted.length - 1].replaceAll('&', '/');
        return newPath;
      }
    }
    return '/home';
  }

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <div
      className={clsx(`theme-${theme}`, `client--${client}`, 'app-content', {
        'app-content--publisher': isEditorial(userRoleGuid),
        'app-content--student': isStudent(userRoleGuid),
        'app-content--teacher': isTeacher(userRoleGuid),
        'app-content--msteams': isTeams(),
        'app-content--kids': template === 'kids' && isStudent(userRoleGuid),
      })}
    >
      <LayoutContextProvider history={history} menuConfig={{}}>
        {userRoleGuid && isEditorial(userRoleGuid) && <BarMenuContainer />}
        <Switch>
          <Route path="/remote/:shortToken" component={RemotePage} />
          <Route path="/deeplink" render={() => <DeepLinkView />} />
          <Route path="/auth/login/:token/deeplink" render={() => <DeepLinkView />} />
          <Route path="/test-component" render={() => <TestComponentView />} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/saml/callback/error" component={SamlError} />
          {/* Static pages */}
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/terms" component={TermsOfUse} />
          {/* Teams configuration is required regardless if authorized or not to be able to bypass the popup */}
          <Route path="/config" component={MSTeamsConfiguration} />
          <Route path="/tab-auth-start" component={TabAuthStart} />
          <Route path="/tab-auth-end" component={TabAuthEnd} />

          {!isAuthorized ? (
            /* Redirect to `/auth` when user is not authorized */
            <Switch>
              <Route path="/auth/login/:token" component={TokenCallback} />
              <Route path="/saml/callback/:token" component={SamlCallback} />
              <Route path="/auth/login" component={LoginPage} />
              <Route path="/auth/signup" component={AuthPage} />
              <Route path="/recover-password" component={RecoverPasswordPage} />
              <Redirect to="/auth/login" />
            </Switch>
          ) : (
            <Switch>
              <Redirect exact from="/" to="/home" />
              {isTeams() && <Redirect exact from="/" to={teamsContentUrl} />}
              {isTeams() && <Redirect exact from="/home" to={teamsContentUrl} />}
              <Route path="/home" render={() => <HomePage />} />
              {window.origin.includes('localhost') && <Route path="/ui" render={() => <UiPage />} />}
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/question/:contentGuid"
                render={(props) => <LemonTest {...props} />}
              />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/new-question/:lemonadeType"
                render={(props) => <LemonTest {...props} />}
              />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid/mint"
                render={(props) => <AssessmentResultMint {...props} />}
              />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid/videolesson"
                render={(props) => <VideolessonResultsPage {...props} />}
              />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid" render={(props) => <AssessmentResult {...props} />} />
              <Route path="/course/:courseGuid/results-scorm/:itemGuid" render={() => <ScormResult />} />
              <Route path="/course/:courseGuid/results-xapi/:itemGuid" render={() => <XapiResult />} />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/videotest/:assessmentGuid"
                render={(props) => userRoleGuid === 'R01' && <AnswerVideolesson {...props} />}
              />
              <Route
                path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid"
                render={(props) => (userRoleGuid === 'R01' ? <AnswerTest {...props} /> : <NewAssessment {...props} />)}
              />

              <Route path="/videolesson-redirect" render={(props) => <VideolessonRedirect {...props} />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/new-content" render={(props) => <NewContentView {...props} />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/content/:contentGuid" render={(props) => <NewContentView {...props} />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid" render={() => <ProjectionMint />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid/projection" render={() => <Projection />} />
              <Route path="/course/:courseGuid/lesson/:lessonGuid" render={() => <LessonPage />} />
              <Route path="/course/:guid/grades" render={() => <CoursePage tabId="grades" />} />
              <Route path="/course/:guid/program" render={() => <CoursePage tabId="program" />} />
              <Route path="/course/:guid/subgroups" render={() => <CoursePage tabId="subgroups" />} />
              <Route path="/course/:guid" render={() => <CoursePage tabId="stream" />} />
              <Route path="/viewerfull/:guid" render={(props) => <Viewer {...props} showHeader={false} />} />
              <Route path="/viewer/externalcontent" render={(props) => <Viewer {...props} showHeader={false} />} />
              <Route path="/viewer/externalcontentlti" render={(props) => <Viewer {...props} showHeader={false} />} />
              <Route path="/viewer/:guid" render={(props) => <Viewer {...props} showHeader={true} />} />
              <Route path="/calendar" render={() => <CalendarPage />} />
              <Route path="/calendar/:course" render={(props) => <CalendarPage {...props} />} />
              <Route path="/profile" render={() => <ProfilePage isStudent={isStudent(userRoleGuid)} />} />
              <Route path="/tasks" render={() => (userRoleGuid === 'R01' ? <TasksStudentView /> : <TasksView />)} />
              <Route path="/lemon-test" render={() => <LemonTest />} />
              <Route path="/answer-test" render={() => <AnswerTest />} />
              <Route path="/results-assesment/:guid" render={() => <AssessmentResult2 />} />
              <Route
                path="/results-assesment-individual/course/:courseGuid/:assessmentGuid/user/:userGuid"
                render={() => (userRoleGuid === 'R01' ? <AssessmentResultIndividualStudent /> : <AssessmentResultIndividual />)}
              />
              <Route
                path="/results-videolesson-individual/course/:courseGuid/:assessmentGuid/user/:userGuid"
                render={(props) => <VideolessonResultsPage {...props} />}
              />
              <Route path="/results-scorm-individual/:itemGuid/user/:userGuid" render={() => <ScormIndividualResult />} />
              <Route path="/results-xapi-individual/:itemGuid/user/:userGuid" render={() => <XapiIndividualResult />} />
              <Route path="/single-activity/:courseGuid/record/:contentGuid/:referenceGuid" render={() => <IndividualActivityPage record={true} />} />
              <Route path="/single-activity/:courseGuid/:lessonItemGuid/:contentGuid" render={() => <IndividualActivityPage />} />
              <Route path="/tools/:courseProjectGuid" render={() => <ToolsProjectContainer />} />
              <Route path="/remote" component={RemotePage} />
              <Route path="/error" component={ErrorView} />
              <Route path="/license" component={AddLicense} />
              {/* <Route path="/videolesson" component={VideolessonPage} /> */}
              <Route path="/classroom/:groupGuid/users" render={() => <ClassroomUsersPage />} />

              <Redirect from="/auth/login/:token" to={redirectFromLoginWithToken()} />
              <Redirect from="/saml/callback/:token" to={redirectFromLoginWithToken()} />
              {/* <Redirect from="/auth/login" to={lastLocation?.pathname ? lastLocation.pathname : '/home'} /> */}
              <Redirect from="/auth/login" to={'/home'} />
              <Redirect from="/auth/signup" to={'/home'} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}
        </Switch>
      </LayoutContextProvider>
    </div>
  );
});
